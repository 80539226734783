import React, { useEffect, useState } from "react";
import "./StatusBox.scss";

export default function StatusBox({
  status = "",
  width,
  label,
  className,
  onClick,
}) {
  function formatter(text) {
    return statusFormatter(text)?.toLowerCase().split(" ").join("");
  }

  function statusFormatter(status) {
    switch (status?.toLowerCase()) {
      case "close":
        return "Closed";
      case "forward":
        return "Forwarded";
      case "reject":
        return "Rejected";
      case "approve":
        return "Approved";
      case "paid":
        return "Paid";
      case "cancel":
        return "Cancelled";
      case "return":
        return "Returned";
      case "complete":
        return "Completed";
      case "reopen":
        return "Reopened";
      case "share task":
        return "Task Shared";
      case "task reopen":
        return "Task Reopened";
      default:
        return status;
    }
  }

  return (
    <span
      id="StatusBox"
      className={className}
      style={{ "--sbx-color": `var(--sbx-${formatter(status)}, #000)` }}
      onClick={onClick}
    >
      {label && <p>{label}</p>}
      <span
        className={"box" + " " + formatter(status)}
        style={{ width: width }}
      >
        {statusFormatter(status)}
      </span>
    </span>
  );
}
StatusBox.displayName = "StatusBox";
