import React, { useEffect, useState } from "react";
import Input from "../../commonComponents/Input/Input";
import useForm from "../../utils/Hooks/useForm";
import TextArea from "../../commonComponents/TextArea/TextArea";
import { AiOutlineClose } from "react-icons/ai";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  approveOrReject,
  getReviewDetail,
  getStatusList,
  insertReview,
  updateReview,
  uploadFile,
} from "../../redux/actionCreator";
import {
  review_details,
  reviews_path,
  status_list,
  update_status_loading,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { Modal } from "react-bootstrap";

const AddReview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const details = useSelector(review_details);
  const path = useSelector(reviews_path);
  const statusData = useSelector(status_list);
  const statusLoading = useSelector(update_status_loading);

  const [invalidUpload, setInvalidUpload] = useState(false);
  const [trackId, setTrackId] = useState("");
  const [status, setStatus] = useState("");
  const [showModal, setShowModal] = useState(false);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  useEffect(() => {
    if (id) {
      dispatch(getReviewDetail(id));
    }
  }, [id]);

  useEffect(() => {
    let statusKey = {
      key: "review_management",
    };
    dispatch(getStatusList(statusKey));
  }, []);

  useEffect(() => {
    if (details && id) {
      let result = {
        name: details?.name,
        review: details?.review,
        rating: details?.rating,
        profile_image: details?.profile_image
          ? [
              {
                type: details?.profile_image?.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${details?.profile_image}`,
                name: `${details?.profile_image}`,
                response: `${details?.profile_image}`,
              },
            ]
          : "",
      };

      setInputs({ ...result });
    }
  }, [details]);

  const removeImage = (type) => {
    setInputs((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const singleFileUploadHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        formData.append(`path`, "reviews");
        dispatch(
          uploadFile(formData, (res) => {
            setErrors({
              ...errors,
              [field]: "",
            });
            setInputs((s) => ({
              ...s,
              [field]: [
                {
                  type: e[0].type,
                  url: e[0].url,
                  name: e[0]?.name,
                  metaFile: e,
                  response: res?.data[0],
                },
              ],
            }));
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setInputs({});
    setErrors({});
    navigate("/manage-reviews");
  };

  const onSubmitHandler = async () => {
    let isValidated = await validate({
      returnErrors: true,
    });
    if (isValidated?.valid && !invalidUpload) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = {
      name: inputs?.name,
      rating: inputs?.rating,
      review: inputs?.review,
      profile_image: inputs?.profile_image?.[0]?.response ?? "",

      track_id: trackId,
      path : "reviews"
    };

    console.log(formData);

    if (id) {
      formData = {
        ...formData,
        id: id,
      };
      dispatch(
        updateReview(formData, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    } else {
      dispatch(
        insertReview(formData, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  const onStatusHandler = (item) => {
    setStatus(item);
    setShowModal(true);
  };

  const handleClose = () => {
    setStatus("");
    setShowModal(false);
  };

  const statusSubmitHandler = () => {
    let raw = {
      id: id,
      status: status?._id,
    };
    dispatch(
      approveOrReject(raw, (res) => {
        handleClose();
        onCancelHandler();
      })
    );
  };

  return (
    <div className="card">
      <div className="card-body ">
        <h5 className="card-title border-style mb-3">
          {id ? "Edit Review" : "Add Review"}
        </h5>
        <div className="row">
          <div className="col-12">
            <Input
              className="css-inp-esc1 mb-3"
              label="Customer Name"
              placeholder="Type here"
              required={{ message: "Please Enter Name" }}
              field={"name"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              disabled={details?.review_type == "customer"}
            />
          </div>

          <div className="col-12 mb-3">
            <TextArea
              label={"Review"}
              className={"css-txt-esc1"}
              placeholder={"Type here"}
              field={"review"}
              value={inputs.review}
              error={errors.review}
              inputSocket={formPlug}
              // validation={{maxChar:200}}
              onChange={inputHandler}
              required={{ message: "Please Enter Review" }}
              disabled={details?.review_type == "customer"}
            />
          </div>

          <div className="col-6">
            <Input
              type={"Number"}
              className="css-inp-esc1 mb-3"
              label="Rating"
              placeholder="Type here"
              required={{ message: "Please Enter Rating" }}
              field={"rating"}
              validation={{ maxChar: 1, maxValue: 5 }}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              disabled={details?.review_type == "customer"}
            />
          </div>
          {details?.review_type == "customer" ? (
            <></>
          ) : (
            <div className="row">
              <div className="col-4 mb-3">
                <FileUpload
                  style={{
                    marginRight: 5,
                    marginTop: 0,
                  }}
                  className="css-atc-esc5 "
                  label="Profile Image"
                  accept={["jpg", "jpeg", "png"]}
                  icon={"/assets/img/icons/plus_black.svg"}
                  closeIcon={
                    <AiOutlineClose
                      size={30}
                      className="remove-icon"
                      onClick={() => removeImage("profile_image")}
                    />
                  }
                  onChange={(e, isInvalid, message, isClosing) => {
                    setErrors((s) => ({
                      ...s,
                      profile_image: "",
                    }));
                    singleFileUploadHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "profile_image"
                    );
                  }}
                  error={errors.profile_image}
                  width={28}
                  field={"profile_image"}
                  id="profile_image"
                  maxFileSizeMB={50}
                  multiple={false}
                  showPreview
                  files={inputs?.profile_image}
                  required={{ message: "Please upload image" }}
                  inputSocket={formPlug}
                  disabled={details?.review_type == "customer"}
                />
              </div>
            </div>
          )}

          {details?.review_type == "customer" ? (
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary m-2"
                onClick={() =>
                  onStatusHandler(
                    statusData.filter(
                      (item) => item.handle_key === "approved"
                    )?.[0]
                  )
                }
              >
                Approve
              </button>
              <button
                type="submit"
                className="btn btn-danger m-2"
                onClick={() =>
                  onStatusHandler(
                    statusData.filter(
                      (item) => item.handle_key === "rejected"
                    )?.[0]
                  )
                }
              >
                Reject
              </button>
              <button
                type="submit"
                className="btn btn-danger  m-2"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary m-2"
                onClick={() => onSubmitHandler()}
              >
                Submit
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal size="lg" show={showModal} centered onHide={handleClose}>
        <Modal.Body>
          <div className="container_deletemodal">
            <p className="title_deletemodal">
              {status.handle_key == "approved"
                ? "Confirm Approval"
                : "Confirm Rejection"}
            </p>

            <p className="description_deletemodal">
              Are you sure you want to{" "}
              {status.handle_key == "approved" ? "approve" : "reject"}{" "}? 
            </p>
            <div className="actions_deletemodal">
              <button className="delete-btn" onClick={statusSubmitHandler}>
                Yes
              </button>
              <button className="cancel-btn" onClick={handleClose}>
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddReview;
