import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";

const MediaUploader = ({ media, onUpload, onRemove, uploadType }) => {
  const isVideo =
    media?.file?.type?.includes("video") || media?.type == "video";

  return (
    <div className="uploader-container">
      {media?.preview ? (
        <div className="uploader-preview">
          {isVideo ? (
            <div className="video__prev">
              <video controls width="150" height="150">
                <source src={media.preview} type={"video"} />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <div className="image-showcontainer">
              <img src={media.preview} alt="Uploaded Preview" />
            </div>
          )}
          <AiOutlineClose
            size={30}
            className="remove-icon"
            onClick={onRemove}
          />
        </div>
      ) : (
        <label className="upload-label">
          <AiOutlinePlus size={30} className="plus-icon" />
          <input
            type="file"
            accept={
              uploadType
                ? uploadType?.map((item) => "." + item)
                : "image/*,video/*"
            }
            onChange={onUpload}
            className="file-input"
          />
        </label>
      )}
    </div>
  );
};

export default MediaUploader;
