import DatePick from "../../../commonComponents/DatePicker/DatePick";
import Input from "../../../commonComponents/Input/Input";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import Select from "../../../commonComponents/Select/Select";
import Checkbox from "../../../commonComponents/Checkbox/Checkbox";

const PassengerInfoDetails = ({
  inputs,
  formPlug,
  errors,
  setErrors,
  inputDataChangeHandler,
  fileUploadHandler,
  countryData,
}) => {
  return (
    <>
      {inputs?.traveller_info?.map((ele, index) => {
        return (
          <div className="card">
            <div className="card-body pt-0">
              <div className="tab-content">
                <div className="tab__header">
                  <h2>
                    {ele?.is_main == "1"
                      ? "Main Traveller"
                      : ele?.is_adult == "1"
                      ? `Adult ${ele?.adult}`
                      : `Child ${ele?.child}`}
                  </h2>
                  {ele?.is_main == "1" ? (
                    <></>
                  ) : (
                    <>
                      <div className="login__access">
                        <h2>Login Access</h2>
                        <div className="toggle__btn mt-0">
                          <p>Yes</p>
                          <Checkbox
                            label=""
                            className="css-tgl-esc1"
                            inputSocket={() => {}}
                            field={"can_login"}
                            toggleSwitch={{ true: "", false: "" }}
                            checked={ele.answer}
                            onChange={(e, key) => {
                              inputDataChangeHandler(
                                e,
                                key,
                                index,
                                "traveller_info"
                              );
                            }}
                          />
                          <p>No</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="grid__items">
                  <div className="item">
                    <Input
                      className="css-inp-esc1 "
                      label="First Name"
                      placeholder="Type here"
                      required={{ message: "Please Enter First Name" }}
                      field={"first_name"}
                      inputSocket={formPlug}
                      value={ele?.first_name}
                      onChange={(e, key) => {
                        inputDataChangeHandler(e, key, index, "traveller_info");
                      }}
                      error={
                        errors[`${"traveller_info"}_${"first_name"}_${index}`]
                      }
                    />
                  </div>
                  <div className="item">
                    <Input
                      className="css-inp-esc1 "
                      label="Middle Name"
                      placeholder="Type here"
                      // required={{ message: "Please Enter First Name" }}
                      field={"middle_name"}
                      inputSocket={formPlug}
                      value={ele?.middle_name}
                      onChange={(e, key) => {
                        inputDataChangeHandler(e, key, index, "traveller_info");
                      }}
                      error={
                        errors[`${"traveller_info"}_${"middle_name"}_${index}`]
                      }
                    />
                  </div>
                  <div className="item">
                    <Input
                      className="css-inp-esc1 "
                      label="Last Name"
                      placeholder="Type here"
                      required={{ message: "Please Enter Last Name" }}
                      field={"last_name"}
                      inputSocket={formPlug}
                      value={ele?.last_name}
                      onChange={(e, key) => {
                        inputDataChangeHandler(e, key, index, "traveller_info");
                      }}
                      error={
                        errors[`${"traveller_info"}_${"last_name"}_${index}`]
                      }
                    />
                  </div>
                  <div className="item">
                    <DatePick
                      className="css-dtp-esc2 mb-3"
                      title={"Date of Birth"}
                      placeholder="DD/MM/YYYY"
                      dateFormat="dd/MM/yyyy"
                      required={{ message: "Please select date" }}
                      field={"dob"}
                      showMonthDropdown
                      showYearDropdown
                      inputSocket={formPlug}
                      selected={ele?.dob}
                      onChange={(e, key) => {
                        inputDataChangeHandler(e, key, index, "traveller_info");
                      }}
                      error={errors[`${"traveller_info"}_${"dob"}_${index}`]}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="item">
                    <Select
                      title="Nationality"
                      placeholder="Select Nationality"
                      className="css-slc-esc1 mb-3"
                      options={countryData}
                      labelSetter={(option) => option?.name}
                      valueSetter={(option) => option._id}
                      field={"nationality"}
                      inputSocket={formPlug}
                      value={ele.nationality}
                      onChange={(e, key) => {
                        inputDataChangeHandler(e, key, index, "traveller_info");
                      }}
                      error={
                        errors[`${"traveller_info"}_${"nationality"}_${index}`]
                      }
                      required={{ message: "Please select nationality" }}
                    />
                  </div>
                  <div className="item">
                    <Input
                      type="email"
                      className="css-inp-esc1 "
                      label="Email Address"
                      placeholder="Type here"
                      required={{ message: "Please Enter Email" }}
                      field={"email"}
                      validation={{ email: true }}
                      inputSocket={formPlug}
                      value={ele?.email}
                      onChange={(e, key) => {
                        inputDataChangeHandler(e, key, index, "traveller_info");
                      }}
                      error={errors[`${"traveller_info"}_${"email"}_${index}`]}
                    />
                  </div>

                  <div className="item">
                    <Select
                      title="Nationality"
                      placeholder="Select Country Code"
                      className="css-slc-esc1 mb-3"
                      options={countryData}
                      labelSetter={(option) => option?.dial_code}
                      valueSetter={(option) => option._id}
                      field={"country_code"}
                      inputSocket={formPlug}
                      value={ele.country_code}
                      onChange={(e, key) => {
                        inputDataChangeHandler(e, key, index, "traveller_info");
                      }}
                      error={
                        errors[`${"traveller_info"}_${"country_code"}_${index}`]
                      }
                      required={{ message: "Please select country code" }}
                    />
                  </div>
                  <div className="item">
                    {console.log(ele?.phone, "ele?.phone", index)}
                    <Input
                      type="number"
                      className="css-inp-esc1 "
                      label="Phone Number"
                      placeholder="Type here"
                      required={{ message: "Please Enter Phone Number" }}
                      field={"phone"}
                      validation={{ phone: true }}
                      inputSocket={formPlug}
                      value={ele?.phone}
                      onChange={(e, key) => {
                        console.log(e, "e");
                        inputDataChangeHandler(e, key, index, "traveller_info");
                      }}
                      // onChange={(e, key) => {
                      //   const inputValue = e;
                      //   console.log(e,"e",ele?.phone)
                      //   if (/^\d{0,13}$/.test(inputValue)) {
                      //     console.log(inputValue,"inputValue")
                      //     inputDataChangeHandler(
                      //       inputValue,
                      //       key,
                      //       index,
                      //       "traveller_info"
                      //     );
                      //   }
                      // }}
                      // onChange={(e, key) => {
                      //   let inputValue = "";
                      //   console.log(e, "e", ele?.phone);
                      //   if (/^\d{0,13}$/.test(e)) {
                      //     inputValue = e;
                      //   } else {
                      //     inputValue = e.match(/\d+/g)?.join("") || "";
                      //   }

                      //   inputDataChangeHandler(
                      //     inputValue,
                      //     key,
                      //     index,
                      //     "traveller_info"
                      //   );
                      // }}
                      error={errors[`${"traveller_info"}_${"phone"}_${index}`]}
                      maxNumber={13}
                    />
                  </div>
                </div>
                <div className="tab__sub_header">
                  <h2>Passport Details</h2>
                </div>
                <div className="grid__items">
                  <div className="item">
                    <Input
                      className="css-inp-esc1 "
                      label="Passport Number"
                      placeholder="Type here"
                      required={{ message: "Please Enter Passport Number" }}
                      field={"passport_no"}
                      inputSocket={formPlug}
                      value={ele?.passport_no}
                      onChange={(e, key) => {
                        inputDataChangeHandler(e, key, index, "traveller_info");
                      }}
                      error={
                        errors[`${"traveller_info"}_${"passport_no"}_${index}`]
                      }
                    />
                  </div>
                  <div className="item">
                    <DatePick
                      className="css-dtp-esc2 mb-3"
                      title={"Passport Expiry"}
                      placeholder="DD/MM/YYYY"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      required={{ message: "Please select Expiry" }}
                      field={"passport_expiry"}
                      inputSocket={formPlug}
                      selected={ele?.passport_expiry}
                      onChange={(e, key) => {
                        inputDataChangeHandler(e, key, index, "traveller_info");
                      }}
                      error={
                        errors[
                          `${"traveller_info"}_${"passport_expiry"}_${index}`
                        ]
                      }
                      minDate={new Date()}
                    />
                  </div>
                  <div className="item">
                    <FileUpload
                      className="css-atc-esc1 preview-imagemargin"
                      buttonLabel="Add Passport Copy"
                      accept={["jpg", "jpeg", "png", "pdf", "application/pdf"]}
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          passport: "",
                        }));
                        fileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "passport",
                          inputDataChangeHandler,
                          "single",
                          index,
                          "traveller_info"
                        );
                      }}
                      error={
                        errors[`${"traveller_info"}_${"passport"}_${index}`]
                      }
                      id="passport"
                      maxFileSizeMB={100}
                      showPreview
                      files={ele?.passport}
                    />
                  </div>
                </div>
                <div className="item">
                  <FileUpload
                    className="css-atc-esc1 preview-imagemargin"
                    buttonLabel="Additional Documents"
                    accept={["jpg", "jpeg", "png", "pdf", "application/pdf"]}
                    onChange={(e, isInvalid, message, isClosing) => {
                      setErrors((s) => ({
                        ...s,
                        attachments: "",
                      }));
                      fileUploadHandler(
                        e,
                        isInvalid,
                        message,
                        isClosing,
                        "attachments",
                        inputDataChangeHandler,
                        "multiple",
                        index,
                        "traveller_info"
                      );
                    }}
                    error={
                      errors[`${"traveller_info"}_${"attachments"}_${index}`]
                    }
                    id="attachments"
                    maxFileSizeMB={100}
                    showPreview
                    files={ele?.attachments}
                    multiple={true}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default PassengerInfoDetails;
