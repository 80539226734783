import React, { useEffect, useState } from "react";
import MediaUploader from "../../components/media-uploader/MediaUploader";
import * as Switch from "@radix-ui/react-switch";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import { getFlightDetail, insertFlight, updateFlight } from "../../redux/actionCreator";
import { detail_flight_loading, flight_details, flight_path, update_flight_loading } from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";

const AddFlights = ({ setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector(detail_flight_loading);
  const submitLoading = useSelector(update_flight_loading);
  const details = useSelector(flight_details);
  const path = useSelector(flight_path);

  const [data, setData] = useState({
    flight_name: "",
    flight_code: "",
    logo: "",
    is_airline_include: "",
  });
  const [err, setErr] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(getFlightDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        flight_name: details?.flight_name,
        flight_code: details?.flight_code,
        is_airline_include: details?.is_airline_include,
        logo: details?.logo
          ? [
              {
                type: details?.logo?.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${details?.logo}`,
                name: `${details?.logo}`,
                response: `${details?.logo}`,
              },
            ]
          : "",
      };
      setData({ ...result });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const documentUploadHandler = (field, e, isInvalid) => {
    if (isInvalid) {
      setErr({
        ...err,
        [field]: "Invalid file format",
      });
    } else {
      setErr({
        ...err,
        [field]: "",
      });
      setData({
        ...data,
        [field]: [
          {
            type: e[0].type,
            url: e[0].url,
            name: e[0]?.name,
            metaFile: e,
            response: e,
          },
        ],
      });
      setErr({
        ...err,
        [field]: "",
      });
    }
  };

  const validate = () => {
    let temp = {};
    if (!data?.flight_name?.trim()?.length) {
      temp = {
        ...temp,
        flight_name: "Please enter flight name",
      };
    }
    if (!data?.flight_code?.trim()?.length) {
      temp = {
        ...temp,
        flight_code: "Please enter flight code",
      };
    }
    if (data?.logo == "") {
      temp = {
        ...temp,
        logo: "Please upload logo",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let formData = new FormData();
      formData.append("flight_name", data?.flight_name?.trim());
      formData.append("flight_code", data?.flight_code?.trim());
      formData.append(
        "logo",
        data?.logo?.[0]?.metaFile?.[0]?.metaFile ?? data?.logo?.[0]?.name
      );
      formData.append("is_airline_include", data?.is_airline_include);
      console.log(formData);
      if (id) {
        formData.append("id", id);
        dispatch(
          updateFlight(formData, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertFlight(formData, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      flight_name: "",
      flight_code: "",
      logo: "",
      is_airline_include: false,
    });
    navigate("/manage-flights");
  };
  return (
    <div className="card">
      <div className="card-body ">
        <h5 className="card-title border-style mb-3">Add Flights</h5>
        <div className="row">
          <div className="mb-4">
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label">Flight Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  onChange={(e) => onChangeHandler("flight_name", e?.target?.value)}
                  value={data?.flight_name}
                />
                <small id="error" className="error">
                  {err?.flight_name}
                </small>
              </div>
            </div>

            <div className="col-12 ">
              <div className="mb-3">
                <label className="form-label">Flight Code</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  onChange={(e) => onChangeHandler("flight_code", e?.target?.value)}
                  value={data?.flight_code}
                />
                <small id="error" className="error">
                  {err?.flight_code}
                </small>
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <div className="mb-3">
                <FileUpload
                  className="css-atc-esc2"
                  label={"Upload Logo"}
                  buttonLabel="Choose file"
                  accept={["jpg", "jpeg", "png", "svg"]}
                  onChange={(e, isInvalid) =>
                    documentUploadHandler("logo", e, isInvalid)
                  }
                  error={err.logo}
                  id="logo"
                  files={data?.logo}
                  // required
                  inputSocket={() => {}}
                />
              </div>
            </div>
            <div className="col-12 ">
              <div className="mb-3">
                <label className="form-label">Enable For API Search</label>
                <div className="switch_layout">
                  <Switch.Root
                    className="switchRoot"
                    checked={data?.is_airline_include}
                    onCheckedChange={(e) =>
                      onChangeHandler("is_airline_include", e)
                    }
                  >
                    <Switch.Thumb className="switchThumb" />
                  </Switch.Root>
                  <div className="switch_content">
                    <p>{data?.is_airline_include ? "Active" : ""}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-primary me-3"  onClick={() => onSubmitHandler()}>
              Submit
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => onCancelHandler()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFlights;
