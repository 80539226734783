import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { toolbarOptions } from "../constants/config";
import feather from "feather-icons";
import MediaUploader from "../components/media-uploader/MediaUploader";
import Seo from "../components/Seo";
import IconUploader from "../components/media-uploader/IconUploader";
import { useDispatch, useSelector } from "react-redux";
import {
  getAboutDetail,
  insertAbout,
  updateAbout,
  uploadFile,
} from "../redux/actionCreator";
import {
  about_details,
  about_path,
  detail_about_loading,
  update_about_loading,
} from "../redux/commonReducer";
import { IMAGE_BASE_URL } from "../constants/configuration";
import LoadingSpinner from "../commonComponents/LoadingSpinner/LoadingSpinner";
import TextEditor from "../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../utils/editorConfig";
import useForm from "../utils/Hooks/useForm";

export default function About() {
  const dispatch = useDispatch();
  const editor = useRef();

  const submitLoading = useSelector(update_about_loading);
  const loading = useSelector(detail_about_loading);
  const details = useSelector(about_details);
  const path = useSelector(about_path);

  const [data, setData] = useState({
    title: "",
    short_description: "",
    banner_image1: "",
    banner_image1_alt: "",
    banner_image2: "",
    banner_image2_alt: "",
    vision_title: "",
    vision_description: "",
    vision_image: "",
    vision_image_alt: "",
    vision_icon: "",
    vision_icon_alt: "",
    mission_title: "",
    mission_description: "",
    mission_image: "",
    mission_image_alt: "",
    mission_icon: "",
    mission_icon_alt: "",
    main_title: "",
   
    main_image: "",
    main_image_alt: "",
    features: [
      { title: "", icon: "", icon_alt: "" },
      { title: "", icon: "", icon_alt: "" },
      { title: "", icon: "", icon_alt: "" },
      { title: "", icon: "", icon_alt: "" },
      { title: "", icon: "", icon_alt: "" },
      { title: "", icon: "", icon_alt: "" },
    ],
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
  });
  const [err, setErr] = useState({});
  const [trackId, setTrackId] = useState("");

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  useEffect(() => {
    dispatch(getAboutDetail());
  }, []);

  useEffect(() => {
    feather.replace();
  }, []);

  useEffect(() => {
    if (details?._id) {
      let result = {
        title: details?.title,
        short_description: details?.short_description,
        banner_image1: details?.banner_image1
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.banner_image1}`,
            file: null,
            image: details?.banner_image1,
          }
          : "",
        banner_image1_alt: details?.banner_image1_alt,
        banner_image2: details?.banner_image2
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.banner_image2}`,
            file: null,
            image: details?.banner_image2,
          }
          : "",
        banner_image2_alt: details?.banner_image2_alt,
        vision_title: details?.vision_title,
        vision_description: details?.vision_description,
        vision_image: details?.vision_image
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.vision_image}`,
            file: null,
            image: details?.vision_image,
          }
          : "",
        vision_image_alt: details?.vision_image_alt,
        vision_icon: details?.vision_icon
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.vision_icon}`,
            file: null,
            image: details?.vision_icon,
          }
          : "",
        vision_icon_alt: details?.vision_icon_alt,
        mission_title: details?.mission_title,
        mission_description: details?.mission_description,
        mission_image: details?.mission_image
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.mission_image}`,
            file: null,
            image: details?.mission_image,
          }
          : "",
        mission_image_alt: details?.mission_image_alt,
        mission_icon: details?.mission_icon
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.mission_icon}`,
            file: null,
            image: details?.mission_icon,
          }
          : "",
        mission_icon_alt: details?.mission_icon_alt,
        main_title: details?.main_title,
        main_image: details?.main_image
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.main_image}`,
            file: null,
            image: details?.main_image,
          }
          : "",
        main_image_alt: details?.main_image_alt,
        meta_title: details?.meta_title,
        meta_keyword: details?.meta_keyword,
        meta_description: details?.meta_description,
      };
      if (details?.features?.length) {
        let arr = details?.features?.map((ele, i) => {
          let obj = {
            icon: ele?.icon
              ? {
                preview: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
                file: null,
                image: ele?.icon,
              }
              : "",
            title: `${ele?.title}`,
            icon_alt: `${ele?.icon_alt}`,
          };
          return obj;
        });
        result = {
          ...result,
          features: [...arr],
        };
      }
      setData({ ...result });
      setInputs({...inputs,main_description:details?.main_description})
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const removeImage = (type) => {
    setData((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const singleFileSelectedHandler = (event, field, setFunction, index) => {
    const file = event.target.files?.[0];
    setErr({
      ...err,
      [field]: "",
    });
    let formData = new FormData();
    if (trackId?.length == 0) {
      let track_id = Math.floor(1000 + Math.random() * 9000).toString();
      setTrackId(track_id);
      formData.append("track_id", track_id);
    } else {
      formData.append("track_id", trackId);
    }
    formData.append(`file`, file);
    formData.append(`path`, "about");
    dispatch(
      uploadFile(formData, (res) => {
        setErr({
          ...err,
          [field]: "",
        });
        setFunction(field, file, res, index);
      })
    );
  };

  const setSingleImageState = (field, file, res) => {
    setData((s) => ({
      ...s,
      [field]: {
        file: file,
        image: res?.data[0],
        preview: URL.createObjectURL(file),
      },
    }));
  };

  const setFeatureImageState = (field, file, res, index) => {
    let dataList = { ...data };
    dataList.features[index][field] = {
      file: file,
      image: res?.data[0],
      preview: URL.createObjectURL(file),
    };
    setData(dataList);
    setErr({
      ...err,
      [`features_${field}_${index}`]: "",
    });
  };

  const removeFeatureImage = (field, index) => {
    let dataList = { ...data };
    dataList.features[index][field] = "";
    setData(dataList);
    setErr({
      ...err,
      [`features_${field}_${index}`]: "",
    });
  };

  const handleFeaturesChange = (index, value, field) => {
    let dataList = { ...data };
    dataList.features[index][field] = value;
    setData(dataList);
    setErr({
      ...err,
      [`features_${field}_${index}`]: "",
    });
  };

  const validator = () => {
    let temp = {};
    if (!data?.title?.trim()?.length) {
      temp = {
        ...temp,
        title: "Please enter title",
      };
    }
    if (!data?.short_description?.trim()?.length) {
      temp = {
        ...temp,
        short_description: "Please enter short description",
      };
    }
    // if (!data?.banner_image1_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     banner_image1_alt: "Please enter banner alt text",
    //   };
    // }
    if (data?.banner_image1 == "") {
      temp = {
        ...temp,
        banner_image1: "Please upload banner image",
      };
    }
    // if (!data?.banner_image2_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     banner_image2_alt: "Please enter banner alt text",
    //   };
    // }
    if (data?.banner_image2 == "") {
      temp = {
        ...temp,
        banner_image2: "Please upload banner image",
      };
    }
    if (!data?.vision_title?.trim()?.length) {
      temp = {
        ...temp,
        vision_title: "Please enter vision title",
      };
    }
    if (!data?.vision_description?.trim()?.length) {
      temp = {
        ...temp,
        vision_description: "Please enter vision description",
      };
    }
    if (data?.vision_image == "") {
      temp = {
        ...temp,
        vision_image: "Please upload vision image",
      };
    }
    // if (!data?.vision_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     vision_image_alt: "Please enter vision alt text",
    //   };
    // }
    if (data?.vision_icon == "") {
      temp = {
        ...temp,
        vision_icon: "Please upload vision icon",
      };
    }
    // if (!data?.vision_icon_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     vision_icon_alt: "Please enter vision icon alt text",
    //   };
    // }
    if (!data?.mission_title?.trim()?.length) {
      temp = {
        ...temp,
        mission_title: "Please enter mission title",
      };
    }
    if (!data?.mission_description?.trim()?.length) {
      temp = {
        ...temp,
        mission_description: "Please enter mission description",
      };
    }
    if (data?.mission_image == "") {
      temp = {
        ...temp,
        mission_image: "Please upload mission image",
      };
    }
    // if (!data?.mission_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     mission_image_alt: "Please enter mission alt text",
    //   };
    // }
    if (data?.mission_icon == "") {
      temp = {
        ...temp,
        mission_icon: "Please upload mission icon",
      };
    }
    // if (!data?.mission_icon_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     mission_icon_alt: "Please enter mission icon alt text",
    //   };
    // }
    if (!data?.main_title?.trim()?.length) {
      temp = {
        ...temp,
        main_title: "Please enter main title",
      };
    }
    // if (!data?.main_description?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     main_description: "Please enter main description",
    //   };
    // }
    if (data?.main_image == "") {
      temp = {
        ...temp,
        main_image: "Please upload main icon",
      };
    }
    // if (!data?.main_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     main_image_alt: "Please enter main image alt text",
    //   };
    // }
    data?.features?.forEach((ele, i) => {
      if (!ele.title?.trim()?.length) {
        temp[`features_title_${i}`] = "Please enter name";
      }
      // if (!ele.icon_alt?.trim()?.length) {
      //   temp[`features_icon_alt_${i}`] = "Please enter alt text";
      // }
      if (ele.icon == "") {
        temp[`features_icon_${i}`] = "Please upload icon";
      }
    });
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = async () => {
    let isValidated = await validate({
      returnErrors: true,
    });
    if (isValidated?.valid ) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    if (validator()) {
      let raw = {
        title: data?.title?.trim(),
        short_description: data?.short_description?.trim(),
        banner_image1: data?.banner_image1?.image,
        banner_image1_alt: data?.banner_image1_alt?.trim(),
        banner_image2: data?.banner_image2?.image,
        banner_image2_alt: data?.banner_image2_alt?.trim(),
        vision_title: data?.vision_title?.trim(),
        vision_description: data?.vision_description?.trim(),
        vision_image: data?.vision_image?.image,
        vision_image_alt: data?.vision_image_alt?.trim(),
        vision_icon: data?.vision_icon?.image,
        vision_icon_alt: data?.vision_icon_alt?.trim(),
        mission_title: data?.mission_title?.trim(),
        mission_description: data?.mission_description?.trim(),
        mission_image: data?.mission_image?.image,
        mission_image_alt: data?.mission_image_alt?.trim(),
        mission_icon: data?.mission_icon?.image,
        mission_icon_alt: data?.mission_icon_alt?.trim(),
        main_title: data?.main_title?.trim(),
        main_description: inputs?.main_description?.trim(),
        main_image: data?.main_image?.image,
        main_image_alt: data?.main_image_alt?.trim(),
        features: data?.features?.map((item, i) => {
          return {
            [`icon`]: item?.icon?.image,
            [`title`]: item?.title?.trim(),
            [`icon_alt`]: item?.icon_alt?.trim(),
          };
        }),
        track_id: trackId,
        meta_title: data?.meta_title?.trim(),
        meta_keyword: data?.meta_keyword?.trim(),
        meta_description: data?.meta_description?.trim(),
        path : "about"
      };
      console.log(raw);
      if (details?._id) {
        raw = {
          ...raw,
          id: details?._id,
        };
        dispatch(
          updateAbout(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertAbout(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      title: "",
      short_description: "",
      banner_image1: "",
      banner_image1_alt: "",
      banner_image2: "",
      banner_image2_alt: "",
      vision_title: "",
      vision_description: "",
      vision_image: "",
      vision_image_alt: "",
      vision_icon: "",
      vision_icon_alt: "",
      mission_title: "",
      mission_description: "",
      mission_image: "",
      mission_image_alt: "",
      mission_icon: "",
      mission_icon_alt: "",
      main_title: "",
      main_image: "",
      main_image_alt: "",
      features: [
        { title: "", icon: "", icon_alt: "" },
        { title: "", icon: "", icon_alt: "" },
        { title: "", icon: "", icon_alt: "" },
        { title: "", icon: "", icon_alt: "" },
        { title: "", icon: "", icon_alt: "" },
        { title: "", icon: "", icon_alt: "" },
      ],
      meta_title: "",
      meta_keyword: "",
      meta_description: "",
    });
    setInputs({})
    dispatch(getAboutDetail());
  };

  return (
    loading ? (<LoadingSpinner height={"200px"} />) : (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Add About</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Title <em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) => onChangeHandler("title", e?.target?.value)}
                      value={data?.title}
                    />
                    <small id="error" className="error">
                      {err?.title}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Short description<em>*</em></label>
                    <textarea
                      type="text"
                      className="form-control"
                      rows={4}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("short_description", e?.target?.value)
                      }
                      value={data?.short_description}
                    />
                    <small id="error" className="error">
                      {err?.short_description}
                    </small>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="form-label">
                      About Page Images(Recommended Size:650*730)<em>*</em>
                    </label>
                    <MediaUploader
                      media={data.banner_image1}
                      onUpload={(e) =>
                        singleFileSelectedHandler(
                          e,
                          "banner_image1",
                          setSingleImageState
                        )
                      }
                      onRemove={() => removeImage("banner_image1")}
                    />
                    <small id="error" className="error">
                      {err?.banner_image1}
                    </small>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("banner_image1_alt", e?.target?.value)
                      }
                      value={data?.banner_image1_alt}
                    />
                    <small id="error" className="error">
                      {err?.banner_image1_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-4 margin-container">
                  <div className="mb-3">
                  <em>*</em>
                    <MediaUploader
                      media={data.banner_image2}
                      onUpload={(e) =>
                        singleFileSelectedHandler(
                          e,
                          "banner_image2",
                          setSingleImageState
                        )
                      }
                      onRemove={() => removeImage("banner_image2")}
                    />
                    <small id="error" className="error">
                      {err?.banner_image2}
                    </small>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("banner_image2_alt", e?.target?.value)
                      }
                      value={data?.banner_image2_alt}
                    />
                    <small id="error" className="error">
                      {err?.banner_image2_alt}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Feature icons sections */}
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Feature List</h5>
              <div className="row">
                {data?.features?.map((ele, index) => (
                  <React.Fragment key={index}>
                    <div className="col-12 col-md-2">
                      <div className="mb-3">
                      <em>*</em>
                        <IconUploader
                          media={ele?.icon}
                          onUpload={(e) =>
                            singleFileSelectedHandler(
                              e,
                              "icon",
                              setFeatureImageState,
                              index
                            )
                          }
                          onRemove={() => removeFeatureImage("icon", index)}
                          index={index}
                        />
                        <small id="error" className="error">
                          {err[`features_icon_${index}`]}
                        </small>
                      </div>
                    </div>
                    <div className="col-12 col-md-10">
                      <div className="">
                        <label className="form-label">
                          {index + 1}.Feature Name<em>*</em>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type here"
                          onChange={(e) =>
                            handleFeaturesChange(index, e?.target?.value, "title")
                          }
                          value={ele?.title}
                        />
                        <small id="error" className="error">
                          {err[`features_title_${index}`]}
                        </small>
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Alt Text</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type here"
                          onChange={(e) =>
                            handleFeaturesChange(
                              index,
                              e?.target?.value,
                              "icon_alt"
                            )
                          }
                          value={ele?.icon_alt}
                        />
                        <small id="error" className="error">
                          {err[`features_icon_alt_${index}`]}
                        </small>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Vision</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Vision Title<em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("vision_title", e?.target?.value)
                      }
                      value={data?.vision_title}
                    />
                    <small id="error" className="error">
                      {err?.vision_title}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Icon (Recommended Size:650*730)<em>*</em>
                    </label>
                    <MediaUploader
                      media={data.vision_icon}
                      onUpload={(e) =>
                        singleFileSelectedHandler(
                          e,
                          "vision_icon",
                          setSingleImageState
                        )
                      }
                      onRemove={() => removeImage("vision_icon")}
                    />
                    <small id="error" className="error">
                      {err?.vision_icon}
                    </small>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("vision_icon_alt", e?.target?.value)
                      }
                      value={data?.vision_icon_alt}
                    />
                    <small id="error" className="error">
                      {err?.vision_icon_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="mb-4">
                    <label className="form-label">
                      Background Image (Recommended Size:650*730)<em>*</em>
                    </label>
                    <MediaUploader
                      media={data.vision_image}
                      onUpload={(e) =>
                        singleFileSelectedHandler(
                          e,
                          "vision_image",
                          setSingleImageState
                        )
                      }
                      onRemove={() => removeImage("vision_image")}
                    />
                    <small id="error" className="error">
                      {err?.vision_image}
                    </small>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("vision_image_alt", e?.target?.value)
                      }
                      value={data?.vision_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.vision_image_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Short description<em>*</em></label>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("vision_description", e?.target?.value)
                      }
                      value={data?.vision_description}
                    ></textarea>
                    <small id="error" className="error">
                      {err?.vision_description}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body ">
              <h5 className="card-title border-style mb-3">Mission</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Mission Title<em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("mission_title", e?.target?.value)
                      }
                      value={data?.mission_title}
                    />
                    <small id="error" className="error">
                      {err?.mission_title}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Icon (Recommended Size:650*730)
                    </label>
                    <MediaUploader
                      media={data.mission_icon}
                      onUpload={(e) =>
                        singleFileSelectedHandler(
                          e,
                          "mission_icon",
                          setSingleImageState
                        )
                      }
                      onRemove={() => removeImage("mission_icon")}
                    />
                    <small id="error" className="error">
                      {err?.mission_icon}
                    </small>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("mission_icon_alt", e?.target?.value)
                      }
                      value={data?.mission_icon_alt}
                    />
                    <small id="error" className="error">
                      {err?.mission_icon_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="mb-3">
                    <label className="form-label">
                      Background Image (Recommended Size:650*730)<em>*</em>
                    </label>
                    <MediaUploader
                      media={data.mission_image}
                      onUpload={(e) =>
                        singleFileSelectedHandler(
                          e,
                          "mission_image",
                          setSingleImageState
                        )
                      }
                      onRemove={() => removeImage("mission_image")}
                    />
                    <small id="error" className="error">
                      {err?.mission_image}
                    </small>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("mission_image_alt", e?.target?.value)
                      }
                      value={data?.mission_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.mission_image_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Short description<em>*</em></label>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("mission_description", e?.target?.value)
                      }
                      value={data?.mission_description}
                    ></textarea>
                    <small id="error" className="error">
                      {err?.mission_description}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Add Main Contents</h5>
              <div className="row">
                <div className="col-12 mb-3">
                <TextEditor
                  config={editorConfigEn}
                  label={"Description"}
                  className={"mb-3"}
                  field={"main_description"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  required={{
                    message: "Please enter description",
                  }}
                  placeholder="Type Here"
                />
              </div>

                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Banner Image (Recommended Size:650*730)<em>*</em>
                    </label>
                    <MediaUploader
                      media={data.main_image}
                      onUpload={(e) =>
                        singleFileSelectedHandler(
                          e,
                          "main_image",
                          setSingleImageState
                        )
                      }
                      onRemove={() => removeImage("main_image")}
                    />
                    <small id="error" className="error">
                      {err?.main_image}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("main_image_alt", e?.target?.value)
                      }
                      value={data?.main_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.main_image_alt}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <Seo data={data} onChangeHandler={onChangeHandler} />
            </div>
          </div>
          <div className="card">
            <div className="card-body ">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary me-3"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
}
