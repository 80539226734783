import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../commonComponents/DeleteModal/DeleteModal";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";
import Table from "../../commonComponents/Table/Table";
import Actions from "../../commonComponents/Actions/Actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFilter from "../../utils/Hooks/useFilter";
import { deleteReview, getReviews } from "../../redux/actionCreator";
import {
  review_data,
  review_loading,
  review_page_details,
  reviews_path,
  updateRedux,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import EditIcon from "../../assets/icons/EDIT.svg";
import DeleteIcon from "../../assets/icons/DELETE.svg";
import StatusBox from "../../commonComponents/StatusBox/StatusBox";
const ManageReviews = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(review_data);
  const pageData = useSelector(review_page_details);
  const path = useSelector(reviews_path);
  const loading = useSelector(review_loading);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "createdAt",
    sortValue: -1,
    keyword: "",
  };

  const [filterData, setFilterData] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  useEffect(() => {
    dispatch(updateRedux({ key: "review_details", value: {} }));
  }, []);

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  const handleClose = () => {
    setDeleteID("");
    setShowDeleteModal(false);
  };

  const deleteHandler = () => {
    dispatch(
      deleteReview(deleteID, () => {
        setShowDeleteModal(false);
        setDeleteID("");
        if (data?.length == 1 && pageData?.pages > 1 && filterData?.page != 0) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });

          let data = {
            page: filterData?.page - 1,
            sort_order: filterData.sortValue,
            perPage: filterData.perPage,
            sort_key: filterData.sortColumn,
            keyword: filterData?.keyword?.trim() ?? "",
            is_pagination: "1",
          };

          dispatch(getReviews(data));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const getSearchDataHandler = (filter) => {
    const data = {
      page: filter.page,
      sort_order: filter.sortValue,
      perPage: filter.perPage,
      sort_key: filter.sortColumn,
      keyword: filter?.keyword?.trim() ?? "",
      is_pagination: "1",
    };

    dispatch(getReviews(data));
  };
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="table__add border-style">
                <h5 className="card-title">Manage Reviews</h5>
                <Link to="/add-Review">
                  <button className="btn btn-primary"> Add Review</button>
                </Link>
              </div>
              <div className="search__head">
                <div className="count">
                  <EntriesCounter
                    value={filterData.perPage}
                    onChange={(e) => {
                      filterHandler("entries", e);
                    }}
                  />
                </div>
                <div className="search">
                  <Input
                    value={filterData.keyword}
                    type="text"
                    className="css-inp-esc2 search"
                    // icon={GenericSearchIcon}
                    placeholder={"Search Titles"}
                    reverse
                    width="300px"
                    field="keyword"
                    showCloseBtn
                    onChange={(e) => !e && filterHandler("keyword", e)}
                    onSubmit={(e) => {
                      filterHandler("keyword", e);
                    }}
                    onIconClick={(e) => {
                      filterHandler("keyword", e);
                    }}
                  />
                </div>
              </div>
              {loading ? (
                <LoadingSpinner height={"200px"} />
              ) : (
                <Table
                  titles={[
                    {
                      title: "Sl.No",
                      key: "sl_no",
                      width: "5px",
                    },
                    {
                      title: "Profile",
                      key: "profile_image",
                      sort: true,
                      width: "50px",
                    },
                    {
                      title: "Customer Name",
                      key: "name",
                      sort: true,
                      width: "50px",
                    },
                    {
                      title: "Review",
                      key: "review",
                      sort: true,
                      width: "250px",
                    },
                    {
                      title: "Rating",
                      key: "rating",
                      sort: true,
                      width: "30px",
                    },
                    {
                      title: "Reviewer",
                      key: "review_type",
                      sort: false,
                      width: "30px",
                    },
                    {
                      title: "Status",
                      key: "status",
                      sort: true,
                      width: "80px",
                    },
                    { title: "Action", key: "action", width: "100px" },
                  ]}
                  content={data?.map((item, i) => ({
                    sl_no: filterData.page * filterData.perPage + (i + 1),
                    profile_image: item?.profile_image ? (
                      <img
                        src={`${IMAGE_BASE_URL}${path}/${item?.profile_image}`}
                        alt={item?.profile_image_alt}
                      />
                    ) : (
                      <img
                        src={`/assets/img/logo.png`}
                        alt={item?.profile_image_alt}
                      />
                    ),
                    name: item?.name,
                    review: <span className="textLimit">{item?.review}</span>,
                    rating: item?.rating,
                    status: item?.status?.[0]?.name ? (
                      <StatusBox status={item?.status?.[0]?.name} />
                    ) : (
                      "--"
                    ),
                    review_type	 : item?.review_type	== "admin" ? "Admin" : "Customer",
                    action: (
                      <Actions
                        buttons={{
                          edit: EditIcon,
                          delete: DeleteIcon,
                        }}
                        visibility={{
                          edit:
                            item?.review_type === "admin" ||
                            (item?.status[0]?.handle_key !== "approved" &&
                              item?.status[0]?.handle_key !== "rejected" &&
                              item?.review_type === "customer"),
                        }}
                        onEdit={() => navigate(`/update-review/${item?._id}`)}
                        onDelete={() => showDeletePopup(item?._id)}
                      />
                    ),
                  }))}
                  onSort={(e) => filterHandler("sort", e)}
                  page={filterData.page}
                  pagesCount={pageData?.pages}
                  pageStatus={pageData?.status}
                  onPageChange={(e) => {
                    filterHandler("page", e.selected);
                  }}
                  sortValue={{
                    key: filterData.sortColumn,
                    value: filterData.sortValue,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleClose={handleClose}
        deleteHandler={deleteHandler}
        title={"Delete Review"}
      />
    </>
  );
};

export default ManageReviews;
