import React from "react";
import MediaUploader from "./media-uploader/MediaUploader";
import ReactQuill from "react-quill";
import { toolbarOptions } from "../constants/config";
import Seo from "./Seo";
import LoadingSpinner from "../commonComponents/LoadingSpinner/LoadingSpinner";
import TextEditor from "../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../utils/editorConfig";

const PolicyContent = ({
  title,
  data,
  onChangeHandler,
  err,
  titleField,
  descriptionField,
  altField,
  imageField,
  singleFileSelectedHandler,
  removeImage,
  onSubmitHandler,
  onCancelHandler,
  editor,
  loading,
}) => {
  // console.log(data);
  return loading ? (
    <LoadingSpinner height="200px" />
  ) : (
    <>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3 ">{title}</h5>
              <div className="row">
                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label"> Background Image</label>
                    <MediaUploader
                      media={data?.[imageField]}
                      onUpload={(e) => singleFileSelectedHandler(e, imageField)}
                      onRemove={() => removeImage(imageField)}
                    />
                    <small id="error" className="error">
                      {err?.[imageField]}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label"> Image alt text</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler(altField, e?.target?.value)
                      }
                      value={data?.[altField]}
                    />
                    <small id="error" className="error">
                      {err?.[altField]}
                    </small>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="mb-3">
                    <label className="form-label"> Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler(titleField, e?.target?.value)
                      }
                      value={data?.[titleField]}
                    />
                    <small id="error" className="error">
                      {err?.[titleField]}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <TextEditor
                    config={editorConfigEn}
                    label={"Description"}
                    className={"mb-3"}
                    field={"description"}
                    inputSocket={() => {}}
                    required={{
                      message: "Please enter description",
                    }}
                    placeholder="Type Here"
                    value={data?.[descriptionField]}
                    onBlur={(newContent) =>
                      onChangeHandler(descriptionField, newContent)
                    }
                    onChange={(e, key) => {
                      onChangeHandler(descriptionField, e);
                    }}
                    error={err?.[descriptionField]}
                  />
                  {/* <div className="mb-3">
                    <label className="form-label">Description</label>
                    <ReactQuill
                    config={editorConfigEn}
                      ref={editor}
                      theme="snow"
                      modules={{
                        toolbar: toolbarOptions,
                      }}
                      placeholder="Type here"
                      onChange={(e) => onChangeHandler(descriptionField, e)}
                      value={data?.[descriptionField]}
                      onBlur={(newContent) =>
                        onChangeHandler(descriptionField, newContent)
                      }
                    />
                    <small id="error" className="error">
                      {err?.[descriptionField]}
                    </small>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Seo data={data} onChangeHandler={onChangeHandler} />
        </div>
      </div>
      <div className="card">
        <div className="card-body ">
          <div className="col-12">
            <button
              type="submit"
              className="btn btn-primary me-3"
              onClick={() => onSubmitHandler()}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => onCancelHandler()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PolicyContent;
