import React, { useEffect } from "react";
import Input from "../../../commonComponents/Input/Input";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import SupportedFormats from "../../../commonComponents/SupportedFormats/SupportedFormats";
import TextEditor from "../../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../../utils/editorConfig";
import Select from "../../../commonComponents/Select/Select";
import SortableList from "../../../commonComponents/sortFiles/SortableList";
import { scrollToTop } from "../../../utils/helpers";

export default function PackageDetails({
  inputs,
  errors,
  formPlug,
  inputHandler,
  setErrors,
  singleFileUploadHandler,
  onDetailsEditorChange,
  inclusionData,
  exclusionData,
  blogData,
  seasonList,
  handleInputDelete,
  trackId,
  altChangeHandler,
  imageLoader,
  fileSelectedHandler,
  mainInputFileUpdateHandler,
  setInputs,
  handleImageInputDelete,
  inputBlogHandler,
  packageMasterData
}) {
  useEffect(() => {
    if (errors) {
      setErrors({
        ...errors,
      });
    }
    scrollToTop()
  }, []);
  return (
    <>
      <div>
        <h5 className="package__header">Package Info</h5>
        <div className="row mb-4">
          <div className="col-12">
            <div className="row">
              <div className="col-12 ">
                <Input
                  className="css-inp-esc1 mb-4"
                  label="Package Name"
                  placeholder="Type here"
                  required={{ message: "Please enter package name" }}
                  field={"package_name"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div>
                  <div className="d-flex gap-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                      }}
                      className="css-atc-esc2 mb-4"
                      label="Thumbnail (Recommended 1250*650)"
                      buttonLabel="Choose File"
                      accept={["jpg", "jpeg", "png"]}
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          thumbnail: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "thumbnail",
                          mainInputFileUpdateHandler
                        );
                      }}
                      error={errors.thumbnail}
                      field={"thumbnail"}
                      id="thumbnail"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.thumbnail}
                      required={{ message: "Please upload thumbnail" }}
                      inputSocket={formPlug}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <Input
                  className="css-inp-esc1 mb-4"
                  label="Thumbnail Alt Text"
                  placeholder="Type here"
                  // required={{ message: "Please enter thumbnail alt" }}
                  field={"thumbnail_alt"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div>
                  <div className="d-flex gap-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 15,
                      }}
                      className="css-atc-esc2 mt-0 mb-0"
                      label="Add Banner (Recommended 1250*650)"
                      noLabel
                      buttonLabel="Choose File"
                      accept={["jpg", "jpeg", "png", "svg"]}
                      validFilesOnly
                      onChange={(e, isInvalid, message, isClosing) => {
                        console.log(e)
                        setErrors((s) => ({
                          ...s,
                          banner_image: "",
                        }));
                        fileSelectedHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "banner_image"
                        );
                      }}
                      field={"banner_image"}
                      loader={imageLoader}
                      error={errors.banner_image}
                      id="banner_image"
                      maxFileSizeMB={100}
                      multiple={true}
                      files={inputs?.banner_image}
                      inputSocket={() => { }}
                      required={{
                        message: "Please upload at-least one banner",
                      }}
                    />
                  </div>
                  <SupportedFormats
                      formats={["jpg", "jpeg", "png"]}
                      maxSize={50}
                    />
                </div>
              </div>
            </div>
            {inputs?.banner_image?.length ? (
              <SortableList
                setInputs={setInputs}
                inputs={inputs}
                field="banner_image"
                handleDelete={handleImageInputDelete}
                randomTrackID={trackId ?? ""}
                err={errors}
                altChangeHandler={altChangeHandler}
              />
            ) : (
              <></>
            )}
          </div>

          <div className="col-12">
            <div className="row">
              <div className="col-12 mt-4">
                <TextEditor
                  lang="en"
                  config={editorConfigEn}
                  label={"Overview"}
                  className={"mb-4"}
                  field={"overview"}
                  onBlur={(newContent) =>
                    onDetailsEditorChange(newContent, "overview")
                  }
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  // required={{ message: "Please enter overview" }}
                  placeholder="Type Here"
                />
              </div>

              <div className="col-12 col-md-4">
                <Select
                  title="Season"
                  className="css-slc-esc1 max-w-100 mb-4"
                  placeholder={"Select any option"}
                  options={seasonList}
                  labelSetter={(option) => option.name}
                  valueSetter={(option) => option._id}
                  field={"season"}
                  error={errors.season}
                  value={inputs.season}
                  onChange={(e, key) => {
                    inputHandler(e, key);
                  }}
                  inputSocket={formPlug}
                  required={{ message: "Please select season" }}
                  dontSort
                />
              </div>

              <div className="col-12 col-md-4">
                <Input
                  type="number"
                  className="css-inp-esc1 mb-4"
                  label="Duration"
                  placeholder="Type here"
                  required={{ message: "Please enter duration" }}
                  field={"duration"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  validation={{ maxValue: 30 }}
                />
              </div>
              <div className="col-12 col-md-4">
                <Input
                  type="number"
                  className="css-inp-esc1 mb-4"
                  label="Anticipated Travel KLM"
                  placeholder="Type here"
                  // required={{
                  //   message: "Please enter anticipated travel kilometer",
                  // }}
                  field={"klm"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h5 className="package__header">Inclusion</h5>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6">
                <Select
                  title="Choose Inclusion"
                  className="css-slc-esc1"
                  placeholder={"Select"}
                  options={inclusionData}
                  labelSetter={(o) => o?.inclusion_name}
                  valueSetter={(option) => option._id}
                  field={"inclusions"}
                  error={errors.inclusions}
                  value={inputs.inclusions ? inputs.inclusions : []}
                  onChange={inputHandler}
                  inputSocket={formPlug}
                  Multi2
                // required={{ message: "Please select inclusion" }}
                />
              </div>
              <div className="raw">
                <div className="selectedListData">
                  {inputs?.inclusions?.map((option) => (
                    <div key={option} className="mb-1">
                      <div className="d-inline-block mb-1">
                        <button className="selected__item" type="button">
                          {option?.inclusion_name}
                          <img src="/assets/img/icons/close-red.svg" onClick={() =>
                            handleInputDelete(option?._id, "inclusions")
                          } alt="" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h5 className="package__header">Exclusion</h5>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6">
                <Select
                  title="Choose Exclusion"
                  className="css-slc-esc1"
                  placeholder={"Select"}
                  options={exclusionData}
                  labelSetter={(o) => o?.exclusion_name}
                  valueSetter={(option) => option._id}
                  field={"exclusions"}
                  error={errors.exclusions}
                  value={inputs.exclusions ? inputs.exclusions : []}
                  onChange={inputHandler}
                  inputSocket={formPlug}
                  Multi2
                // required={{ message: "Please select exclusions" }}
                />
              </div>
              <div className="raw">
                <div className="selectedListData">
                  {inputs?.exclusions?.map((option) => (
                    <div key={option} className="mb-1">
                      <div className="d-inline-block mb-1">
                        <button className="selected__item" type="button">
                          {option?.exclusion_name}
                          <img src="/assets/img/icons/close-red.svg" onClick={() =>
                              handleInputDelete(option?._id, "exclusions")
                            } alt="" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h5 className="package__header">Associated Blogs</h5>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6">
                <Select
                  title="Choose Associated Blogs"
                  className="css-slc-esc1"
                  placeholder={"Select"}
                  options={blogData}
                  labelSetter={(o) => o?.title}
                  valueSetter={(option) => option._id}
                  field={"blog"}
                  error={errors.blog}
                  value={inputs.blog ? inputs.blog : []}
                  // onChange={inputHandler}
                  onChange={(value, key) => inputBlogHandler(value, key)}
                  inputSocket={formPlug}
                  // required={{ message: "Please select blog" }}
                  Multi2
                />
              </div>
              <div className="raw">
                <div className="col-12 selectedListData">
                  {inputs?.blog?.map((option) => (
                    <div key={option} className="mb-1">
                      <div className="d-inline-block mb-1">
                        <button className="selected__item" type="button">
                          {option?.title}
                          <img src="/assets/img/icons/close-red.svg" onClick={() =>
                              handleInputDelete(option?._id, "blog")
                            } alt="" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* <h5 className="card-title border-style mb-4">Essential Information</h5>
        <div className="row ">
          <div className="col-12">
            <TextEditor
              config={editorConfigEn}
              label={"Description"}
              className={"mb-4"}
              field={"essential_info"}
              onBlur={(newContent) =>
                onDetailsEditorChange(newContent, "essential_info")
              }
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              required={{
                message: "Please enter essential information",
              }}
              placeholder="Type Here"
            />
          </div>
        </div> */}
        <div className="col-12 col-md-6">
          <Input
            type="number"
            className="css-inp-esc1 mb-4"
            label="Rank"
            placeholder="Type here"
            required={{ message: "Please enter rank" }}
            field={"rank"}
            value={inputs}
            error={errors}
            inputSocket={formPlug}
            onChange={inputHandler}
          />
        </div>
      </div>
    </>
  );
}
