import React, { useEffect, useState } from "react";
import "./RadioButtons.scss";
import { v4 } from "uuid";

let validationObj = {
  checkIfTrue: { message: "", condition: "" },
};

export default function RadioButtons({
  className,
  label,
  buttons,
  name,
  required,
  field,
  value,
  inputSocket,
  error,
  onChange,
  multiChoice,
  condition,
  validation = validationObj,
  clearOnHide,
  disabled,
}) {
  let uuid = v4();

  const [state, setState] = useState(null);
  const [inError, setInError] = useState("");

  useEffect(() => {
    multiChoice
      ? value?.length > 0
        ? setState(value)
        : setState(null)
      : String(value?.[name])
      ? setState(String(value?.[name]))
      : setState(null);
  }, [value]);

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field };
      if (required) obj.required = required;
      if (ifObjectExist(validation.checkIfTrue))
        obj.checkIfTrue = validation.checkIfTrue;
      inputSocket && inputSocket(obj);
      if (Object.keys(obj).length > 1 && !inputSocket)
        setInError("Please connect 'formPlug' to 'inputSocket'");
    }
    return () => {
      inputSocket && inputSocket({ key: field, clearValidation: true });
    };
  }, [
    ...(required && typeof required === "object"
      ? Object.values(required)
      : [required]),
    condition,
  ]);

  useEffect(() => {
    return () => {
      condition &&
        clearOnHide &&
        inputSocket &&
        inputSocket({ key: field, clearValidation: true, clearValue: true });
    };
  }, [condition]);

  function ifObjectExist(obj = {}) {
    return Object.values(obj).join("").length > 0 || obj === true;
  }

  function changeHandler(value) {
    setState((s) => {
      let res = multiChoice
        ? s
          ? s?.includes(value)
            ? s?.filter((i) => i !== value)
            : [...s, value]
          : [value]
        : String(value);
      onChange && onChange(res, name);
      return res;
    });
  }

  if (!condition && condition !== undefined) return null;

  return (
    <span id="RadioButtons" className={className || ""}>
      <span className="wrapper">
        {label && (
          <label>
            <p>{label}</p>
            {(required?.condition !== undefined
              ? required?.condition
              : required) && <em>*</em>}
          </label>
        )}
        <span className="buttons">
          {buttons &&
            buttons.map((btn, i) => {
              return (
                <span id={"btn" + i} className="radiobtn" key={i}>
                  <label htmlFor={uuid + btn.label}>
                    {btn.label || "label"}
                  </label>
                  <input
                    type={multiChoice ? "checkbox" : "radio"}
                    id={uuid + btn.label}
                    name={name}
                    checked={
                      multiChoice
                        ? state?.includes(btn?.value) ?? false
                        : state === String(btn?.value)
                    }
                    onChange={(e) => changeHandler(btn.value)}
                    disabled={disabled}
                  />
                </span>
              );
            })}
        </span>
      </span>
      {(inError || (typeof error === "object" ? error?.[field] : error)) && (
        <small id="error" className= {multiChoice ? "mt-4":"mt-0"}>
          {inError ||
            (typeof error === "object" ? error?.[field] || "" : error)}
        </small>
      )}
    </span>
  );
}


