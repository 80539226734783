import React from "react";
import "./SupportedFormats.scss";

import icon_info from "../../assets/icons/icon.svg";

export default function SupportedFormats({ formats, maxSize }) {
  return (
    <p id="SupportedFormats" className="mb-3">
      <img src={icon_info} />
      {`${"Supported formats"} : ${
        formats?.toString().split(",").join(", ") || ""
      }${maxSize ? ` ${"and size upto"} ${maxSize} ${"MB"}` : ""}`}
    </p>
  );
}
