import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  getSeasonDetail,
  insertSeason,
  updateSeason,
} from "../../redux/actionCreator";
import { useNavigate, useParams } from "react-router-dom";
import {
  detail_season_loading,
  season_details,
  season_path,
  update_season_loading,
} from "../../redux/commonReducer";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import MediaUploader from "../../components/media-uploader/MediaUploader";
import moment from "moment/moment";
import Select from "../../commonComponents/Select/Select";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

export default function Seasons() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector(detail_season_loading);
  const details = useSelector(season_details);
  const path = useSelector(season_path);
  const submitLoading = useSelector(update_season_loading);

  const [data, setData] = useState({
    name: "",
    icon: "",
    season_months: [],
  });
  const [err, setErr] = useState({});

  const seasonData = [
    { month: "Jan" },
    { month: "Feb" },
    { month: "Mar" },
    { month: "Apr" },
    { month: "May" },
    { month: "Jun" },
    { month: "Jul" },
    { month: "Aug" },
    { month: "Sep" },
    { month: "Oct" },
    { month: "Nov" },
    { month: "Dec" },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getSeasonDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        name: details?.name,
        icon: details?.icon
          ? {
              preview: `${IMAGE_BASE_URL}${path}/${details?.icon}`,
              file: details?.icon,
              image: details?.icon,
            }
          : "",
        icon_alt: details?.icon_alt,
        season_months: seasonData?.filter((ele) =>
          details?.season_months?.includes(ele?.month)
        ),
      };
      setData({ ...result });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    
    setData({
      ...data,
      [key]: value,
     
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (!data?.name?.trim()?.length) {
      temp = {
        ...temp,
        name: "Please enter name",
      };
    }
    // if (!data?.icon_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     icon_alt: "Please enter alt text",
    //   };
    // }
    if (data?.icon === "") {
      console.log(data.icon.file)
      temp = {
        ...temp,
        icon: "Please upload icon",
      };
    } else if (!/\.(jpg|jpeg|png|svg)$/i.test(data?.icon?.file)) {
  
      temp = {
        ...temp,
        icon: "Invalid format. Please upload a JPG, PNG, or SVG file.",
      };
    }
    

    if (data?.season_months?.length == 0) {
      temp = {
        ...temp,
        season_months: "Please select season months",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let formData = new FormData();
      formData.append("name", data?.name?.trim());
      data?.season_months?.forEach((item, i) => {
        formData.append(`season_months[]`, item?.month);
      });
      formData.append("icon", data?.icon?.file ?? data?.icon?.image);
      formData.append("icon_alt", data?.icon_alt?.trim());
    
      if (id) {
        formData.append("id", id);
        dispatch(
          updateSeason(formData, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertSeason(formData, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      name: "",
      icon_alt: "",
      icon: "",
      season_months: [],
    });
    navigate("/manage-season");
  };

  const handleImageUpload = (event, type) => {
    const file = event.target.files?.[0];
    if (file) {
        
      setData((prevState) => ({
        ...prevState,
        icon_alt:prevState?.name || "",
        [type]: { file, preview: URL.createObjectURL(file) },
      }));
       

      setErr((prevState) => ({
        ...prevState,
        [type]: "",
      }));
    }
  };

  const removeImage = (type) => {
    setData((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  return (
   loading?  (<LoadingSpinner height="200px" /> ):(
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">{id ? "Edit Season":"Add Season"}</h5>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Name <em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("name", e?.target?.value)
                      }
                      value={data?.name}
                    />
                    <small id="error" className="error">
                      {err?.name}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <Select
                      title="Season Months"
                      className="css-slc-esc1 mb-3 "
                      placeholder={"Select"}
                      options={seasonData}
                      labelSetter={(o) => o?.month}
                      valueSetter={(o) => o.month}
                      field={"season_months"}
                      required={{message:"Please select season months "}}
                      error={err.season_months}
                      value={data.season_months ? data.season_months : []}
                      onChange={(e) => onChangeHandler("season_months", e)}
                      inputSocket={() => {}}
                      isMulti
                      dontSort
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Upload icon <em>*</em></label>
                    <MediaUploader
                      media={data.icon}
                      onUpload={(e) => handleImageUpload(e, "icon")}
                      onRemove={() => removeImage("icon")}
                    />
                    <small id="error" className="error">
                      {err?.icon}
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">Add Alt Text</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("icon_alt", e?.target?.value)
                      }
                      value={data?.icon_alt}
                    />
                    <small id="error" className="error">
                      {err?.icon_alt}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary m-2"
                    onClick={() => onSubmitHandler()}
                  >
                    Submit
                  </button>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => onCancelHandler()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
