import Checkbox from "../../../commonComponents/Checkbox/Checkbox";
import Select from "../../../commonComponents/Select/Select";
import TextEditor from "../../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../../utils/editorConfig";

const CustomerEngagement = ({
  questionnaire,
  questionnaireDataChangeHandler,
  paymentMethodList,
  formPlug,
  inputs,
  errors,
  inputHandler,
  paymentTypeList,
  details,
}) => {
  return (
    <div className="card">
      <div className="card-body pt-0">
        <div className="">
          {/* <div className="tab__header">
            <h2>Main Passenger</h2>
          </div> */}
          <div className="mt-3">
            {questionnaire?.map((ele, index) => {
              return (
                <div
                  className="grid__items mb-1 mt-5"
                  style={{ gridTemplateColumns: "repeat(1, 1fr)" }}
                >
                  <div className="item questions">
                    <p>Question {index + 1}</p>
                    <h3 className="question">{ele?.title}</h3>
                    <div className="login__access">
                      <p>Yes</p>
                      <div className="toggle__btn">
                        <Checkbox
                          label=""
                          className="css-tgl-esc1"
                          inputSocket={() => {}}
                          field={"answer"}
                          toggleSwitch={{ true: "", false: "" }}
                          checked={ele.answer}
                          onChange={(e, key) => {
                            questionnaireDataChangeHandler(
                              key,
                              e,
                              index,
                              "questionnaire"
                            );
                          }}
                        />
                      </div>
                      <p>No</p>
                    </div>
                    <h4>Add Comment</h4>
                    <textarea
                      className="form-control"
                      rows={4}
                      placeholder="Type here"
                      onChange={(e) => {
                        questionnaireDataChangeHandler(
                          "comment",
                          e?.target?.value,
                          index,
                          "questionnaire"
                        );
                      }}
                      value={ele?.comment}
                    ></textarea>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
          <div className="col-md-4 mt-3">
            <Select
              title="Payment Method"
              className="css-slc-esc1 mb-3"
              placeholder={"Select"}
              options={paymentMethodList}
              labelSetter={(o) => o?.label}
              valueSetter={(o) => o.value}
              field={"payment_method"}
              inputSocket={formPlug}
              value={inputs.payment_method}
              onChange={(e, key) => {
                inputHandler(e, key);
              }}
              error={errors[`payment_method`]}
              required={{ message: "Please select payment method" }}
            />
          </div>
          <div className="col-md-4 mt-3">
            <Select
              title="Payment type"
              className="css-slc-esc1 mb-3"
              placeholder={"Select"}
              options={paymentTypeList}
              labelSetter={(o) => o?.label}
              valueSetter={(o) => o?.value}
              field={"payment_mode"}
              inputSocket={formPlug}
              value={inputs.payment_mode}
              onChange={(e, key) => {
                inputHandler(e, key);
              }}
              error={errors[`payment_mode`]}
              disabled={details?.is_payment.length}
              required={{ message: "Please select payment mode" }}
            />
          </div>
          <div className="col-12">
            <TextEditor
              config={editorConfigEn}
              label={"Additional Information"}
              className={"mb-3"}
              field={"additional_info"}
              inputSocket={formPlug}
              required={{
                message: "Please enter additional information",
              }}
              placeholder="Type Here"
              value={inputs?.additional_info}
              onBlur={(newContent) =>
                inputHandler(newContent, "additional_info")
              }
              onChange={(e, key) => {
                inputHandler(e, key);
              }}
              error={errors[`additional_info`]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerEngagement;
