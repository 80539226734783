import React, { useEffect, useState } from "react";
import Input from "../../commonComponents/Input/Input";
import useForm from "../../utils/Hooks/useForm";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getModules,
  getUserDetail,
  insertUser,
  updateUser,
} from "../../redux/actionCreator";
import Checkbox from "../../commonComponents/Checkbox/Checkbox";
import RadioButtons from "../../commonComponents/RadioButtons/RadioButtons";
import {
  modules_data,
  permission_details,
  permission_path,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";

export default function AddPermission() {
  const [invalidUpload, setInvalidUpload] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const details = useSelector(permission_details);
  const path = useSelector(permission_path);
  const modules = useSelector(modules_data);
  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({
    customPermissions: [],
  });

  useEffect(() => {
    dispatch(getModules());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getUserDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        profile_image: details?.profile_image
          ? [
              {
                type: details?.profile_image?.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${details?.profile_image}`,
                name: `${details?.profile_image}`,
                response: `${details?.profile_image}`,
              },
            ]
          : "",
        profile_image_alt: details?.profile_image_alt,
        username: details?.username,
        first_name: details?.first_name,
        last_name: details?.last_name,
        email: details?.email,
        phone: details?.phone,
        gender: details?.gender,
        customPermissions: details?.customPermissions,
      };

      setInputs({ ...result });
    }
  }, [details]);

  const removeImage = (type) => {
    setInputs((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const onChangeHandler = (value) => {
    console.log(value);
    setErrors((prev) => ({
      ...prev,
      customPermissions: "",
    }));
    setInputs((prev) => ({
      ...prev,
      customPermissions: value,
    }));
  };

  const singleFileUploadHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setInputs((s) => ({
          ...s,
          [field]: [
            {
              type: e[0].type,
              url: e[0].url,
              name: e[0]?.name,
              metaFile: e,
            },
          ],
          profile_image_alt: "Profile Image Alt" || " ",
        }));
      }
    }
  };

  const onCancelHandler = () => {
    setInputs({});
    setErrors({});
    navigate("/manage-permission");
  };

  const onSubmitHandler = async () => {
    let isValidated = await validate({
      returnErrors: true,
    });

    if (isValidated?.valid && !invalidUpload) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    console.log(inputs?.customPermissions);
    let formData = new FormData();
    formData.append(
      "profile_image",
      inputs?.profile_image?.[0]?.metaFile?.[0]?.metaFile ??
        inputs?.profile_image?.[0]?.name
    );

    console.log(formData);
    formData.append("profile_image_alt", inputs?.profile_image_alt);
    formData.append("username", inputs?.username);
    formData.append("first_name", inputs?.first_name);
    formData.append("last_name", inputs?.last_name);
    formData.append("email", inputs?.email);
    formData.append("phone", inputs?.phone);
    formData.append("gender", inputs?.gender);
    inputs.customPermissions.forEach((item) => {
      formData.append("customPermissions", item);
    });

    if (id) {
      formData.append("id", id);
      dispatch(
        updateUser(formData, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    } else {
      dispatch(
        insertUser(formData, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">
              {id ? "Edit User" : "Add User"}
            </h5>
            <div className="row">
              <div className="col-6 mb-3">
                <FileUpload
                  style={{
                    marginRight: 5,
                    marginTop: 15,
                  }}
                  className="css-atc-esc5  mt-3"
                  label="Profile Image"
                  accept={["jpg", "jpeg", "png", "svg"]}
                  icon={"/assets/img/icons/plus_black.svg"}
                  closeIcon={
                    <AiOutlineClose
                      size={30}
                      className="remove-icon"
                      onClick={() => removeImage("profile_image")}
                    />
                  }
                  onChange={(e, isInvalid, message, isClosing) => {
                    setErrors((s) => ({
                      ...s,
                      profile_image: "",
                    }));
                    singleFileUploadHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "profile_image"
                    );
                  }}
                  error={errors.profile_image}
                  width={28}
                  field={"profile_image"}
                  id="profile_image"
                  maxFileSizeMB={50}
                  multiple={false}
                  showPreview
                  files={inputs?.profile_image}
                  required={{ message: "Please upload image" }}
                  inputSocket={formPlug}
                />
              </div>
              <div className="col-6">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="Profile Image Alt"
                  placeholder="Type here"
                  // required={{ message: "Please enter image Alt" }}
                  field={"profile_image_alt"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="User Name"
                  placeholder="Type here"
                  required={{ message: "Please enter user name" }}
                  field={"username"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-6">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="First Name"
                  placeholder="Type here"
                  required={{ message: "Please enter first Name" }}
                  field={"first_name"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-6">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="Last Name"
                  placeholder="Type here"
                  required={{ message: "Please enter last name" }}
                  field={"last_name"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-6">
                <Input
                  type={"email"}
                  className="css-inp-esc1 mb-3"
                  label="Email"
                  placeholder="Type here"
                  validation={{ email: true }}
                  readOnly={id ? inputs.email : ""}
                  required={id ? "" : { message: "Please enter email" }}
                  field={"email"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-6">
                <Input
                  type={"number"}
                  className="css-inp-esc1 mb-3"
                  label="Phone Number"
                  placeholder="Type here"
                  required={{ message: "Please enter phone number" }}
                  field={"phone"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  validation={{ maxChar: 12 }}
                />
              </div>
              <div className="col-4 mb-3">
                <RadioButtons
                  label={"Gender"}
                  buttons={[
                    {
                      label: "Male",
                      value: "male",
                    },
                    {
                      label: "Female",
                      value: "female",
                    },
                  ]}
                  name="gender"
                  placeholder={"Select Gender"}
                  required={{ message: "Please select  an option" }}
                  field={"gender"}
                  error={errors?.gender}
                  value={{
                    gender: inputs?.gender,
                  }}
                  onChange={(value, key) => inputHandler(value, key)}
                  inputSocket={formPlug}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div>
                <RadioButtons
                  className="text-capitalize"
                  label="Add Permission"
                  multiChoice
                  buttons={modules?.map((ele) => ({
                    label: ele?.module_name,
                    value: ele?._id,
                  }))}
                  value={inputs.customPermissions}
                  name="customPermissions"
                  field="customPermissions"
                  onChange={(value) => onChangeHandler(value)}
                  error={errors?.customPermissions}
                  inputSocket={formPlug}
                  placeholder="Select Permission"
                  required={{ message: "Please select an option" }}
                />
              </div>
              <div className="col-12 mt-4">
                <button
                  type="submit"
                  className="btn btn-primary m-2"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
