import axios from "axios";
import { API_BASE_URL } from "../constants/configuration";

const defaultOptions = {
  baseURL: API_BASE_URL,
};

// axios instance for making requests
const axiosInstance = axios.create(defaultOptions);

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  let token = localStorage.getItem("token");
  let csrf = localStorage.getItem("csrf");
  token && (config.headers.Authorization = `Bearer ${token}`);
  csrf && (config.headers["x-csrf-token"] = csrf);
  return config;
});

axiosInstance.interceptors.response.use(
  (response, dispatch) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    console.log(error.response.data.token,error.response.data)
    if (error.response.data.token == false) {

      return axios
        .get(`${API_BASE_URL}logout`, {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "x-csrf-token" : localStorage.getItem("csrf")
        })
        .then((res) => {
          if (res.data.status) {
            localStorage.clear();
            window.location.hash = "/login";
          }
        })
        .catch((err) => {
          localStorage.clear();
          window.location.hash = "/login";
        });
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default axiosInstance;
