import React from "react";

export default function PageNotFound() {


  return (
    <div
      className="d-flex justify-content-center align-items-end"
      style={{ height: "40vh" }}
    >
      <h1 style={{ fontSize: "34px" }}>Page Not Found 404 !</h1>
    </div>
  );
}
