import React, { useEffect, useState, useRef } from "react";
import EditIcon from "../../assets/icons/EDIT.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  detail_room_loading,
  room_details,
  update_room_loading,
} from "../../redux/commonReducer";
import {
  getRoomCostDetail,
  insertRoomCosting,
} from "../../redux/actionCreator";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

export default function HotelRooms() {
  const dispatch = useDispatch();
  const inputRef = useRef([])

  const submitLoading = useSelector(update_room_loading);
  const detailLoading = useSelector(detail_room_loading);
  const details = useSelector(room_details);

  const [data, setData] = useState([
    { month: "January", key: "january", value: "", edit: false },
    { month: "February", key: "february", value: "", edit: false },
    { month: "March", key: "march", value: "", edit: false },
    { month: "April", key: "april", value: "", edit: false },
    { month: "May", key: "may", value: "", edit: false },
    { month: "June", key: "june", value: "", edit: false },
    { month: "July", key: "july", value: "", edit: false },
    { month: "August", key: "august", value: "", edit: false },
    { month: "September", key: "september", value: "", edit: false },
    { month: "October", key: "october", value: "", edit: false },
    { month: "November", key: "november", value: "", edit: false },
    { month: "December", key: "december", value: "", edit: false },
  ]);
  const [err, setErr] = useState({});

  useEffect(() => {
    dispatch(getRoomCostDetail());
  }, []);
  useEffect(() => {
    if (details) {
      if (details?.room_costing?.length) {
        let arr = details?.room_costing?.map((ele) => {
          return {
            month: Object.keys(ele)[0],
            key: Object.keys(ele)[0],
            value: Object.values(ele)[0],
            edit: false,
          };
        });
        console.log(arr);
        setData(arr);
      }
    }
  }, [details]);

  const handleValueChange = (index, value, field) => {
    if (field === "edit") {
      inputRef.current[index]?.focus();
    }
    const temp = [...data];
    temp[index][field] = value;
    setData(temp);
    setErr({
      ...err,
      [`${field}_${index}`]: "",
    });

  };

  const validate = () => {
    let temp = {};
    data?.forEach((ele, i) => {
      if (!ele.value?.trim()?.length) {
        temp[`value_${i}`] = "Please enter value";
      }
    });
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };
  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        room_costing: data?.map((item, i) => {
          return {
            [`${item?.key}`]: item?.value,
          };
        }),
      };
      console.log(raw);
      dispatch(
        insertRoomCosting(raw, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    dispatch(getRoomCostDetail());
  };
  return (
    detailLoading ? (<LoadingSpinner height={"200px"} />) : (

      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Room costing </h5>
              <div className="row cost__table">
                <table className="table ">
                  <thead className="table_head">
                    <tr>
                      <th scope="col">Sl.No</th>
                      <th scope="col">Month</th>
                      <th scope="col">Per Head Room Cost (USD) <em>*</em></th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((ele, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td
                            className="name"
                            style={{ textTransform: "capitalize" }}
                          >
                            {ele?.month}
                          </td>
                          <td>
                            <input
                              type="text"
                              ref={(el) => (inputRef.current[i] = el)}
                              className="form-control typeNumber"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d{0,10}$/.test(inputValue)) {
                                  handleValueChange(i, inputValue, "value");
                                }
                              }}
                              value={ele?.value}
                              placeholder="Type here"
                              disabled={!ele?.edit}
                              maxLength="10"
                            />
                            {err && (
                            <small id="error" className="error">
                              {err[`value_${i}`]}
                            </small>
                            )}
                          </td>
                          <td className="actions">
                            <button
                              className="btn-sm p-0"
                              onClick={() => handleValueChange(i, true, "edit")}
                            >
                              <img
                                src={EditIcon}
                                alt=""
                                style={{
                                  height: "38px",
                                  width: "38px",
                                  marginRight: "0px",
                                }}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary m-2"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
}
