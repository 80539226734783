import React, { useEffect, useRef, useState } from "react";
import "./Login.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login_loading } from "../../redux/commonReducer";
import { login } from "../../redux/actionCreator";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import cryptoJs from "crypto-js";
import { v4 } from "uuid";

export default function Login() {
  const pwdInput = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const uuid = v4();

  const loading = useSelector(login_loading);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate(-1);
    }
  }, []);

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (username?.trim() == "" || password?.trim() == "") {
      toast.error("Please enter email and password", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      const data = {
        email: username,
        // password :password
        // password: window.btoa(unescape(encodeURIComponent(password))),
        password:
          cryptoJs.AES.encrypt(password, uuid).toString() +
          "_" +
          window.btoa(uuid + "_" + username),
      };
      dispatch(
        login(data, (msg) => {
          if (msg == true) {
            navigate("/");
            toast.success("Login successfully", {
              position: "bottom-center",
              autoClose: 3000,
            });
          }
        })
      );
    }
  };

  const onUsernamekeyPress = (e) => {
    if (e.key == "Enter") {
      pwdInput.current.focus();
    }
  };

  const onEnterLogin = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
    }
  };

  return (
    <div id="login-page">
      <div class="container">
        <div class="c-r row justify-content-md-center">
          <div class="col-md-12 col-lg-4">
            <div class="card login-box-container">
              <div class="card-body">
                <div class="authent-logo">
                  <img
                    width={100}
                    className="logo_img"
                    src="/assets/img/logo.png"
                    alt=""
                  />
                </div>
                <div class="authent-text">
                  <p>Welcome to SKYWARD</p>
                  <p>Please Sign-in to your account.</p>
                </div>

                <form>
                  <div class="mb-3">
                    <div class="form-floating">
                      <input
                        type="email"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete="off"
                        onKeyDown={(e) => onUsernamekeyPress(e)}
                      />
                      <label for="floatingInput">Email address</label>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="form-floating">
                      <input
                        type="password"
                        class="form-control"
                        id="floatingPassword"
                        ref={pwdInput}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        autoComplete="off"
                        onKeyDown={(e) => onEnterLogin(e)}
                      />
                      <label for="floatingPassword">Password</label>
                    </div>
                  </div>
                  <div class="d-grid">
                    <button
                      type="submit"
                      class="btn btn-info m-b-xs"
                      onClick={(e) => handleSubmit(e)}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                        ></Spinner>
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
