import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useFilter from "../../utils/Hooks/useFilter";
import feather from "feather-icons";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";
import Actions from "../../commonComponents/Actions/Actions";
import EditIcon from "../../assets/icons/EDIT.svg";
import DeleteIcon from "../../assets/icons/DELETE.svg";
import {
  package_data,
  package_loading,
  package_page_details,
  package_path,
} from "../../redux/commonReducer";
import {
  deletePackage,
  getPackage,
  updatePackageRank,
} from "../../redux/actionCreator";
import DeleteModal from "../../commonComponents/DeleteModal/DeleteModal";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

export default function ManagePackages() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const packageData = useSelector(package_data);
  const pageData = useSelector(package_page_details);
  const path = useSelector(package_path);
  const loading = useSelector(package_loading);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_date",
    sortValue: -1,
    keyword: "",
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [data, setData] = useState([]);
  const [err, setErr] = useState({});

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  useEffect(() => {
    feather.replace();
  }, []);

  useEffect(() => {
    setData([]);
    let arr = packageData?.map((item, i) => {
      return {
        ...item,
      };
    });
    setData(arr);
  }, [packageData]);

  const getSearchDataHandler = (filter) => {
    let raw = {
      page: filter.page,
      perPage: filter.perPage,
      sort_order: filter.sortValue,
      sort_key: filter.sortColumn,
      keyword: filter?.keyword?.trim() ?? "",
    };
    dispatch(getPackage(raw));
  };

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const handleClose = () => {
    setDeleteID("");
    setShowDeleteModal(false);
  };

  const deleteHandler = () => {
    dispatch(
      deletePackage(deleteID, () => {
        setShowDeleteModal(false);
        setDeleteID("");
        if (data?.length == 1 && pageData?.pages > 1 && filterData?.page != 0) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });
          let raw = {
            page: filterData?.page - 1,
            perPage: filterData.perPage,
            sort_order: filterData.sortValue,
            sort_key: filterData.sortColumn,
            keyword: filterData?.keyword?.trim() ?? "",
          };
          dispatch(getPackage(raw));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  const handleValueChange = (index, value, field) => {
    console.log(`${field}_${index}`);
    const temp = [...data];
    temp[index][field] = value;
    setData(temp);
    setErr({
      ...err,
      [`${field}_${index}`]: "",
    });
  };

  const onEnter = (e, item) => {
    if (e.key == "Enter") {
      let raw = {
        package_id: item?._id,
        rank: item?.rank,
      };
      dispatch(
        updatePackageRank(raw, (res) => {
          if (res.status) {
            getSearchDataHandler(filterData);
          }
        })
      );
    }
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <div className="table__add border-style">
              <h5 className="card-title">Manage Packages</h5>

              <Link to="/add-package">
                <button className="btn btn-primary">Add Package</button>
              </Link>
            </div>
            <div className="search__head">
              <div className="count">
                <EntriesCounter
                  value={filterData.perPage}
                  onChange={(e) => {
                    filterHandler("entries", e);
                  }}
                />
              </div>
              <div className="search">
                <Input
                  value={filterData.keyword}
                  type="text"
                  className="css-inp-esc2 search"
                  // icon={GenericSearchIcon}
                  placeholder={"Search Titles"}
                  reverse
                  width="300px"
                  field="keyword"
                  showCloseBtn
                  onChange={(e) => !e && filterHandler("keyword", e)}
                  onSubmit={(e) => {
                    filterHandler("keyword", e);
                  }}
                  onIconClick={(e) => {
                    filterHandler("keyword", e);
                  }}
                />
              </div>
            </div>
            {loading ? (
              <LoadingSpinner height={"200px"} />
            ) : (
              <Table
                titles={[
                  {
                    title: "Sl.No",
                    key: "sl_no",
                    width: "10px",
                  },
                  {
                    title: "Package ID",
                    key: "package_id",
                    sort: true,
                    width: "150px",
                  },
                  {
                    title: "Package Name",
                    key: "package_name",
                    sort: true,
                    width: "300px",
                  },
                  {
                    title: "Rank",
                    key: "rank",
                    sort: true,
                    width: "200px",
                  },
                  {
                    title: "Duration",
                    key: "duration",
                    // sort: false,
                    width: "100px",
                  },
                  {
                    title: "Active/Inactive",
                    key: "is_active",
                    sort: true,
                    width: "50px",
                  },
                  // {
                  //   title: "Published",
                  //   key: "is_published",
                  //   sort: true,
                  //   width: "150px",
                  // },
                  { title: "Action", key: "action", width: "100px" },
                ]}
                content={data?.map((item, i) => ({
                  sl_no: filterData.page * filterData.perPage + (i + 1),
                  package_id: item?.package_id,
                  package_name: item?.package_name.includes("&amp;") 
                  ? item?.package_name.replace(/&amp;/g, "And")
                  : item?.package_name,
                  is_active:  (
                    <button
                      style={{
                        backgroundColor: item?.is_active ? "#28a745" : "#FF4545",
                        color: "#fff",
                        border: "none",
                        padding: "5px 10px",
                        borderRadius: "5px",
                      }}
                      disabled
                    >
                      {item?.is_active ? "Active" : "Inactive"}
                    </button>
                  ),

                  // is_published: item?.is_published ? "Yes" : "No",
                  rank: (
                    <>
                      <input
                        type="text"
                        className="form-control typeNumber"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^\d{0,2}$/.test(inputValue)) {
                            handleValueChange(i, inputValue, "rank");
                          }
                        }}
                        value={item?.rank}
                        placeholder="Type here"
                        // disabled={!item?.edit}
                        maxLength="10"
                        onKeyDown={(e) => onEnter(e, item)}
                      />
                      <small id="error" className="error">
                        {err[`rank_${i}`]}
                      </small>
                    </>
                  ),
                  duration: item.duration,
                  action: (
                    <Actions
                      buttons={{
                        edit: EditIcon,
                        delete: DeleteIcon,
                      }}
                      onEdit={() => F_navigate(`/update-package/${item?._id}`)}
                      onDelete={() => showDeletePopup(item?._id)}
                    />
                  ),
                }))}
                onSort={(e) => filterHandler("sort", e)}
                page={filterData.page}
                pagesCount={pageData?.pages}
                pageStatus={pageData?.status}
                onPageChange={(e) => {
                  filterHandler("page", e.selected);
                }}
                sortValue={{
                  key: filterData.sortColumn,
                  value: filterData.sortValue,
                }}
              />
            )}
          </div>
        </div>
      </div>

      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleClose={handleClose}
        deleteHandler={deleteHandler}
        title={"Delete Package"}
      />
    </div>
  );
}
