import { Root, List, Trigger, Content } from "@radix-ui/react-tabs";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import Seo from "../../components/Seo";
import { useDispatch, useSelector } from "react-redux";
import useFilter from "../../utils/Hooks/useFilter";
import {
  deleteVehicleInfo,
  getPartnersDetail,
  getVehicleInfo,
  insertVehiclePartners,
  uploadFile,
} from "../../redux/actionCreator";
import {
  update_vehicle_partners_loading,
  vehicle_data,
  vehicle_loading,
  vehicle_page_details,
  vehicle_partners_details,
  vehicle_partners_path,
  vehicle_path,
} from "../../redux/commonReducer";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import Actions from "../../commonComponents/Actions/Actions";
import Table from "../../commonComponents/Table/Table";
import EditIcon from "../../assets/icons/EDIT.svg";
import DeleteIcon from "../../assets/icons/DELETE.svg";
import { Modal, ProgressBar } from "react-bootstrap";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import SupportedFormats from "../../commonComponents/SupportedFormats/SupportedFormats";
import SortableList from "../../commonComponents/sortFiles/SortableList";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import DeleteModal from "../../commonComponents/DeleteModal/DeleteModal";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

function VehicleInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const vehicleData = useSelector(vehicle_data);
  const pageData = useSelector(vehicle_page_details);
  const updatePartnersLoading = useSelector(update_vehicle_partners_loading);
  const details = useSelector(vehicle_partners_details);
  const vehiclePartnersPath = useSelector(vehicle_partners_path);
  const path = useSelector(vehicle_path);
  const loading = useSelector(vehicle_loading);

  const [data, setData] = useState({
    partners: [],
  });
  const [err, setErr] = useState({});
  const [trackId, setTrackId] = useState("");
  const [addAttachmentProgress, setAddAttachmentProgress] = useState(0);
  const [imageLoader, setImageLoader] = useState(false);
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [delFile, setDelFile] = useState([]);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_date",
    sortValue: -1,
    keyword: "",
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  useEffect(() => {
    getSearchDataHandler(initialFilter);
    dispatch(getPartnersDetail());
  }, []);

  useEffect(() => {
    if (details?.partners?.length) {
      let arr = details?.partners?.map((ele, i) => {
        let obj = {
          type: ele?.image?.split(".").pop(),
          url: `${IMAGE_BASE_URL}${vehiclePartnersPath}/${ele?.image}`,
          name: `${ele?.image}`,
          response: `${ele?.image}`,
          id: ele?._id,
          alt: ele?.alt,
        };
        return obj;
      });
      setData({
        ...data,
        partners: [...arr],
      });
    }
  }, [details]);

  const getSearchDataHandler = (filter) => {
    let formData = new FormData();
    formData.append("page", filter.page);
    formData.append("perPage", filter.perPage);
    formData.append("sort_order", filter.sortValue);
    formData.append("sort_key", filter.sortColumn);
    formData.append("keyword", filter?.keyword?.trim() ?? "");
    dispatch(getVehicleInfo(formData));
    console.log(formData);
  };

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const handleClose = () => {
    setDeleteID("");
    setShowDeleteModal(false);
  };

  const deleteHandler = () => {
    dispatch(
      deleteVehicleInfo(deleteID, () => {
        setShowDeleteModal(false);
        setDeleteID("");
        if (data?.length == 1 && pageData?.pages > 1 && filterData?.page != 0) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });
          let formData = new FormData();
          formData.append("page", filterData?.page - 1);
          formData.append("perPage", filterData.perPage);
          formData.append("sort_order", filterData.sortValue);
          formData.append("sort_key", filterData.sortColumn);
          formData.append("keyword", filterData?.keyword?.trim() ?? "");
          dispatch(getVehicleInfo(formData));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  const handleDelete = (file, field) => {
    console.log(file);
    let temp = [...data?.[field]];
    if (temp?.length) {
      temp = temp?.filter((ele) => ele?.id !== file?.id);
      setData({
        ...data,
        [field]: temp,
      });
      setDelFile([...delFile, file?.response]);
    }
  };

  const fileSelectedHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErr({
        ...err,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErr({
        ...err,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setImageLoader(true);
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        formData.append(`path`, "vehicleInfo/partners");
        dispatch(
          uploadFile(
            formData,
            (res) => {
              let oldFiles = e?.filter((el) => el?.metaFile == undefined);
              let arr = e
                ?.filter((el) => el?.metaFile != undefined)
                ?.map((ele, i) => {
                  return {
                    // ...ele,
                    url: ele?.url,
                    name: ele?.name,
                    response: res?.data[i],
                    partners: res?.data[i],
                    id: res?.data[i] + i + 1,
                    alt: "",
                  };
                });
              setErr({
                ...err,
                [field]: "",
              });
              setData((s) => ({
                ...s,
                [field]: [...oldFiles, ...arr],
              }));
              setImageLoader(false);
            },
            (progress) => {
              setAddAttachmentProgress(progress);
            }
          )
        );
      }
    }
  };

  const altChangeHandler = (field, value, index, id) => {
    let dataList = { ...data };
    dataList.partners[index][field] = value;
    setData(dataList);
    setErr({
      ...err,
      [`${field}_${id}`]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (data?.partners == "") {
      temp = {
        ...temp,
        partners: "Please upload logos",
      };
    } else {
      data?.partners?.forEach((ele, i) => {
        if (!ele.alt?.trim()?.length) {
          temp[`alt_${ele?.id}`] = "Please enter alt text";
        }
      });
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        partners: data?.partners?.map((item, i) => {
          return {
            [`image`]: item?.response,
            [`alt`]: item?.alt?.trim(),
          };
        }),
        track_id: trackId,
        path : "vehicleInfo/partners"
      };
      console.log(raw);
      dispatch(
        insertVehiclePartners(raw, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    setData({
      partners: [],
    });
    dispatch(getPartnersDetail());
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <Root className="TabsRoot" defaultValue="tab1">
              <List className="TabsList" aria-label="Manage your account">
                <Trigger className="TabsTrigger" value="tab1">
                  Manage Vehicle
                </Trigger>
                <Trigger className="TabsTrigger" value="tab2">
                  Manage Partners
                </Trigger>
              </List>
              <Content className="TabsContent" value="tab1">
                <div className="table__add border-style">
                  <h5 className="card-title ">Manage Vehicle</h5>
                  <Link to="/add-vehicle">
                    {" "}
                    <button className="btn btn-primary">Add Vehicle</button>
                  </Link>
                </div>
                <div className="search__head">
                  <div className="count">
                    <EntriesCounter
                      value={filterData.perPage}
                      onChange={(e) => {
                        filterHandler("entries", e);
                      }}
                    />
                  </div>
                  <div className="search">
                    <Input
                      value={filterData.keyword}
                      type="text"
                      className="css-inp-esc2 search"
                      // icon={GenericSearchIcon}
                      placeholder={"Search Titles"}
                      reverse
                      width="300px"
                      field="keyword"
                      showCloseBtn
                      onChange={(e) => !e && filterHandler("keyword", e)}
                      onSubmit={(e) => {
                        filterHandler("keyword", e);
                      }}
                      onIconClick={(e) => {
                        filterHandler("keyword", e);
                      }}
                    />
                  </div>
                </div>
                {loading ? (
                  <LoadingSpinner height={"200px"} />
                ) : (
                  <div className="table-responsive">
                    <Table
                      titles={[
                        {
                          title: "Sl.No",
                          key: "sl_no",
                          width: "10px",
                        },
                        {
                          title: "Image",
                          key: "image",
                          sort: true,
                          width: "200px",
                        },
                        {
                          title: "Vehicle Name",
                          key: "title",
                          sort: true,
                          width: "200px",
                        },
                        {
                          title: "Included in package",
                          key: "is_package_cost_included",
                          sort: true,
                          width: "100px",
                        },

                        { title: "Action", key: "action", width: "100px" },
                      ]}
                      content={vehicleData?.map((item, i) => ({
                        sl_no: filterData.page * filterData.perPage + (i + 1),
                        image: (
                          <img
                            src={`${IMAGE_BASE_URL}${path}/${item?.image}`}
                            alt=""
                          />
                        ),
                        title: item?.title,
                        is_package_cost_included:
                          item?.is_package_cost_included == "1" ? "Yes" : "No",
                        action: (
                          <Actions
                            buttons={{
                              edit: EditIcon,
                              delete: DeleteIcon,
                            }}
                            onEdit={() =>
                              F_navigate(`/update-vehicle/${item?._id}`)
                            }
                            onDelete={() => showDeletePopup(item?._id)}
                          />
                        ),
                      }))}
                      onSort={(e) => filterHandler("sort", e)}
                      page={filterData.page}
                      pagesCount={pageData?.pages}
                      pageStatus={pageData?.status}
                      onPageChange={(e) => {
                        filterHandler("page", e.selected);
                      }}
                      sortValue={{
                        key: filterData.sortColumn,
                        value: filterData.sortValue,
                      }}
                    />
                  </div>
                )}
              </Content>
              <Content className="TabsContent" value="tab2">
                <h5 className="card-title border-style mb-3 ">
                  Manage Partners
                </h5>

                <div className="row mb-4">
                  <div className="col-6">
                    <div className="d-flex gap-3">
                      <FileUpload
                        style={{
                          marginRight: 5,
                          marginTop: 15,
                        }}
                        className="css-atc-esc2  mt-3"
                        label="Upload logo"
                        noLabel
                        buttonLabel="Choose File"
                        accept={["jpg", "jpeg", "png", "svg"]}
                        onChange={(e, isInvalid, message, isClosing) => {
                          setErr((s) => ({
                            ...s,
                            partners: "",
                          }));
                          fileSelectedHandler(
                            e,
                            isInvalid,
                            message,
                            isClosing,
                            "partners"
                          );
                        }}
                        field={"partners"}
                        loader={imageLoader}
                        error={err.partners}
                        id="partners"
                        maxFileSizeMB={100}
                        multiple={true}
                        files={data?.partners}
                        inputSocket={() => {}}
                      />
                      <ProgressBar
                        progress={addAttachmentProgress}
                        width={350}
                      />
                    </div>
                    <SupportedFormats
                      formats={["jpg", "jpeg", "png", "svg"]}
                      maxSize={100}
                    />
                  </div>
                  {data?.partners?.length ? (
                    <SortableList
                      setInputs={setData}
                      inputs={data}
                      field="partners"
                      handleDelete={handleDelete}
                      randomTrackID={trackId ?? ""}
                      err={err}
                      altChangeHandler={altChangeHandler}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary me-3"
                    onClick={() => onSubmitHandler()}
                  >
                    Submit
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => onCancelHandler()}
                  >
                    Cancel
                  </button>
                </div>
              </Content>
            </Root>
          </div>
        </div>

        {/* <div className="card">
          <div className="card-body">
            <Seo />
          </div>
        </div> */}
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleClose={handleClose}
        deleteHandler={deleteHandler}
        title={"Delete Vehicle"}
      />
    </div>
  );
}

export default VehicleInfo;
