const Quotation = ({
  quotation,
  setQuotation,
  airfareQuotation,
  setAirfareQuotation,
  details,
  gross_total,
  deduction,
  amount_to_be_paid,
}) => {
  const handlePackageInputChange = (e, field, index) => {
    const value = e.target.value;
    const updatedQuotation = [...quotation];
    updatedQuotation[index][field] = value;
    if (field === "unit_cost" || field === "units") {
      const unitCost = parseFloat(updatedQuotation[index]?.unit_cost || 0);
      const units = parseFloat(updatedQuotation[index]?.units || 0);
      updatedQuotation[index].total_cost = (unitCost * units).toFixed(2);
    }
    setQuotation(updatedQuotation);
  };

  const handleFlightInputChange = (e, field, index) => {
    const value = e.target.value;
    const updatedQuotation = [...airfareQuotation];
    updatedQuotation[index][field] = value;
    if (field === "unit_cost" || field === "units") {
      const unitCost = parseFloat(updatedQuotation[index]?.unit_cost || 0);
      const units = parseFloat(updatedQuotation[index]?.units || 0);
      updatedQuotation[index].total_cost = (unitCost * units).toFixed(2);
    }
    setAirfareQuotation(updatedQuotation);
  };

  return (
    <div className="card">
      <div className="card-body pt-0">
        <div className="tab-content">
          <div className="tab__header">
            <h2>Add Updates</h2>
          </div>
          <div className="quotation">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Final Quote</th>
                  <th scope="col">Unit Cost ( USD )</th>
                  <th scope="col">Units</th>
                  <th scope="col">Total ( USD )</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan={4}>Package Cost</th>
                </tr>
                {quotation?.map((ele, index) => {
                  return (
                    <tr>
                      <td>{ele?.label}</td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Unit Cost"
                          value={ele?.unit_cost}
                          onChange={(e) =>
                            handlePackageInputChange(e, "unit_cost", index)
                          }
                          disabled={details?.is_payment}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Units"
                          value={ele?.units}
                          onChange={(e) =>
                            handlePackageInputChange(e, "units", index)
                          }
                          disabled={details?.is_payment}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Total"
                          value={ele?.total_cost}
                          disabled
                        />
                      </td>
                    </tr>
                  );
                })}
                {airfareQuotation?.length ? (
                  <>
                    {" "}
                    <tr>
                      <th colSpan={4}>Airfare Cost</th>
                    </tr>
                    {airfareQuotation?.map((ele, index) => {
                      return (
                        <tr>
                          <td>{ele?.label}</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Unit Cost"
                              value={ele?.unit_cost}
                              onChange={(e) =>
                                handleFlightInputChange(e, "unit_cost", index)
                              }
                              disabled={details?.is_payment}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Units"
                              value={ele?.units}
                              onChange={(e) =>
                                handleFlightInputChange(e, "units", index)
                              }
                              disabled={details?.is_payment}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Total"
                              value={ele?.total_cost}
                              disabled
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}

                <tr>
                  <th colSpan={3}>Gross Total</th>
                  <th>{gross_total}</th>
                </tr>
                <tr>
                  <th colSpan={3}>Payment Completed</th>
                  <th>{Number(details?.amount_paid ?? 0)?.toFixed(2)}</th>
                </tr>

                <tr>
                  <th colSpan={3}>Additional Cost</th>
                  <th>
                    {(
                      Number(details?.no_of_travellers) *
                      Number(details?.custom_variable_cost)
                    )?.toFixed(2) ?? 0}
                  </th>
                </tr>
                <tr>
                  <th colSpan={3}>Discounts</th>
                  <th>{details?.total_discount ?? 0}</th>
                </tr>
                <tr>
                  <th colSpan={3}>Deductions</th>
                  <th>{deduction}</th>
                </tr>
                <tr>
                  <th colSpan={3}>Total To Be Paid</th>
                  <th>{amount_to_be_paid}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Quotation;
