import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import feather from "feather-icons";
import { useDispatch, useSelector } from "react-redux";
import useFilter from "../../utils/Hooks/useFilter";
import {
  essential_info_details,
  faq_data,
  faq_loading,
  faq_page_details,
} from "../../redux/commonReducer";
import {
  deleteEssentialInfo,
  getEsseDetail,
  getEssentialInfo,
  insertEssentialInfoDesc,
} from "../../redux/actionCreator";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";
import Actions from "../../commonComponents/Actions/Actions";
import EditIcon from "../../assets/icons/EDIT.svg";
import DeleteIcon from "../../assets/icons/DELETE.svg";
import { Modal } from "react-bootstrap";
import DeleteModal from "../../commonComponents/DeleteModal/DeleteModal";
import TextEditor from "../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../utils/editorConfig";
import useForm from "../../utils/Hooks/useForm";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

export default function ManageEssential() {
  const dispatch = useDispatch();

  const data = useSelector(faq_data);
  const pageData = useSelector(faq_page_details);
  const details = useSelector(essential_info_details);
  const loading = useSelector(faq_loading);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_date",
    sortValue: -1,
    keyword: "",
  };

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  useEffect(() => {
    feather.replace();
  }, []);

  useEffect(() => {
    getSearchDataHandler(initialFilter);
    dispatch(getEsseDetail());
  }, []);

  useEffect(() => {
    if (details?._id) {
      setInputs({
        einfo_description: details?.einfo_description,
      });
    }
  }, [details]);

  const getSearchDataHandler = (filter) => {
    let formData = new FormData();
    formData.append("page", filter.page);
    formData.append("perPage", filter.perPage);
    formData.append("sort_order", filter.sortValue);
    formData.append("sort_key", filter.sortColumn);
    formData.append("keyword", filter?.keyword?.trim() ?? "");
    console.log(formData);
    dispatch(getEssentialInfo(formData));
  };

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const handleClose = () => {
    setDeleteID("");
    setShowDeleteModal(false);
  };

  const deleteHandler = () => {
    dispatch(
      deleteEssentialInfo(deleteID, () => {
        setShowDeleteModal(false);
        setDeleteID("");
        if (data?.length == 1 && pageData?.pages > 1 && filterData?.page != 0) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });
          let formData = new FormData();
          formData.append("page", filterData?.page - 1);
          formData.append("perPage", filterData.perPage);
          formData.append("sort_order", filterData.sortValue);
          formData.append("sort_key", filterData.sortColumn);
          formData.append("keyword", filterData?.keyword?.trim() ?? "");
          dispatch(getEssentialInfo(formData));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  const onSubmitHandler = async () => {
    let isValidated = await validate();
    if (isValidated) {
      let raw = {
        einfo_description: inputs.einfo_description,
      };
      dispatch(
        insertEssentialInfoDesc(raw, (res) => {
          if (res) {
            onCancelHandler();
            getSearchDataHandler(filterData);
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    setInputs({});
    dispatch(getEsseDetail());
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <div className="table__formAdd">
              <div className="col-md-12">
                <TextEditor
                  config={editorConfigEn}
                  label={"Essential Information (Maximum 255 characters)"}
                  className={"mb-3"}
                  field={"einfo_description"}
                  onBlur={(newContent) =>
                    setInputs({
                      ...inputs,
                      einfo_description: newContent,
                    })
                  }
                  validation = {{maxChar:255}}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  required={{
                    message: "Please enter essential information",
                  }}
                  placeholder="Type Here"
                />
              </div>
              <div className="actions">
                <button
                  className="btn btn-primary"
                  onClick={() => onSubmitHandler()}
                >
                  {details?._id ? "Update" : "Add"}
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table__add border-style">
              <h5 className="card-title ">Manage Essential Information</h5>
              <div className="d-flex gap-3">
                <Link to="/add-essential">
                  <button className="btn btn-primary">Add FAQ</button>
                </Link>
              </div>
            </div>
            <div className="search__head">
              <div className="count">
                <EntriesCounter
                  value={filterData.perPage}
                  onChange={(e) => {
                    filterHandler("entries", e);
                  }}
                />
              </div>
              <div className="search">
                <Input
                  value={filterData.keyword}
                  type="text"
                  className="css-inp-esc2 search"
                  // icon={GenericSearchIcon}
                  placeholder={"Search Titles"}
                  reverse
                  width="300px"
                  field="keyword"
                  showCloseBtn
                  onChange={(e) => !e && filterHandler("keyword", e)}
                  onSubmit={(e) => {
                    filterHandler("keyword", e);
                  }}
                  onIconClick={(e) => {
                    filterHandler("keyword", e);
                  }}
                />
              </div>
            </div>
            {loading ? (
              <LoadingSpinner height={"200px"} />
            ) : (
              <Table
                titles={[
                  {
                    title: "Sl.No",
                    key: "sl_no",
                    width: "10px",
                  },
                  {
                    title: "Question",
                    key: "question",
                    sort: true,
                    width: "600px",
                  },
                  { title: "Action", key: "action", width: "100px" },
                ]}
                content={data?.map((item, i) => ({
                  sl_no: filterData.page * filterData.perPage + (i + 1),
                  question: item?.question,
                  action: (
                    <Actions
                      buttons={{
                        edit: EditIcon,
                        delete: DeleteIcon,
                      }}
                      onEdit={() =>
                        F_navigate(`/update-essential/${item?._id}`)
                      }
                      onDelete={() => showDeletePopup(item?._id)}
                    />
                  ),
                }))}
                onSort={(e) => filterHandler("sort", e)}
                page={filterData.page}
                pagesCount={pageData?.pages}
                pageStatus={pageData?.status}
                onPageChange={(e) => {
                  filterHandler("page", e.selected);
                }}
                sortValue={{
                  key: filterData.sortColumn,
                  value: filterData.sortValue,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleClose={handleClose}
        deleteHandler={deleteHandler}
        title={"Delete Essential Information"}
      />
    </div>
  );
}
