import React, { useState, useEffect, useRef } from "react";
import "./Select.scss";
import ReactSelect from "react-select";
import { MultiSelect } from "react-multi-select-component";
import DropDownicon from "../../assets/icons/Icon material-arrow-drop-down1.svg";

let validationObj = {};

export default function Select({
  placeholder = "Select",
  title,
  options,
  value,
  labelSetter,
  valueSetter,
  inputSocket,
  field,
  onChange,
  className,
  isMulti,
  Multi2,
  style,
  width,
  error,
  showCloseBtn,
  required,
  showToggleBtn,
  disabled,
  classNamePrefix,
  condition,
  validation = validationObj,
  clearOnHide,
  doTranslate,
  menuPosition,
  customStyle = {},
  dontTranslate,
  dontSort,
}) {
  const selectRef = useRef();

  const initialValue = Multi2 ? [] : "";

  const [list, setList] = useState([]);
  const [inValue, setInValue] = useState(initialValue);
  const [toggleBtn, setToggelBtn] = useState(false);
  const [inError, setInError] = useState("");

  useEffect(() => {
    setInValue(() => {
      let res = initialValue;
      if (typeof value === "object" && value?.[field] !== undefined)
        res = value?.[field];
      else if (
        typeof value === "object" &&
        value?.[field] === undefined &&
        !Array.isArray(value) &&
        !(
          options !== undefined &&
          options.find((item) => valueSetter(item) === valueSetter(value))
        )
      )
        res = initialValue;
      else if (typeof value === 'string' && !(
        options !== undefined &&
        options.find((item) => valueSetter(item) === valueSetter(value))
      )) {
        res = options?.find(item => valueSetter(item) === value)
      }
      else if (value !== undefined) res = value;
      return res;
    });
    options && setList(options);
    return () => {
      setList([]);
      setInValue(Multi2 ? [] : "");
    };
  }, [options, value]);

  // useEffect(() => {
  //   let a = selectRef.current
  //   a.addEventListener('click', () => {
  //     setTimeout(() => {
  //       let b = a.querySelector('div.search')
  //       let d = a.querySelector('.dropdown-heading-value')
  //       let c = b
  //       // d.append(b)
  //       // b.focus()
  //       // console.log(b)
  //       // console.log(d)
  //     }, 0);
  //   })
  // }, [])

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field };
      if (required) obj.required = required;
      inputSocket && inputSocket(obj);
      if (Object.keys(obj).length > 1 && !inputSocket)
        setInError("Please connect 'formPlug' to 'inputSocket'");
    }
    return () => {
      inputSocket && inputSocket({ key: field, clearValidation: true });
    };
  }, [
    ...(required && typeof required === "object"
      ? Object.values(required)
      : [required]),
    condition,
  ]);

  useEffect(() => {
    return () => {
      condition &&
        clearOnHide &&
        inputSocket &&
        inputSocket({ key: field, clearValidation: true, clearValue: true });
    };
  }, [condition]);

  function changeHandler(e) {
    setInValue(e);
    onChange && onChange(e, field);
  }

  if (!condition && condition !== undefined) return null;

  let tempList = [...list];
  let sortedList = [];

  if (
    tempList[0] &&
    labelSetter(tempList[0]) &&
    !React.isValidElement(labelSetter(tempList[0])) &&
    !dontSort
  ) {
    sortedList = tempList.sort((a, b) => {
      let aValue = labelSetter(a)[0]?.toLowerCase();
      let bValue = labelSetter(b)[0]?.toLowerCase();
      if (aValue > bValue) return 1;
      else if (aValue < bValue) return -1;
      else return 0;
    });
  } else sortedList = list;

  return (
    <span
      id="Select"
      className={
        className + (showCloseBtn && inValue ? " close_button_appear" : "")
      }
      style={{ width: width, ...style }}
    >
      {title && (
        <p className="title">
          {title}
          {(required?.condition !== undefined
            ? required?.condition
            : required) && <em>*</em>}
          {showToggleBtn && (
            <img
              className={
                "toggle_arrow" + (toggleBtn ? " expanded" : " collapsed")
              }
              src={DropDownicon}
              alt=""
              onClick={() => setToggelBtn((s) => !s)}
            />
          )}
        </p>
      )}
      {!toggleBtn && (
        <span className="select_span" ref={selectRef}>
          {Multi2 ? (
            <MultiSelect
              className="select_multi_select"
              options={list?.map((item) => {
                return {
                  ...item,
                  label: labelSetter && labelSetter(item),
                  value: valueSetter && valueSetter(item),
                };
              })}
              value={(inValue || [])?.map((item) => {
                return {
                  ...item,
                  label: labelSetter && labelSetter(item),
                  value: valueSetter && valueSetter(item),
                };
              })}
              hasSelectAll={false}
              overrideStrings={{
                selectSomeItems: dontTranslate ? placeholder : placeholder,
              }}
              labelledBy={"name"}
              disabled={list.length === 0 || disabled}
              onChange={changeHandler}
            />
          ) : (
            <ReactSelect
              className="select_react_select"
              isMulti={isMulti}
              options={sortedList}
              value={inValue ?? ""}
              placeholder={placeholder}
              isDisabled={list.length === 0 || disabled}
              getOptionLabel={(o) => labelSetter(o)}
              getOptionValue={valueSetter}
              onChange={changeHandler}
              classNamePrefix={classNamePrefix}
              menuPosition={menuPosition}
              styles={customStyle}
            />
          )}
          {showCloseBtn && (Multi2 ? inValue.length > 0 : inValue) && (
            <span
              className="close_btn"
              onClick={() => {
                setInValue("");
                onChange && onChange("", field);
              }}
            >
              ✖
            </span>
          )}
        </span>
      )}
      {(inError || (typeof error === "object" ? error?.[field] : error)) && (
        <small id="error" className="error">
          {inError ||
            (typeof error === "object" ? error?.[field] || "" : error)}
        </small>
      )}
    </span>
  );
}

{
  /* 

<Select
  title=''
  className='css-slc-esc1'
  placeholder='select...'
  options={[
    { a: 1 },
    { a: 2 },
    { a: 3 },
  ]}
  value={{}}
  labelSetter={(e) => e.a}
  valueSetter={(e) => e.a}
  onChange={(e) => { }}
  error={'error message'}
  isMulti
  showCloseBtn
  showToggleBtn
/>

*/
}
