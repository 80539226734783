import React, { useEffect, useRef, useState } from "react";
import PolicyContent from "../components/PolicyContent";
import {
  detail_privacy_loading,
  privacy_details,
  privacy_path,
  update_privacy_loading,
} from "../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacy, insertPrivacy, uploadFile } from "../redux/actionCreator";
import { IMAGE_BASE_URL } from "../constants/configuration";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const editor = useRef();

  const submitLoading = useSelector(update_privacy_loading);
  const loading = useSelector(detail_privacy_loading);
  const details = useSelector(privacy_details);
  const path = useSelector(privacy_path);

  const [data, setData] = useState({
    title: "",
    description: "",
    image_alt: "",
    image: "",
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
  });
  const [err, setErr] = useState({});
  const [trackId, setTrackId] = useState("");

  useEffect(() => {
    dispatch(getPrivacy());
  }, []);

  useEffect(() => {
    if (details?._id) {
      let result = {
        title: details?.title,
        description: details?.description,
        image_alt: details?.image_alt,
        image: details?.image
          ? {
              preview: `${IMAGE_BASE_URL}${path}/${details?.image}`,
              file: null,
              image: details?.image,
            }
          : "",
        meta_title: details?.meta_title,
        meta_keyword: details?.meta_keyword,
        meta_description: details?.meta_description,
      };
      setData({ ...result });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const singleFileSelectedHandler = (event, field) => {
    const file = event.target.files?.[0];
    setErr({
      ...err,
      [field]: "",
    });
    let formData = new FormData();
    if (trackId?.length == 0) {
      let track_id = Math.floor(1000 + Math.random() * 9000).toString();
      setTrackId(track_id);
      formData.append("track_id", track_id);
    } else {
      formData.append("track_id", trackId);
    }
    formData.append(`file`, file);
    formData.append(`path`, "cms");
    dispatch(
      uploadFile(formData, (res) => {
        setErr({
          ...err,
          [field]: "",
        });
        setData((s) => ({
          ...s,
          [field]: {
            file: file,
            image: res?.data[0],
            preview: URL.createObjectURL(file),
          },
        }));
      })
    );
  };

  const removeImage = (type) => {
    setData((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const validate = () => {
    let temp = {};
    if (!data?.title?.trim()?.length) {
      temp = {
        ...temp,
        title: "Please enter title",
      };
    }
    if (data?.description == "") {
      temp = {
        ...temp,
        description: "Please select description",
      };
    }
    if (!data?.image_alt?.trim()?.length) {
      temp = {
        ...temp,
        image_alt: "Please enter image alt text",
      };
    }
    if (data?.image == "") {
      temp = {
        ...temp,
        image: "Please upload image",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        title: data?.title?.trim(),
        description: data?.description,
        image_alt: data?.image_alt?.trim(),
        image: data?.image?.image,
        track_id: trackId,
        meta_title: data?.meta_title?.trim(),
        meta_keyword: data?.meta_keyword?.trim(),
        meta_description: data?.meta_description?.trim(),
        path : "cms"
      };
      console.log(raw);
      dispatch(
        insertPrivacy(raw, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    setData({
      title: "",
      description: "",
      image_alt: "",
      image: "",
      meta_title: "",
      meta_keyword: "",
      meta_description: "",
    });
    dispatch(getPrivacy());
  };
  return (
   
    <PolicyContent
    title={"Privacy Policy"}
    data={data}
    onChangeHandler={onChangeHandler}
    err={err}
    titleField={"title"}
    descriptionField={"description"}
    altField={"image_alt"}
    imageField={"image"}
    singleFileSelectedHandler={singleFileSelectedHandler}
    removeImage={removeImage}
    onSubmitHandler={onSubmitHandler}
    onCancelHandler={onCancelHandler}
    editor={editor}
    loading = {loading}
  />
  );
};

export default PrivacyPolicy;
