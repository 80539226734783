import { Accordion } from "react-bootstrap";
import Input from "../../../commonComponents/Input/Input";
import TextEditor from "../../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../../utils/editorConfig";
import Select from "../../../commonComponents/Select/Select";
import SupportedFormats from "../../../commonComponents/SupportedFormats/SupportedFormats";
import { AiOutlineClose } from "react-icons/ai";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import { useEffect } from "react";
import { scrollToTop } from "../../../utils/helpers";

const Itinerary = ({
  inputs,
  errors,
  formPlug,
  inputHandler,
  setErrors,
  singleFileUploadHandler,
  removeImage,
  activityData,
  attractionData,
  setInputs,
  inputDataChangeHandler,
  inputDataRemoveChangeHandler,
  mainInputFileUpdateHandler,
  validate,
  highlightPageErr,
}) => {
  useEffect(() => {
    if (errors) {
      setErrors({
        ...errors,
      });
    }
    scrollToTop()
  }, []);

  const validated = async () => {
    let validateCall = await validate({ returnErrors: true });
    console.log(validateCall, "validateCall");
  };

  // useEffect(() => {
  //   if (highlightPageErr?.includes("error")) {
  //     setTimeout(() => {
  //       validated();
  //     }, 1000);
  //   }
  // }, [highlightPageErr]);

  return (
    (
      <>
        <div className="row mb-4">
          <div className="col-12 ">
            <div className="row">
              <div className="col-12 col-md-6">
                <div>
                  <div className="d-flex gap-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 15,
                      }}
                      className="css-atc-esc5  mt-3"
                      label="Main Map"
                      accept={["jpg", "jpeg", "png"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("main_map")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          main_map: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "main_map",
                          mainInputFileUpdateHandler
                        );
                      }}
                      error={errors.main_map}
                      width={28}
                      field={"main_map"}
                      id="main_map"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.main_map}
                      required={{
                        message: "Please upload main map image",
                      }}
                      inputSocket={formPlug}
                    />
                  </div>
                  <SupportedFormats
                    formats={["jpg", "jpeg", "png"]}
                    maxSize={50}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="Image Alt Text"
                  placeholder="Type here"
                  // required={{ message: "Please enter alt text" }}
                  field={"main_map_alt"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
            </div>
            {inputs?.duration?.length && inputs?.itinerary?.length ? (
              <>
                <h5 className="package__header">Add Itinerary</h5>
                {inputs?.itinerary?.map((ele, index) => {
                  return (
                    <Accordion defaultActiveKey={index}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>Day {index + 1}</Accordion.Header>
                        <Accordion.Body>
                          <div className="row mb-5">
                            <div className="col-12 ">
                              <Input
                                className="css-inp-esc1 mb-3"
                                label="Day Title"
                                placeholder="Type here"
                                required={{
                                  message: "Please enter title",
                                }}
                                field={"title"}
                                inputSocket={formPlug}
                                value={ele?.title}
                                onChange={(e, key) => {
                                  inputDataChangeHandler(
                                    key,
                                    e,
                                    index,
                                    "itinerary"
                                  );
                                }}
                                error={
                                  errors[`${"itinerary"}_${"title"}_${index}`]
                                }
                              />
                            </div>
                            <div className="col-12">
                              <TextEditor
                                config={editorConfigEn}
                                label={"Day Description"}
                                className={"mb-3"}
                                field={"description"}
                                inputSocket={formPlug}
                                required={{
                                  message: "Please enter day description",
                                }}
                                placeholder="Type Here"
                                value={ele?.description}
                                onBlur={(newContent) =>
                                  inputDataChangeHandler(
                                    "description",
                                    newContent,
                                    index,
                                    "itinerary"
                                  )
                                }
                                onChange={(e, key) => {
                                  inputDataChangeHandler(
                                    key,
                                    e,
                                    index,
                                    "itinerary"
                                  );
                                }}
                                error={
                                  errors[
                                  `${"itinerary"}_${"description"}_${index}`
                                  ]
                                }
                              />
                            </div>

                            <div className="col-12 col-md-6">
                              <Select
                                title="Activities"
                                className="css-slc-esc1 mb-3"
                                placeholder={"Select"}
                                options={activityData}
                                labelSetter={(o) => o?.title}
                                valueSetter={(option) => option._id}
                                field={"activity"}
                                inputSocket={formPlug}
                                // required={{ message: "Please select activity" }}
                                Multi2
                                value={ele.activity ? ele.activity : []}
                                onChange={(e, key) => {
                                  inputDataChangeHandler(
                                    key,
                                    e,
                                    index,
                                    "itinerary"
                                  );
                                }}
                                error={
                                  errors[
                                  `${"itinerary"}_${"activity"}_${index}`
                                  ]
                                }
                              />
                            </div>

                            <div className="raw">
                              <div className="col-12 selectedListData">
                                {ele?.activity?.map((option, i) => (
                                  <div key={option} className="mb-1">
                                    <div className="d-inline-block mb-1">
                                      <button
                                        className="selected__item"
                                        type="button"
                                      >
                                        {option?.title}
                                        <img src="/assets/img/icons/close-red.svg" 
                                        onClick={() =>
                                          inputDataRemoveChangeHandler(
                                            "activity",
                                            option?._id,
                                            "itinerary",
                                            index
                                          )
                                        }
                                         alt="" />
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                            <Select
                                  title="Attractions"
                                  className="css-slc-esc1"
                                  placeholder={"Select"}
                                  options={attractionData}
                                  labelSetter={(o) => o?.title}
                                  valueSetter={(option) => option._id}
                                  field={"attraction"}
                                  inputSocket={formPlug}
                                  // required={{ message: "Please select attraction" }}
                                  Multi2
                                  value={ele.attraction ? ele.attraction : []}
                                  onChange={(e, key) => {
                                    inputDataChangeHandler(
                                      key,
                                      e,
                                      index,
                                      "itinerary"
                                    );
                                  }}
                                  error={
                                    errors[
                                    `${"itinerary"}_${"attraction"}_${index}`
                                    ]
                                  }
                                />
                            </div>

                            <div className="raw">
                              <div className="selectedListData">
                                {ele?.attraction.map((option) => (
                                  <div key={option} className="mb-3">
                                    <div className="d-inline-block mb-1">
                                      <button
                                        className="selected__item"
                                        type="button"
                                      >
                                        {option?.title}

                                        <img src="/assets/img/icons/close-red.svg" onClick={() =>
                                          inputDataRemoveChangeHandler(
                                            "attraction",
                                            option?._id,
                                            "itinerary",
                                            index
                                          )
                                        } alt="" />
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div>
                                  <div className="d-flex gap-3">
                                    <FileUpload
                                      style={{
                                        marginRight: 5,
                                        marginTop: 15,
                                      }}
                                      className="css-atc-esc5  mt-3"
                                      label="Map Image"
                                      accept={["jpg", "jpeg", "png"]}
                                      icon={"/assets/img/icons/plus_black.svg"}
                                      closeIcon={
                                        <AiOutlineClose
                                          size={30}
                                          className="remove-icon"
                                          onClick={() =>
                                            removeImage("map_image")
                                          }
                                        />
                                      }
                                      onChange={(
                                        e,
                                        isInvalid,
                                        message,
                                        isClosing
                                      ) => {
                                        setErrors((s) => ({
                                          ...s,
                                          map_image: "",
                                        }));
                                        singleFileUploadHandler(
                                          e,
                                          isInvalid,
                                          message,
                                          isClosing,
                                          "map_image",
                                          inputDataChangeHandler,
                                          index,
                                          "itinerary"
                                        );
                                      }}
                                      error={
                                        errors[
                                        `${"itinerary"}_${"mobile_map_image"}_${index}`
                                        ]
                                      }
                                      width={28}
                                      field={"map_image"}
                                      id="map_image"
                                      maxFileSizeMB={50}
                                      multiple={false}
                                      showPreview
                                      files={ele?.map_image}
                                      required={{
                                        message: "Please upload map image",
                                      }}
                                      inputSocket={formPlug}
                                    />
                                  </div>
                                  <SupportedFormats
                                    formats={["jpg", "jpeg", "png"]}
                                    maxSize={50}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <Input
                                  className="css-inp-esc1 mb-3"
                                  label="Map Alt Text"
                                  placeholder="Type here"
                                  // required={{ message: "Please enter map alt" }}
                                  field={"map_image_alt"}
                                  inputSocket={formPlug}
                                  value={ele?.map_image_alt}
                                  onChange={(e, key) => {
                                    inputDataChangeHandler(
                                      key,
                                      e,
                                      index,
                                      "itinerary"
                                    );
                                  }}
                                  error={
                                    errors[
                                    `${"itinerary"}_${"map_image_alt"}_${index}`
                                    ]
                                  }
                                />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-12 col-md-6">
                                <div>
                                  <div className="d-flex gap-3">
                                    <FileUpload
                                      style={{
                                        marginRight: 5,
                                        marginTop: 15,
                                      }}
                                      className="css-atc-esc5  mt-3"
                                      label="Itinerary image"
                                      accept={["jpg", "jpeg", "png"]}
                                      icon={"/assets/img/icons/plus_black.svg"}
                                      closeIcon={
                                        <AiOutlineClose
                                          size={30}
                                          className="remove-icon"
                                          onClick={() =>
                                            removeImage("mobile_map_image")
                                          }
                                        />
                                      }
                                      onChange={(
                                        e,
                                        isInvalid,
                                        message,
                                        isClosing
                                      ) => {
                                        setErrors((s) => ({
                                          ...s,
                                          mobile_map_image: "",
                                        }));
                                        singleFileUploadHandler(
                                          e,
                                          isInvalid,
                                          message,
                                          isClosing,
                                          "mobile_map_image",
                                          inputDataChangeHandler,
                                          index,
                                          "itinerary"
                                        );
                                      }}
                                      error={
                                        errors[
                                        `${"itinerary"}_${"Itinerary_image"}_${index}`
                                        ]
                                      }
                                      width={28}
                                      field={"mobile_map_image"}
                                      id="mobile_map_image"
                                      maxFileSizeMB={50}
                                      multiple={false}
                                      showPreview
                                      files={ele?.mobile_map_image}
                                      required={{
                                        message:
                                          "Please upload mobile app image",
                                      }}
                                      inputSocket={formPlug}
                                    />
                                  </div>
                                  <SupportedFormats
                                    formats={["jpg", "jpeg", "png"]}
                                    maxSize={50}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <Input
                                  className="css-inp-esc1 mb-3"
                                  label="Itinerary Image Alt Text"
                                  placeholder="Type here"
                                  // required={{
                                  //   message: "Please enter alt text",
                                  // }}
                                  field={"mobile_map_image_alt"}
                                  inputSocket={formPlug}
                                  value={ele?.mobile_map_image_alt}
                                  onChange={(e, key) => {
                                    inputDataChangeHandler(
                                      key,
                                      e,
                                      index,
                                      "itinerary"
                                    );
                                  }}
                                  error={
                                    errors[
                                    `${"itinerary"}_${"mobile_map_image_alt"}_${index}`
                                    ]
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default Itinerary;
