import React, { useEffect, useMemo, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import History from "../../components/History";
import CommentUpdates from "../../components/CommentUpdates";
import PaymentHistory from "../../components/PaymentHistory";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivity,
  getAttraction,
  getBookingDetails,
  getCountry,
  getMilestone,
  getQuestionnaire,
  getStatusList,
  updateBooking,
  updateStatusBooking,
  uploadFile,
} from "../../redux/actionCreator";
import { useNavigate, useParams } from "react-router-dom";
import {
  activity_data,
  attraction_data,
  booking_path,
  country_list,
  details_booking,
  payment_milestone_data,
  questionnaire_data,
  status_list,
} from "../../redux/commonReducer";
import BookingDetails from "./tabs/bookingDetails";
import CustomerEngagement from "./tabs/customerEngagement";
import TravelItinerary from "./tabs/travelItinerary";
import VehicleDetails from "./tabs/vehicleDetails";
import Quotation from "./tabs/quotation";
import Payments from "./tabs/payments";
import Preview from "./tabs/preview";
import PassengerInfoDetails from "./tabs/passengerInfo";
import useForm from "../../utils/Hooks/useForm";
import moment, { invalid } from "moment";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import TextArea from "../../commonComponents/TextArea/TextArea";
import { toast } from "react-toastify";
import { scrollToTop } from "../../utils/helpers";
import SupportedFormats from "../../commonComponents/SupportedFormats/SupportedFormats";

const ViewBooking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const details = useSelector(details_booking);
  const countryData = useSelector(country_list);
  const activityData = useSelector(activity_data);
  const attractionData = useSelector(attraction_data);
  const questionnaireData = useSelector(questionnaire_data);
  const paymentMilestoneData = useSelector(payment_milestone_data);
  const statusData = useSelector(status_list);
  const bookingPath = useSelector(booking_path);

  const [invalidUpload, setInvalidUpload] = useState(false);
  const [trackId, setTrackId] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeHistoryTab, setActiveHistoryTab] = useState(0);
  const [itinerary, setItinerary] = useState([]);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [quotation, setQuotation] = useState([]);
  const [airfareQuotation, setAirfareQuotation] = useState([]);
  const [mileStoneData, setMileStoneData] = useState([]);
  const [tablist, setTablist] = useState([
    { label: "Booking Details", disabled: false },
    { label: "Passenger Info", disabled: false },
    { label: "Customer Engagement", disabled: false },
    { label: "Travel Itinerary", disabled: details?.is_customer_engagement },
    { label: "Car & Flight Details", disabled: details?.is_itinerary },
    { label: "Quotation", disabled: details?.is_vehicle_details },
    { label: "Payments", disabled: false },
    { label: "Preview", disabled: details?.is_payment },
  ]);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({
    traveler_info: [],
    numberOfDays: "",
  });

  const keys = [
    "",
    "passenger_info",
    "customer",
    "itinerary",
    "vehicle_details",
    "quotation",
    "payment",
    "preview",
  ];
  const historyTabs = ["Comments Updates"];

  const documentList = [
    { label: "Hotel", value: "hotel" },
    // { label: "Cab", value: "cab" },
    { label: "Other", value: "other" },
  ];

  const paymentMethodList = [
    { label: "Online Payment", value: "online" },
    { label: "Direct Bank", value: "direct" },
  ];

  const paymentTypeList = [
    { label: "One Time", value: "onetime" },
    { label: "Milestone", value: "multiple" },
  ];

  const paymentStatusList = [
    { label: "Payment Initiated", value: "init" },
    { label: "Payment Pending", value: "pending" },
    { label: "Payment Captured", value: "captured" },
    { label: "Payment Failed", value: "failed" },
    { label: "Payment Refunded", value: "refunded" },
  ];

  const travelerObj = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone: "",
    country_code: "",
    nationality: "",
    dob: "",
    passport_no: "",
    passport_expiry: "",
    passport: "",
    can_login: false,
    attachments: [],
  };

  useEffect(() => {
    let formData = new FormData();
    formData.append("is_pagination", 0);
    dispatch(getActivity(formData));
    dispatch(getAttraction(formData));
    dispatch(getQuestionnaire(formData));
    dispatch(getCountry());
    let statusKey = {
      key: "booking_management",
    };
    dispatch(getStatusList(statusKey));
  }, []);

  useEffect(() => {
    if (
      keys[activeTab] == "payment" &&
      inputs?.payment_mode?.value === "multiple"
    ) {
      let raw = {
        is_pagination: 0,
      };
      dispatch(getMilestone(raw));
    }
  }, [keys[activeTab], inputs?.payment_mode]);

  useEffect(() => {
    const statusKey = {
      key: keys[activeTab],
      id: id,
    };
    dispatch(getBookingDetails(statusKey));
  }, [activeTab]);

  useEffect(() => {
    if (details && id) {
      let result = {
        start_travel_date: details?.start_travel_date,
        end_travel_date: details?.end_travel_date,
        payment_method:
          paymentMethodList?.filter(
            (ele) => ele?.value === details?.payment_method
          )?.[0] ?? "",
        pick_up_date: details?.pick_up_date
          ? new Date(details?.pick_up_date)
          : "",
        pick_up_location: details?.pick_up_location,
        pick_up_time: details?.pick_up_time
          ? timeToDateConverter(details?.pick_up_time)
          : "",
        drop_off_date: details?.drop_off_date
          ? new Date(details?.drop_off_date)
          : "",
        drop_off_time: details?.drop_off_time
          ? timeToDateConverter(details?.drop_off_time)
          : "",
        flight_start_date: details?.flight_start_date
          ? new Date(details?.flight_start_date)
          : "",
        flight_start_time: details?.flight_start_time
          ? timeToDateConverter(details?.flight_start_time)
          : "",
        start_departure_airport: details?.start_departure_airport,
        start_arrival_airport: details?.start_arrival_airport,
        start_travel_time: details?.start_travel_time,
        start_flight_stops: details?.start_flight_stops,
        start_flight_description: details?.start_flight_description,
        travel_duration: details?.travel_duration,
        flight_return_date: details?.flight_return_date
          ? new Date(details?.flight_return_date)
          : "",
        flight_return_time: details?.flight_return_time
          ? timeToDateConverter(details?.flight_return_time)
          : "",
        return_departure_airport: details?.return_departure_airport,
        return_arrival_airport: details?.return_arrival_airport,
        return_travel_time: details?.return_travel_time,
        return_flight_stops: details?.return_flight_stops,
        return_flight_description: details?.return_flight_description,
        payment_mode:
          paymentTypeList?.filter(
            (ele) => ele?.value === details?.payment_mode
          )?.[0] ?? "",

        arrival_start_date: details?.arrival_start_date
          ? new Date(details?.arrival_start_date)
          : "",
        arrival_start_time: details?.arrival_start_time
          ? timeToDateConverter(details?.arrival_start_time)
          : "",
        return_arrival_start_date: details?.return_arrival_start_date
          ? new Date(details?.return_arrival_start_date)
          : "",
        return_arrival_start_time: details?.return_arrival_start_time
          ? timeToDateConverter(details?.return_arrival_start_time)
          : "",
        additional_info: details?.additional_info,
      };
      if (details?.pickup_attachments?.length) {
        let arr = details?.pickup_attachments?.map((ele, i) => {
          let obj = {
            type: ele?.split(".").pop(),
            id: ele,
            url: `${IMAGE_BASE_URL}${bookingPath}/${ele}`,
            name: `${ele}`,
            response: `${ele}`,
          };
          return obj;
        });
        result = {
          ...result,
          pickup_attachments: [...arr],
        };
      }
      if (details?.dropoff_attachments?.length) {
        let arr = details?.dropoff_attachments?.map((ele, i) => {
          let obj = {
            type: ele?.split(".").pop(),
            id: ele,
            url: `${IMAGE_BASE_URL}${bookingPath}/${ele}`,
            name: `${ele}`,
            response: `${ele}`,
          };
          return obj;
        });
        result = {
          ...result,
          dropoff_attachments: [...arr],
        };
      }
      if (details?.flight_starttrip_attachments?.length) {
        let arr = details?.flight_starttrip_attachments?.map((ele, i) => {
          let obj = {
            type: ele?.split(".").pop(),
            id: ele,
            url: `${IMAGE_BASE_URL}${bookingPath}/${ele}`,
            name: `${ele}`,
            response: `${ele}`,
          };
          return obj;
        });
        result = {
          ...result,
          flight_starttrip_attachments: [...arr],
        };
      }
      if (details?.flight_returntrip_attachments?.length) {
        let arr = details?.flight_returntrip_attachments?.map((ele, i) => {
          let obj = {
            type: ele?.split(".").pop(),
            id: ele,
            url: `${IMAGE_BASE_URL}${bookingPath}/${ele}`,
            name: `${ele}`,
            response: `${ele}`,
          };
          return obj;
        });
        result = {
          ...result,
          flight_returntrip_attachments: [...arr],
        };
      }
      if (Number(details?.no_of_travellers)) {
        let temp = [];
        let adult = 0;
        let child = 0;
        let total_adult = Number(details?.no_of_adults);
        let total_child =
          Number(details?.no_of_travellers) - Number(details?.no_of_adults);
        for (let i = 0; i < Number(details?.no_of_travellers); i++) {
          if (details?.traveller_info?.[i]) {
            let obj = details?.traveller_info[i];
            if (obj.is_adult == "1") adult++;
            if (obj.is_child == "1") child++;
            temp.push({
              ...travelerObj,
              ...obj,
              nationality:
                countryData?.filter(
                  (ele) => ele?.name === obj?.nationality
                )?.[0] ?? "",
              country_code:
                countryData?.filter(
                  (ele) => ele?.dial_code === obj?.country_code
                )?.[0] ?? "",
              child: child,
              adult: adult,
              dob: obj?.dob ? moment(obj?.dob, "DD-MM-YYYY").toDate() : "",
              passport_expiry: obj?.passport_expiry
                ? moment(obj?.passport_expiry, "DD-MM-YYYY").toDate()
                : "",
              passport: obj?.passport
                ? [
                    {
                      type: obj?.passport?.split(".").pop(),
                      url: `${IMAGE_BASE_URL}${"booking"}/${obj?.passport}`,
                      name: `${obj?.passport}`,
                      response: `${obj?.passport}`,
                    },
                  ]
                : "",
              attachments: obj?.attachments?.map((ele, i) => {
                let temp = {
                  type: ele?.split(".").pop(),
                  url: `${IMAGE_BASE_URL}booking/${ele}`,
                  name: `${ele}`,
                  response: `${ele}`,
                };
                return temp;
              }),
            });
          } else {
            let is_adult;
            if (total_adult - adult > 0) {
              is_adult = true;

              adult++;
            } else if (total_adult - adult == 0 && total_child - child > 0) {
              is_adult = false;
              child++;
            }
            temp.push({
              ...travelerObj,
              is_main: "0",
              is_adult: is_adult ? "1" : "0",
              is_child: !is_adult ? "1" : "0",
              child: child,
              adult: adult,
            });
          }
        }
        let adultTravelers = temp?.filter((ele) => ele?.is_adult == "1");
        let childTravelers = temp?.filter((ele) => ele?.is_child == "1");
        result = {
          ...result,
          traveller_info: [...adultTravelers, ...childTravelers],
        };
      }
      setInputs({ ...result });
      setTablist([
        { label: "Booking Details", disabled: false },
        { label: "Passenger Info", disabled: false },
        { label: "Customer Engagement", disabled: false },
        {
          label: "Travel Itinerary",
          disabled: !details?.is_customer_engagement,
        },
        { label: "Car & Flight Details", disabled: !details?.is_itinerary },
        { label: "Quotation", disabled: !details?.is_vehicle_details },
        { label: "Payments", disabled: false },
        { label: "Preview", disabled: !details?.is_payment },
      ]);
    }
  }, [details, countryData]);

  useEffect(() => {
    if (details?.itinerary?.length) {
      let arr = details?.itinerary?.map((ele, i) => {
        let obj = {
          title: ele?.title,
          description: ele?.description,
          activity: ele?.activity,
          attraction: ele?.attraction,
          map_image: ele?.map_image
            ? [
                {
                  type: ele?.map_image?.split(".").pop(),
                  url: `${IMAGE_BASE_URL}${"package"}/${ele?.map_image}`,
                  name: `${ele?.map_image}`,
                  response: `${ele?.map_image}`,
                },
              ]
            : "",
          map_image_alt: ele?.map_image_alt,
          mobile_map_image: ele?.mobile_map_image
            ? [
                {
                  type: ele?.mobile_map_image?.split(".").pop(),
                  url: `${IMAGE_BASE_URL}${"package"}/${ele?.mobile_map_image}`,
                  name: `${ele?.mobile_map_image}`,
                  response: `${ele?.mobile_map_image}`,
                },
              ]
            : "",
          mobile_map_image_alt: ele?.mobile_map_image_alt,
          attachments: ele?.attachments?.map((el, i) => {
            let obj = {
              doc_title: el?.title,
              doc_description: el?.description,
              type: documentList?.filter((e) => el?.type == e?.value)?.[0],
              item: el?.item
                ? [
                    {
                      type: el?.item?.split(".").pop(),
                      url: `${IMAGE_BASE_URL}${"booking"}/${el?.item}`,
                      name: `${el?.item}`,
                      response: `${el?.item}`,
                    },
                  ]
                : "",
            };
            return obj;
          }),
          type: "",
          item: "",
          doc_title: "",
          doc_description: "",
          subIndex: "",
        };
        return obj;
      });
      setItinerary([...arr]);
    }
  }, [details?.itinerary]);

  useEffect(() => {
    if (questionnaireData?.length && details) {
      let arr = [];
      questionnaireData?.forEach((ele, i) => {
        let obj = details?.questionnaire_section?.find(
          (e) => e?.question_id == ele?._id
        );
        if (obj) {
          arr.push({
            ...ele,
            answer: obj?.answer,
            comment: obj?.comment,
          });
        } else {
          arr.push({
            ...ele,
            answer: false,
            comment: "",
          });
        }
      });
      setQuestionnaire([...arr]);
    }
  }, [details, questionnaireData]);

  useEffect(() => {
    if (details?.quotation?.length) {
      let updatedQuotation = details.quotation.map((item) => ({ ...item }));
      setQuotation(updatedQuotation);
    }
    if (details?.airfare_quotation?.length) {
      let updatedAirfare_quotation = details.airfare_quotation.map((item) => ({
        ...item,
      }));
      setAirfareQuotation(updatedAirfare_quotation);
    }
  }, [details]);

  useEffect(() => {
    if (inputs?.start_travel_date && inputs?.end_travel_date) {
      const numberOfDays = countDays(
        inputs?.start_travel_date,
        inputs?.end_travel_date
      );
      if (numberOfDays !== itinerary?.length) {
        itineraryCalculator(numberOfDays);
      }
    }
  }, [inputs?.start_travel_date, inputs?.end_travel_date, itinerary]);

  useEffect(() => {
    if (details?.milestone?.length != 0) {
      let temp = [];
      details?.milestone?.forEach((ele, index) => {
        temp.push({
          ...ele,
          payment_status: paymentStatusList?.filter(
            (el) => el?.value == ele?.payment_status
          )?.[0]
            ? paymentStatusList?.filter(
                (el) => el?.value == ele?.payment_status
              )?.[0]
            : paymentStatusList?.filter((el) => el?.value == "init")?.[0],
          upload_receipt: ele?.upload_receipt
            ? ele?.upload_receipt?.map((ele, i) => {
                let obj = {
                  type: ele?.split(".").pop(),
                  id: ele,
                  url: `${IMAGE_BASE_URL}${bookingPath}/${ele}`,
                  name: `${ele}`,
                  response: `${ele}`,
                };
                return obj;
              })
            : "",
        });
      });
      setMileStoneData([...temp]);
    } else if (paymentMilestoneData?.length) {
      let temp = [];
      if (inputs.payment_mode?.value == "onetime") {
        temp.push({
          milestone_name: "Onetime Payment",
          percentage: "100",
          value: details?.amount_to_be_paid,
          date: "",
          upload_receipt: "",
          payment_status:
            paymentStatusList?.filter((el) => el?.value == "init")?.[0] ?? "",
        });
      } else {
        paymentMilestoneData?.forEach((ele, index) => {
          let obj = details?.milestone?.find((el) => ele?._id === el?._id);
          if (obj) {
            temp.push({
              ...obj,
              value: (
                Number(details?.amount_to_be_paid) *
                (Number(obj?.milestone_percentage) / 100)
              )?.toFixed(2),
              date: "",
              upload_receipt: "",
              payment_status:
                paymentStatusList?.filter((el) => el?.value == "init")?.[0] ??
                "",
              percentage: obj?.milestone_percentage,
            });
          } else {
            temp.push({
              ...ele,
              date: "",
              upload_receipt: "",
              value: (
                Number(details?.amount_to_be_paid) *
                (Number(ele?.milestone_percentage) / 100)
              )?.toFixed(2),
              payment_status:
                paymentStatusList?.filter((el) => el?.value == "init")?.[0] ??
                "",
              percentage: ele?.milestone_percentage,
            });
          }
        });
      }
      setMileStoneData([...temp]);
    } else {
      let temp = [];
      if (inputs.payment_mode?.value == "onetime") {
        temp.push({
          milestone_name: "Onetime Payment",
          percentage: "100",
          value: details?.amount_to_be_paid,
          date: "",
          upload_receipt: "",
          payment_status:
            paymentStatusList?.filter((el) => el?.value == "init")?.[0] ?? "",
        });
      }
      setMileStoneData([...temp]);
    }
  }, [paymentMilestoneData, details?.milestone, inputs.payment_mode]);

  let gross_total = useMemo(() => {
    let total_cost =
      Number(
        quotation.reduce((sum, total) => sum + Number(total.total_cost), 0)
      ) +
      Number(
        airfareQuotation.reduce(
          (sum, total) => sum + Number(total.total_cost),
          0
        )
      );
    return total_cost;
  }, [details, quotation, airfareQuotation]);

  let amount_to_be_paid = useMemo(() => {
    let total = Number(gross_total) ? Number(gross_total) : 0;
    let total_discount = Number(details?.total_discount)
      ? Number(details?.total_discount)
      : 0;
    let token_payment = Number(details?.token_payment)
      ? Number(details?.token_payment)
      : 0;
    let custom_variable_cost =
      Number(details?.no_of_travellers) * Number(details?.custom_variable_cost);

    let amount_paid = Number(details?.amount_paid);
    console.log(amount_paid);
    let amount_cost =
      custom_variable_cost + total - total_discount - amount_paid > 0
        ? Number(
            custom_variable_cost + total - total_discount - amount_paid
          )?.toFixed(2)
        : 0;

    return amount_cost;
  }, [details, gross_total]);

  let deduction = useMemo(() => {
    let deduction_cost =
      Number(details?.total_estimate) -
      (Number(gross_total) +
        Number(details?.no_of_travellers) *
          Number(details?.custom_variable_cost));
    return isNaN(deduction_cost) ? 0 : deduction_cost > 0 ? deduction_cost : 0;
  }, [details, gross_total]);

  const timeToDateConverter = (timeString) => {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":");
    const isPM = period.toLowerCase() === "pm";
    let dateWithTime = new Date();
    dateWithTime.setHours(
      (parseInt(hours) % 12) + (isPM ? 12 : 0),
      parseInt(minutes),
      0,
      0
    );
    return dateWithTime;
  };

  const countDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffInMs = end - start;
    const days = diffInMs / (1000 * 60 * 60 * 24);
    return days + 1;
  };

  const itineraryCalculator = (days) => {
    let diff = 0;
    if (days > itinerary?.length) {
      diff = days - itinerary?.length;
      let temp = [];
      for (let i = 0; i < Number(diff); i++) {
        temp.push({
          title: "",
          description: "",
          activity: [],
          attraction: [],
          map_image: "",
          map_image_alt: "",
          mobile_map_image: "",
          mobile_map_image_alt: "",
          attachments: [],
          type: "",
          item: "",
          doc_title: "",
          doc_description: "",
          subIndex: "",
        });
        setItinerary([...itinerary, ...temp]);
      }
    } else {
      diff = itinerary?.length - days;
      setItinerary(itinerary.slice(0, days));
    }
  };

  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);
  }

  const inputDataChangeHandler = (value, key, index, field) => {
    let dataList = { ...inputs };
    dataList[field][index][key] = value;
    setInputs(dataList);
    setErrors({
      ...errors,
      [`${field}_${key}_${index}`]: "",
    });
  };

  const itineraryDataChangeHandler = (value, key, index, field) => {
    let dataList = [...itinerary];
    dataList[index][key] = value;
    setItinerary(dataList);
    setErrors({
      ...errors,
      [`${field}_${key}_${index}`]: "",
    });
  };

  const questionnaireDataChangeHandler = (key, value, index, field) => {
    let dataList = [...questionnaire];
    dataList[index][key] = value;
    setQuestionnaire(dataList);
    setErrors({
      ...errors,
      [`${field}_${key}_${index}`]: "",
    });
  };

  const fileUploadHandler = (
    e,
    isInvalid,
    message,
    isClosing,
    field,
    setFunction,
    type,
    index,
    mainField
  ) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [`${field}_${index}`]: message,
      });
    } else {
      setErrors({
        ...errors,
        [`${field}_${index}`]: message,
      });
      setInvalidUpload(false);
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        formData.append(`path`, "booking");
        dispatch(
          uploadFile(formData, (res) => {
            let oldFiles = [];
            if (type !== "single") {
              oldFiles = e?.filter((el) => el?.metaFile == undefined);
            }
            let arr = e
              ?.filter((el) => el?.metaFile != undefined)
              ?.map((ele, i) => {
                return {
                  ...ele,
                  type: ele?.type,
                  url: ele?.url,
                  name: ele?.name,
                  response: res?.data[i],
                  id: res?.data[i] + i + 1,
                  alt: "",
                };
              });
            let tempValue = [...oldFiles, ...arr];
            setFunction(tempValue, field, index, mainField);
          })
        );
      } else {
        setErrors({
          ...errors,
          [`${field}_${index}`]: message,
        });
        setFunction(e, field, index, mainField);
      }
    }
  };

  const inputDataRemoveChangeHandler = (key, id, field, mainIndex) => {
    let dataList = { ...inputs };
    let temp = [...dataList?.[field]?.[mainIndex][key]];
    setInputs(dataList);
    if (temp?.length) {
      dataList[field][mainIndex][key] = temp?.filter((ele) => ele?._id !== id);
      setInputs(dataList);
    }
  };

  const removeImage = (type) => {
    setInputs((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const onAddAdditionalItineraryData = (item, i) => {
    let tempErr = {};
    if (!item?.type) {
      tempErr[`${"itinerary"}_${"type"}_${i}`] = "Please select type";
    }
    if (!item?.doc_title) {
      tempErr[`${"itinerary"}_${"doc_title"}_${i}`] =
        "Please enter document title";
    }
    if (!item?.item) {
      tempErr[`${"itinerary"}_${"item"}_${i}`] = "Please upload document";
    }
    if (!item?.doc_description) {
      tempErr[`${"itinerary"}_${"doc_description"}_${i}`] =
        "Please enter document description";
    }
    if (Object.values(tempErr).length != 0) {
      setErrors({
        ...errors,
        ...tempErr,
      });
    } else {
      setErrors({ ...errors });

      let arr = [...itinerary];
      let addObj = {
        type: item?.type,
        item: item?.item,
        doc_title: item?.doc_title,
        doc_description: item?.doc_description,
      };
      let addArr = [];
      if (item?.editAttachment) {
        let attachments = [...item?.attachments];
        attachments[item?.subIndex] = addObj;
        addArr = [...attachments];
      } else {
        addArr = [...item?.attachments, addObj];
      }
      item = {
        ...item,
        attachments: [...addArr],
        type: "",
        item: "",
        doc_title: "",
        doc_description: "",
        subIndex: "",
      };
      arr[i] = item;
      setItinerary([...arr]);
    }
  };

  const onClearAdditionalItineraryData = (ele, i) => {
    let arr = [...itinerary];
    ele = {
      ...ele,
      type: "",
      item: "",
      doc_title: "",
      doc_description: "",
      subIndex: "",
    };
    arr[i] = ele;
    setItinerary([...arr]);
  };

  const onEditAttachment = (i, ele, index) => {
    let obj = { ...itinerary[i] };
    let arr = [...itinerary];
    obj = {
      ...obj,
      type: ele?.type,
      item: ele?.item,
      doc_title: ele?.doc_title,
      doc_description: ele?.doc_description,
      editAttachment: true,
      subIndex: index,
    };
    arr[i] = obj;
    setItinerary([...arr]);
    scrollToSection("essential_documents");
  };

  const onDeleteAttachment = (i, index) => {
    let obj = { ...itinerary[i] };
    let arr = [...itinerary];
    obj = {
      ...obj,
      type: "",
      item: "",
      doc_title: "",
      doc_description: "",
      editAttachment: false,
      attachments: obj.attachments?.filter((item, ind) => ind != index),
    };
    arr[i] = obj;
    setItinerary([...arr]);
  };

  const getMinTime = (minFullTime) => {
    if (
      moment(minFullTime, "h:mm A").format("HH:mm") <
      moment("12:00 AM", "h:mm A").format("HH:mm")
    ) {
      return new Date(new Date(minFullTime).setHours(7, 0, 0));
    } else {
      return minFullTime;
    }
  };

  const tabChangeHandler = (tab) => {
    setActiveTab(tab);
    // Please don't remove these codes
    // if (activeTab === 0 || activeTab === 7 || tab === activeTab || tab === 0) {
    //   setActiveTab(tab);
    // } else {
    //   if (activeTab > tab) {
    //     setActiveTab(tab);
    //   } else {
    //     onSubmitHandler(tab, () => {});
    //   }
    // }
  };

  const getStatusColor = (status) => {
    if (status === "New Lead") return "#379777";
    if (status === "Lost Lead") return "#FF4545";
    if (status === "Discussion In Progress") return "#007bff";
    if (status === "Lead Converted") return "#F4CE14";
    return "#6c757d";
  };

  const onCancelHandler = () => {
    setErrors({});
    navigate("/manage-bookings");
  };

  const onSubmitHandler = async (tab) => {
    if (!invalidUpload) {
      finalSubmitHandler(tab);
    }
  };

  const finalSubmitHandler = (tab) => {
    let isInvalid = false;
    let errorObject = { ...errors };

    if (
      inputs?.attachments &&
      inputs?.attachments?.length &&
      !inputs?.comment
    ) {
      errorObject.comment = "Please enter comment";
      isInvalid = true;
    }

    let raw = {
      id: id,
      track_id: trackId,
      key: keys[activeTab],
      attachments: inputs?.attachments?.map((ele, i) => ele?.response),
      comment: inputs?.comment,
      status: details?.status?._id,
      path: "booking",
    };
    if (keys[activeTab] == "passenger_info") {
      let requiredFields = [
        { key: "first_name", errorMessage: "Please enter First Name" },
        { key: "last_name", errorMessage: "Please enter Last Name" },
        { key: "email", errorMessage: "Please enter Email" },
        { key: "phone", errorMessage: "Please enter Phone" },
        { key: "country_code", errorMessage: "Please enter Country Code" },
        { key: "nationality", errorMessage: "Please enter Nationality" },
        { key: "dob", errorMessage: "Please enter DOB" },
        { key: "passport_no", errorMessage: "Please enter Passport Number" },
        {
          key: "passport_expiry",
          errorMessage: "Please enter Passport Expiry",
        },
        { key: "passport", errorMessage: "Please upload Passport" },
      ];
      let validationObject = { ...errorObject };
      inputs?.traveller_info?.forEach((element, index) => {
        requiredFields?.forEach((el) => {
          if (!element[el.key]) {
            isInvalid = true;
            validationObject[`${"traveller_info"}_${el.key}_${index}`] =
              el.errorMessage;
          } else if (el.key == "email") {
            let regex =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            if (!regex.test(element[el.key].trim())) {
              isInvalid = true;
              validationObject[`${"traveller_info"}_${el.key}_${index}`] =
                "Please enter valid email";
            }
          }
        });
      });
      setErrors(validationObject);

      raw = {
        ...raw,
        traveller_info: inputs?.traveller_info?.map((item, i) => {
          return {
            [`first_name`]: item?.first_name?.trim(),
            [`middle_name`]: item?.middle_name?.trim(),
            [`last_name`]: item?.last_name?.trim(),
            [`email`]: item?.email,
            [`phone`]: item?.phone,
            [`country_code`]: item.country_code?.dial_code,
            [`nationality`]: item.nationality?.name,
            [`dob`]: item?.dob ? moment(item?.dob).format("DD-MM-YYYY") : "",
            [`passport_no`]: item?.passport_no,
            [`passport_expiry`]: item?.passport_expiry
              ? moment(item?.passport_expiry).format("DD-MM-YYYY")
              : "",
            [`passport`]: item?.passport?.[0]?.response ?? "",
            [`is_main`]: item?.is_main,
            [`is_adult`]: item?.is_adult,
            [`is_child`]: item?.is_child,
            [`can_login`]: item?.is_main == "1" ? true : item?.can_login,
            [`attachments`]: item?.attachments?.map((ele, i) => ele?.response),
          };
        }),
        is_passenger_info: true,
      };
    }
    if (keys[activeTab] == "customer") {
      if (!inputs?.payment_method) {
        isInvalid = true;
        errorObject.payment_method = "Please select a payment method";
      }

      if (!inputs?.payment_mode) {
        isInvalid = true;
        errorObject.payment_mode = "Please select a payment Mode";
      }

      if (!inputs?.additional_info) {
        isInvalid = true;
        errorObject.additional_info = "Please enter additional information";
      }

      setErrors(errorObject);
      // if (!inputs?.payment_method) {
      //   setErrors({
      //     ...errorObject,
      //     payment_method: "Please select a payment method",
      //   });
      //   isInvalid = true;
      // }
      // if (!inputs?.payment_mode) {
      //   setErrors({
      //     ...errorObject,
      //     payment_mode: "Please select a payment Mode",
      //   });
      //   isInvalid = true;
      // }

      raw = {
        ...raw,
        questionnaire_section: questionnaire?.map((item, i) => {
          return {
            [`question_id`]: item?._id,
            [`answer`]: item?.answer,
            [`comment`]: item?.comment,
          };
        }),
        payment_method: inputs?.payment_method?.value ?? "",
        payment_mode: inputs.payment_mode?.value,
        additional_info: inputs?.additional_info ?? "",
        is_customer_engagement: true,
      };
    }
    if (keys[activeTab] == "itinerary") {
      if (!inputs?.start_travel_date) {
        errorObject.start_travel_date = "Please select start travel date";
      }
      if (!inputs?.end_travel_date) {
        errorObject.end_travel_date = "Please select end travel date";
      }

      let requiredFields = [
        { key: "title", errorMessage: "Please enter Title" },
        { key: "description", errorMessage: "Please enter Description" },
        { key: "map_image", errorMessage: "Please upload Map Image" },
        { key: "map_image_alt", errorMessage: "Please enter Map alt text" },
        {
          key: "mobile_map_image",
          errorMessage: "Please upload Mobile App Image",
        },
        {
          key: "mobile_map_image_alt",
          errorMessage: "Please enter Mobile App Image Alt Text",
        },
      ];

      let validationObject = { ...errorObject };
      itinerary?.forEach((element, index) => {
        requiredFields?.forEach((el) => {
          if (index + 1 > Number(details?.duration) && el.key !== "title") {
            // return;
            isInvalid = true;
            validationObject[`${"itinerary"}_${"title"}_${index}`] =
              "Please enter Title";
          } else if (!element[el.key]) {
            isInvalid = true;
            validationObject[`${"itinerary"}_${el.key}_${index}`] =
              el.errorMessage;
          }
        });
      });
      setErrors(validationObject);

      raw = {
        ...raw,
        itinerary: itinerary?.map((item, i) => {
          return {
            [`title`]: item?.title?.trim(),
            [`description`]: item?.description,
            [`activity`]: item?.activity?.map((item, i) => item?._id),
            [`attraction`]: item?.attraction?.map((item, i) => item?._id),
            [`map_image`]: item?.map_image?.[0]?.response ?? "",
            [`map_image_alt`]: item?.map_image_alt?.trim(),
            [`mobile_map_image`]: item?.mobile_map_image?.[0]?.response ?? "",
            [`mobile_map_image_alt`]: item?.mobile_map_image_alt?.trim(),
            [`attachments`]: item?.attachments?.map((item, i) => {
              return {
                [`type`]: item?.type?.value,
                [`title`]: item?.doc_title?.trim(),
                [`description`]: item?.doc_description,
                [`item`]: item?.item?.[0]?.response ?? "",
              };
            }),
          };
        }),
        start_travel_date: inputs?.start_travel_date
          ? moment(inputs?.start_travel_date).format("YYYY-MM-DD")
          : "",
        end_travel_date: inputs?.end_travel_date
          ? moment(inputs?.end_travel_date).format("YYYY-MM-DD")
          : "",
        travel_duration: itinerary?.length,
        is_itinerary: true,
      };
    }
    if (keys[activeTab] == "vehicle_details") {
      if (!inputs?.pick_up_date) {
        errorObject.pick_up_date = "Please select Pickup date";
      }
      if (!inputs?.pick_up_time) {
        errorObject.pick_up_time = "Please select Pickup time";
      }
      if (!inputs?.pick_up_location) {
        errorObject.pick_up_location = "Please select Pickup location";
      }

      if (!inputs?.drop_off_date) {
        errorObject.drop_off_date = "Please select Drop off date";
      }
      if (!inputs?.drop_off_time) {
        errorObject.drop_off_time = "Please select Drop off time";
      }
      if (!inputs?.drop_off_location) {
        errorObject.drop_off_location = "Please select Drop off location";
      }

      if (!inputs?.flight_start_date) {
        errorObject.flight_start_date = "Please select Flight Departure Date";
      }
      if (!inputs?.flight_start_time) {
        errorObject.flight_start_time = "Please select Flight Departure Time";
      }
      if (!inputs?.arrival_start_date) {
        errorObject.arrival_start_date = "Please select Flight arrival Date";
      }
      if (!inputs?.arrival_start_time) {
        errorObject.arrival_start_time = "Please select Flight arrival Time";
      }
      if (!inputs?.start_departure_airport) {
        errorObject.start_departure_airport = "Please enter Departure Airport";
      }
      if (!inputs?.start_arrival_airport) {
        errorObject.start_arrival_airport = "Please enter Arrival Airport";
      }
      if (!inputs?.start_travel_time) {
        errorObject.start_travel_time = "Please select Travel Time";
      }
      if (!inputs?.start_flight_stops) {
        errorObject.start_flight_stops = "Please enter Flight Stops";
      }
      if (!inputs?.start_flight_description) {
        errorObject.start_flight_description =
          "Please enter Flight Description";
      }

      if (!inputs?.flight_return_date) {
        errorObject.flight_return_date =
          "Please select Flight Return Departure Date";
      }
      if (!inputs?.flight_return_time) {
        errorObject.flight_return_time =
          "Please select Flight Return Departure Time";
      }

      if (!inputs?.return_arrival_start_date) {
        errorObject.return_arrival_start_date =
          "Please select Flight Return Arrival Date";
      }
      if (!inputs?.return_arrival_start_time) {
        errorObject.return_arrival_start_time =
          "Please select Flight Return Arrival Time";
      }
      if (!inputs?.return_departure_airport) {
        errorObject.return_departure_airport = "Please enter Departure Airport";
      }
      if (!inputs?.return_arrival_airport) {
        errorObject.return_arrival_airport = "Please select Arrival Airport";
      }
      if (!inputs?.return_travel_time) {
        errorObject.return_travel_time = "Please select Return Travel Time";
      }
      if (!inputs?.return_flight_stops) {
        errorObject.return_flight_stops = "Please select Return Flight Stops";
      }

      if (!inputs?.return_flight_description) {
        errorObject.return_flight_description =
          "Please select Return Flight Description";
      }
      setErrors(errorObject);
      if (Object.values(errorObject).some((value) => value)) {
        isInvalid = true;
        return true;
      }
      raw = {
        ...raw,
        pick_up_date: inputs?.pick_up_date
          ? moment(inputs?.pick_up_date).format("YYYY-MM-DD")
          : "",
        pick_up_time: inputs?.pick_up_time
          ? moment(inputs?.pick_up_time).format("LT")
          : "",
        pick_up_location: inputs?.pick_up_location ?? "",
        drop_off_date: inputs?.drop_off_date
          ? moment(inputs?.drop_off_date).format("YYYY-MM-DD")
          : "",
        drop_off_time: inputs?.drop_off_time
          ? moment(inputs?.drop_off_time).format("LT")
          : "",
        drop_off_location: inputs?.drop_off_location ?? "",
        flight_start_date: inputs?.flight_start_date
          ? moment(inputs?.flight_start_date).format("YYYY-MM-DD")
          : "",
        flight_start_time: inputs?.flight_start_time
          ? moment(inputs?.flight_start_time).format("LT")
          : "",
        start_departure_airport: inputs?.start_departure_airport ?? "",
        start_arrival_airport: inputs?.start_arrival_airport ?? "",
        start_travel_time: inputs?.start_travel_time ?? "",
        start_flight_stops: inputs?.start_flight_stops ?? "",
        start_flight_description: inputs?.start_flight_description ?? "",
        flight_return_date: inputs?.flight_return_date
          ? moment(inputs?.flight_return_date).format("YYYY-MM-DD")
          : "",
        flight_return_time: inputs?.flight_return_time
          ? moment(inputs?.flight_return_time).format("LT")
          : "",
        return_departure_airport: inputs?.return_departure_airport ?? "",
        return_arrival_airport: inputs?.return_arrival_airport ?? "",
        return_travel_time: inputs?.return_travel_time ?? "",
        return_flight_stops: inputs?.return_flight_stops ?? "",
        return_flight_description: inputs?.return_flight_description ?? "",
        is_vehicle_details: true,
        pickup_attachments: inputs.pickup_attachments?.length
          ? inputs.pickup_attachments?.map((ele) => ele.response)
          : [],
        dropoff_attachments: inputs.dropoff_attachments?.length
          ? inputs.dropoff_attachments?.map((ele) => ele.response)
          : [],
        flight_starttrip_attachments: inputs.flight_starttrip_attachments
          ?.length
          ? inputs.flight_starttrip_attachments?.map((ele) => ele.response)
          : [],
        flight_returntrip_attachments: inputs.flight_returntrip_attachments
          ?.length
          ? inputs.flight_returntrip_attachments?.map((ele) => ele.response)
          : [],
        arrival_start_date: inputs?.arrival_start_date
          ? moment(inputs?.arrival_start_date).format("YYYY-MM-DD")
          : "",
        arrival_start_time: inputs?.arrival_start_time
          ? moment(inputs?.arrival_start_time).format("LT")
          : "",
        return_arrival_start_date: inputs?.return_arrival_start_date
          ? moment(inputs?.return_arrival_start_date).format("YYYY-MM-DD")
          : "",
        return_arrival_start_time: inputs?.return_arrival_start_time
          ? moment(inputs?.return_arrival_start_time).format("LT")
          : "",
      };
    }
    if (keys[activeTab] == "quotation") {
      let invalidQuotation = false;
      if (
        quotation.reduce(function (acc, obj) {
          return acc + obj.total_cost;
        }, 0) <= 0
      ) {
        toast.error("Please enter atleast one package cost", {
          position: "bottom-center",
          autoClose: 3000,
        });
        invalidQuotation = true;
      }
      if (details?.flight_booking) {
        if (
          airfareQuotation.reduce(function (acc, obj) {
            return acc + obj.total_cost;
          }, 0) <= 0
        ) {
          toast.error("Please enter atleast one Airfare cost", {
            position: "bottom-center",
            autoClose: 3000,
          });
          invalidQuotation = true;
        }
      }

      setErrors(errorObject);

      if (invalidQuotation) {
        return;
      }

      raw = {
        ...raw,
        quotation: quotation ?? "",
        airfareQuotation: airfareQuotation ?? "",
        gross_total: gross_total ?? "",
        amount_to_be_paid: amount_to_be_paid ?? "",
        deduction: deduction ?? "",
        is_quotation: true,
      };
    }
    if (keys[activeTab] == "payment" && details?.is_quotation) {
      // if (!inputs?.payment_mode) {
      //   errorObject.payment_mode = "Please select Payment Mode";
      // }
      if (mileStoneData?.length === 0) {
        errorObject.mileStoneData = "Please select Payment Mode";
      }

      let requiredFields = [
        { key: "date", errorMessage: "Please enter Date" },
        { key: "payment_status", errorMessage: "Please enter Payment Status" },
      ];

      let validationObject = { ...errorObject };
      mileStoneData?.forEach((element, index) => {
        requiredFields?.forEach((el) => {
          if (!element[el.key]) {
            isInvalid = true;
            validationObject[`${"milestone"}_${el.key}_${index}`] =
              el.errorMessage;
          }
        });
      });

      setErrors(validationObject);

      raw = {
        ...raw,
        // payment_mode: inputs.payment_mode?.value,
        milestone: mileStoneData?.map((item, i) => {
          return {
            [`milestone_name`]: item?.milestone_name,
            // [`value`]: item?.value,
            [`value`]: Number(item?.value),
            [`date`]: item?.date ? moment(item?.date).format("YYYY-MM-DD") : "",
            [`percentage`]: item?.percentage,
            [`payment_status`]: item?.payment_status?.value ?? "",
            [`upload_receipt`]: item.upload_receipt?.length
              ? item.upload_receipt?.map((ele) => ele.response)
              : [],
          };
        }),
        is_payment: true,
      };
    }
    if (isInvalid) {
      scrollToTop();
      toast.error("Please resolve the errors", {
        position: "bottom-center",
        autoClose: 3000,
      });
      return;
    }
    console.log(raw);
    dispatch(
      updateBooking(raw, (res) => {
        if (res.status) {
          if (tab) {
            setActiveTab(tab);
          } else {
            toast.success(res.message, {
              position: "bottom-center",
              autoClose: 3000,
            });
            const statusKey = {
              key: keys[activeTab],
              id: id,
            };
            dispatch(getBookingDetails(statusKey));
          }
        }
      })
    );
  };

  const milestoneDataChangeHandler = (value, key, index) => {
    let dataList = [...mileStoneData];
    dataList[index][key] = value;
    setMileStoneData(dataList);
    setErrors({
      ...errors,
      [`${"milestone"}_${key}_${index}`]: "",
    });
  };

  const handleStatusChange = (data) => {
    const raw = {
      id: id,
      status: data?._id,
    };
    dispatch(
      updateStatusBooking(raw, (res) => {
        if (res) {
          const statusKey = {
            key: keys[activeTab],
            id: id,
          };
          dispatch(getBookingDetails(statusKey));
        }
      })
    );
  };

  // const fileSelectedHandler = (e, isInvalid, message, isClosing, field) => {
  //   if (isInvalid) {
  //     setInvalidUpload(true);
  //     setErrors({
  //       ...errors,
  //       [field]: message,
  //     });
  //   } else {
  //     setInvalidUpload(false);
  //     setErrors({
  //       ...errors,
  //       [field]: "",
  //     });
  //     if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
  //       setImageLoader(true);
  //       let formData = new FormData();
  //       if (trackId?.length == 0) {
  //         let track_id = Math.floor(1000 + Math.random() * 9000).toString();
  //         setTrackId(track_id);
  //         formData.append("track_id", track_id);
  //       } else {
  //         formData.append("track_id", trackId);
  //       }
  //       e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
  //         formData.append(`file`, item?.metaFile)
  //       );
  //       dispatch(
  //         uploadFile(formData, (res) => {
  //           setImageLoader(false);
  //           let oldFiles = e?.filter((el) => el?.metaFile == undefined);
  //           let arr = e
  //             ?.filter((el) => el?.metaFile != undefined)
  //             ?.map((ele, i) => {
  //               return {
  //                 ...ele,
  //                 url: ele?.url,
  //                 name: ele?.name,
  //                 response: res?.data[i],
  //                 id: res?.data[i] + i + 1,
  //                 alt: "",
  //               };
  //             });
  //         })
  //       );
  //     }
  //   }
  // };

  const inputsFileSetHandler = (field, files) => {
    setErrors({
      ...errors,
      [field]: "",
    });
    setInputs((s) => ({
      ...s,
      [field]: files,
    }));
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    // console.log(inputs),
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <div className="table__add border-style">
              <h5 className="card-title">View Booking Details</h5>
              <div className="booking">
                <div className="number">
                  <h2>BOOKING NUMBER</h2>
                  <h5>{details?.booking_ref_id}</h5>
                </div>
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      backgroundColor: getStatusColor(details?.status?.name),
                      color: "#fff",
                    }}
                  >
                    {details?.status?.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {statusData
                      ?.filter(
                        (data) =>
                          data._id !== details?.status._id &&
                          data.name !== "New Lead"
                      )
                      .map((data) => (
                        <Dropdown.Item
                          key={data?._id}
                          onClick={() => handleStatusChange(data)}
                        >
                          {data?.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="default__tabs">
              <ul>
                {tablist.map((tab, index) => (
                  <li
                    key={index}
                    className={`${activeTab === index ? "active" : ""} ${
                      tab?.disabled ? "pointerNone" : ""
                    }`}
                    onClick={() =>
                      tab?.disabled ? {} : tabChangeHandler(index)
                    }
                  >
                    {tab?.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {activeTab === 0 && (
          <BookingDetails details={details} formatDate={formatDate} />
        )}

        {activeTab === 1 && details && (
          <>
            <PassengerInfoDetails
              inputs={inputs}
              formPlug={formPlug}
              errors={errors}
              setErrors={setErrors}
              inputDataChangeHandler={inputDataChangeHandler}
              fileUploadHandler={fileUploadHandler}
              countryData={countryData}
            />
          </>
        )}

        {activeTab === 2 && (
          <>
            <CustomerEngagement
              questionnaire={questionnaire}
              questionnaireDataChangeHandler={questionnaireDataChangeHandler}
              paymentMethodList={paymentMethodList}
              formPlug={formPlug}
              inputs={inputs}
              errors={errors}
              inputHandler={inputHandler}
              paymentTypeList={paymentTypeList}
              details={details}
            />
          </>
        )}
        {activeTab === 3 && (
          <>
            <TravelItinerary
              inputs={inputs}
              formPlug={formPlug}
              errors={errors}
              setErrors={setErrors}
              inputHandler={inputHandler}
              inputDataChangeHandler={inputDataChangeHandler}
              activityData={activityData}
              attractionData={attractionData}
              inputDataRemoveChangeHandler={inputDataRemoveChangeHandler}
              removeImage={removeImage}
              fileUploadHandler={fileUploadHandler}
              documentList={documentList}
              onAddAdditionalItineraryData={onAddAdditionalItineraryData}
              itineraryDataChangeHandler={itineraryDataChangeHandler}
              itinerary={itinerary}
              onClearAdditionalItineraryData={onClearAdditionalItineraryData}
              onEditAttachment={onEditAttachment}
              onDeleteAttachment={onDeleteAttachment}
            />
          </>
        )}
        {activeTab === 4 && (
          <>
            <VehicleDetails
              inputs={inputs}
              inputHandler={inputHandler}
              errors={errors}
              formPlug={formPlug}
              getMinTime={getMinTime}
              details={details}
              fileUploadHandler={fileUploadHandler}
              setInputs={setInputs}
            />
          </>
        )}
        {activeTab === 5 && (
          <>
            <Quotation
              quotation={quotation}
              setQuotation={setQuotation}
              airfareQuotation={airfareQuotation}
              setAirfareQuotation={setAirfareQuotation}
              details={details}
              gross_total={gross_total}
              deduction={deduction}
              amount_to_be_paid={amount_to_be_paid}
            />
          </>
        )}

        {activeTab === 6 && (
          <>
            <Payments
              paymentTypeList={paymentTypeList}
              formPlug={formPlug}
              inputs={inputs}
              inputHandler={inputHandler}
              errors={errors}
              mileStoneData={mileStoneData}
              fileUploadHandler={fileUploadHandler}
              paymentStatusList={paymentStatusList}
              milestoneDataChangeHandler={milestoneDataChangeHandler}
              details={details}
            />
          </>
        )}
        {activeTab === 7 && (
          <Preview
            details={details}
            gross_total={gross_total}
            deduction={deduction}
            amount_to_be_paid={amount_to_be_paid}
            questionnaire={questionnaire}
            itinerary={itinerary}
            inputs={inputs}
          />
        )}

        <div className="card">
          <div className="card-body">
            <div className="col-12 mt-4">
              <h4 className="card-title border-style mb-3">
                Comments and attachments
              </h4>
              <div
                className="grid__items"
                style={{ gridTemplateColumns: "repeat(1, 1fr)" }}
              >
                <div className="item">
                  <TextArea
                    className={"css-txt-esc1"}
                    placeholder={"Type here"}
                    label="Comment"
                    field={"comment"}
                    value={inputs.comment}
                    error={errors.comment}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                  />
                </div>
                {/* <div className="item mt-4">
                  <FileUpload
                    style={{
                      marginRight: 5,
                      marginTop: 15,
                    }}
                    className="css-atc-esc2 mt-0 mb-0"
                    label="Add Attachment"
                    buttonLabel="Choose File"
                    accept={["jpg", "jpeg", "png", "svg"]}
                    onChange={(e, isInvalid, message, isClosing) => {
                      fileUploadHandler(
                        e,
                        isInvalid,
                        message,
                        isClosing,
                        "attachment",
                        inputHandler,
                        "single"
                      );
                    }}
                    field={"attachment"}
                    loader={imageLoader}
                    error={errors.attachment}
                    id="attachment"
                    maxFileSizeMB={100}
                    files={inputs?.attachment}
                    inputSocket={() => {}}
                  />
                </div> */}
                <div className="d-flex gap-3 mt-3">
                  <FileUpload
                    label="Add Attachments"
                    className="css-atc-esc1"
                    buttonLabel="Add Attachments"
                    accept={[
                      "jpg",
                      "jpeg",
                      "png",
                      "pdf",
                      "docx",
                      "doc",
                      "xlsx",
                      "xls",
                      "csv",
                      "ppt",
                      "pptx",
                    ]}
                    onChange={(e, isInvalid, message, isClosing) => {
                      fileUploadHandler(
                        e,
                        isInvalid,
                        message,
                        isClosing,
                        "attachments",
                        inputHandler,
                        "single"
                      );
                    }}
                    field={"attachments"}
                    loader={imageLoader}
                    error={errors.attachments}
                    id="uploadFiles"
                    maxFileSizeMB={100}
                    multiple={true}
                    showPreview
                    files={inputs?.attachments}
                  />
                </div>
                <SupportedFormats
                  formats={[
                    "jpg",
                    "jpeg",
                    "png",
                    "pdf",
                    "docx",
                    "doc",
                    "xlsx",
                    "xls",
                    "csv",
                    "ppt",
                    "pptx",
                  ]}
                  maxSize={100}
                />
              </div>
              <div className="mt-4">
                <button
                  className="btn btn-primary m-2"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>

                <button
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="history__tabs">
          {/* <ul>
            {historyTabs.map((tab, index) => (
              <li
                key={index}
                className={activeHistoryTab === index ? "active" : ""}
                onClick={() => setActiveHistoryTab(index)}
              >
                {tab}
              </li>
            ))}
          </ul> */}
          {activeHistoryTab === 0 && (
            <CommentUpdates comments={details?.comments} path={"booking"} />
          )}
          {/* {activeHistoryTab === 1 && <History />} */}
          {/* {activeHistoryTab === 2 && <PaymentHistory />} */}
        </div>
      </div>
    </div>
  );
};

export default ViewBooking;
