import { isDate } from "moment";
import React, { useState } from "react";

export default function useForm(initialData = {}) {
  const [validations, setValidations] = useState([]);
  const [inputs, setInputs] = useState(initialData);
  const [errors, setErrors] = useState({});

  function formPlug(obj = {}) {
    // console.log(obj, 'useform')

    if (obj.clearValidation || obj.clearValue) {
      if (obj.clearValidation)
        setValidations((s) => s.filter((item) => item.key !== obj.key));
      if (obj.clearValue) {
        setInputs((s) => ({ ...s, [obj.key]: "" }));
        setErrors((s) => ({ ...s, [obj.key]: "" }));
      }
    } else {
      setInputs((s) => ({
        ...s,
        [obj.key]: s[obj.key] === undefined ? "" : s[obj.key],
      }));
      setErrors((s) => ({ ...s, [obj.key]: "" }));

      let arr = [];
      "required" in obj &&
        arr.push({
          func: "requiredField",
          key: obj.key,
          errMessage: obj.required?.message,
          condition: obj.required?.condition,
        });
      "minChar" in obj &&
        arr.push({
          func: "minCharLength",
          key: obj.key,
          errMessage: obj.minChar?.message,
          minLength: obj.minChar?.length,
          condition: obj.minChar?.condition,
        });
      "maxChar" in obj &&
        arr.push({
          func: "maxCharLength",
          key: obj.key,
          errMessage: obj.maxChar?.message,
          maxLength: obj.maxChar?.length,
          condition: obj.maxChar?.condition,
        });
      "minNumber" in obj &&
        arr.push({
          func: "minNumber",
          key: obj.key,
          errMessage: obj.minNumber?.message,
          minNumber: obj.minNumber?.number,
          condition: obj.minNumber?.condition,
        });
      "maxNumber" in obj &&
        arr.push({
          func: "maxNumber",
          key: obj.key,
          errMessage: obj.maxNumber?.message,
          maxNumber: obj.maxNumber?.number,
          condition: obj.maxNumber?.condition,
        });
      "email" in obj &&
        arr.push({
          func: "validEmail",
          key: obj.key,
          errMessage: obj.email?.message,
          condition: obj.email?.condition,
        });
      "alphabetsOnly" in obj &&
        arr.push({
          func: "alphabetsOnly",
          key: obj.key,
          errMessage: obj.alphabetsOnly?.message,
          more: obj.alphabetsOnly?.more,
          condition: obj.alphabetsOnly?.condition,
          lang: obj.alphabetsOnly?.lang,
        });
      "alphaNumeric" in obj &&
        arr.push({
          func: "alphaNumeric",
          key: obj.key,
          errMessage: obj.alphaNumeric?.message,
          more: obj.alphaNumeric?.more,
          condition: obj.alphaNumeric?.condition,
          lang: obj.alphaNumeric?.lang,
        });
      "phone" in obj &&
        arr.push({
          func: "validPhoneNumber",
          key: obj.key,
          errMessage: obj.phone?.message,
          digits: obj.phone?.digits,
          condition: obj.phone?.condition,
        });
      "checkIfTrue" in obj &&
        arr.push({
          func: "checkIfTrue",
          key: obj.key,
          errMessage: obj.checkIfTrue?.message,
          condition: obj.checkIfTrue?.condition,
        });
      "minDate" in obj &&
        arr.push({
          func: "minDate",
          key: obj.key,
          errMessage: obj.minDate?.message,
          condition: obj.minDate?.condition,
        });
      "maxDate" in obj &&
        arr.push({
          func: "maxDate",
          key: obj.key,
          errMessage: obj.maxDate?.message,
          condition: obj.maxDate?.condition,
        });
      // 'validDate' in obj && arr.push({ func: 'validDate', key: obj.key, errMessage: obj.errMessage, condition: obj.condition })

      setValidations((s) => [...s, ...arr]);
    }
  }

  // console.log({ validations })
  // console.log({ inputs })

  async function validate({
    accept = [],
    except = [],
    required = { accept: [], except: [] },
    returnErrors = false
  } = {}) {
    return await validator(
      setErrors,
      validations
        .filter((item) => {
          return accept.length > 0
            ? accept.includes(item.key)
              ? item
              : false
            : except.length > 0
            ? except.includes(item.key)
              ? false
              : item
            : item;
        })
        .filter((item) => {
          if (item.func !== "requiredField") return item;
          return required?.accept?.length > 0
            ? required?.accept?.includes(item.key)
              ? item
              : false
            : required?.except?.length > 0
            ? required?.except?.includes(item.key)
              ? false
              : item
            : item;
        })
        .filter(() => {
          return !required === false;
        })
        .map((item) => {
          item.obj = inputs;
          let func = actions[item.func];
          return func(item);
        }),
        returnErrors
    );
  }

  function inputHandler(value, key) {
    // console.log(value, key, 'inputHandler useform')

    setInputs((s) => ({ ...s, [key]: value }));
    setErrors((s) => ({ ...s, [key]: "" }));
  }

  function clearInputs({ includes, value } = {}) {
    setInputs((s) =>
      Object.fromEntries(
        Object.keys(s).map((a) => {
          return includes
            ? a.includes(includes)
              ? [[a], value === undefined ? "" : value]
              : [[a], s[a]]
            : [[a], ""];
        })
      )
    );
  }

  function removeInputs({ includes } = {}) {
    setInputs((s) =>
      Object.fromEntries(
        Object.keys(s)
          .filter((a) => {
            return includes ? !a.includes(includes) : false;
          })
          .map((a) => {
            return [[a], s[a]];
          })
      )
    );
  }

  function groupInputs({ includes, hideEmpty, accept = [], except = [] } = {}) {
    let isArray = Array.isArray(includes);
    return Object.keys(inputs).reduce(
      (acc, crr) => {
        let res = acc;
        if (isArray) {
          includes.forEach((item, i) => {
            let exactKey = crr.replace(item, "");
            if (
              crr.includes(item) &&
              (accept.length > 0 ? accept.includes(exactKey) : true) &&
              (except.length > 0 ? !except.includes(exactKey) : true)
            ) {
              if (!(hideEmpty && inputs?.[crr] === "")) {
                res[i] = { ...res[i], [exactKey]: inputs?.[crr] };
                if (!("__id" in res[i])) res[i].__id = item;
              }
            }
          });
        } else {
          if (
            crr.includes(includes) &&
            (accept.length > 0
              ? accept.includes(crr.replace(includes, ""))
              : true) &&
            (except.length > 0
              ? !except.includes(crr.replace(includes, ""))
              : true)
          ) {
            let exactKey = crr.replace(includes, "");
            if (!(hideEmpty && inputs?.[crr] === "")) {
              res = { ...res, [exactKey]: inputs?.[crr] };
              if (!("__id" in res)) res.__id = includes;
            }
          }
        }
        return res;
      },
      isArray ? [] : {}
    );
  }

  // console.log('errors', errors)
  // console.log('validations', validations)
  // console.log('inputs', inputs)

  return {
    formPlug,
    validate,
    inputHandler,
    inputs,
    setInputs,
    errors,
    setErrors,
    clearInputs,
    removeInputs,
    groupInputs,
  };
}

const validator = async (errState, items,returnErrors) => {
  return Promise.all(
    items.map(async (item) => {
      return await item.then((e) => e).catch((e) => e);
    })
  ).then((e) => {
    let obj = {};
    e.forEach((item) => {
      if (!obj[Object.keys(item)[0]]) Object.assign(obj, item);
    });
    errState(obj);
    
    let isTrue = Object.values(obj).every((item) => item === "");
    setTimeout(() => {
      if (!isTrue)
        document.getElementById("error")?.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "nearest",
        });
    }, 0);
    if(returnErrors){
      return({
        valid :isTrue,
        err : obj
      })
    }else{
      return isTrue;
    }
    
  });
};
const actions = {
  minCharLength: ({ obj, key, errMessage, minLength, condition }) => {
    let text = obj[key] || "";
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        if (text.trim().length < minLength || 0)
          reject({
            [key]: errMessage || `Minimum ${minLength} characters required`,
          });
        else resolve({ [key]: "" });
      } else resolve({ [key]: "" });
    });
  },

  maxCharLength: ({ obj, key, errMessage, maxLength, condition }) => {
    let text = obj[key] || "";
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        if (text.trim().length <= maxLength || 0) resolve({ [key]: "" });
        else
          reject({
            [key]:
              errMessage ||
              `You have exceeded the maximum limit of ${maxLength} characters`,
          });
      } else resolve({ [key]: "" });
    });
  },

  minNumber: ({ obj, key, errMessage, minNumber, condition }) => {
    let number = obj[key] || 0;
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        if (isNaN(number)) reject({ [key]: "Value entered is not a number" });
        else if (number.trim() < minNumber || 0)
          reject({
            [key]: errMessage || `Minimum ${minNumber} characters required`,
          });
        else resolve({ [key]: "" });
      } else resolve({ [key]: "" });
    });
  },

  maxNumber: ({ obj, key, errMessage, maxNumber, condition }) => {
    let number = obj[key] || 0;
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        if (isNaN(number)) reject({ [key]: "Value entered is not a number" });
        else if (number.trim() <= maxNumber || 0) resolve({ [key]: "" });
        else
          reject({
            [key]:
              errMessage ||
              `You have exceeded the maximum limit of ${maxNumber} characters`,
          });
      } else resolve({ [key]: "" });
    });
  },

  requiredField: ({ obj, key, errMessage, condition }) => {
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        if (actions.isValueExist(obj[key])) resolve({ [key]: "" });
        else reject({ [key]: errMessage || "Required field" });
      } else resolve({ [key]: "" });
    });
  },

  validEmail: ({ obj, key, errMessage, condition }) => {
    let text = obj[key] || "";
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        let regex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
        if (text.trim() === "" || regex.test(text.trim()))
          resolve({ [key]: "" });
        else reject({ [key]: errMessage || "Invalid email" });
      } else resolve({ [key]: "" });
    });
  },

  alphabetsOnly: ({ obj, key, errMessage, condition, lang, more }) => {
    let text = obj[key] || "";
    let alphabets =
      lang === "ar"
        ? "ء-ي ًٌَُﻹإٍِﻷأـ،~ْﻵآ"
        : lang === "en"
        ? "a-zA-Z"
        : "a-zA-Zء-ي ًٌَُﻹإٍِﻷأـ،~ْﻵآ";
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        let regex = new RegExp(`^[${alphabets} ${more || ""}]*$`);
        if (regex.test(text.trim())) resolve({ [key]: "" });
        else
          reject({
            [key]:
              errMessage ||
              `Only ${lang === "ar" ? "arabic " : ""}alphabets accepted`,
          });
      } else resolve({ [key]: "" });
    });
  },

  alphaNumeric: ({ obj, key, errMessage, condition, lang, more }) => {
    let text = obj[key] || "";
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        let regex = new RegExp(
          `^[${lang === "ar" ? "ء-ي" : "a-zA-Zء-ي"}0-9${more || ""}]*$`
        );
        if (regex.test(text.trim())) resolve({ [key]: "" });
        else
          reject({
            [key]:
              errMessage ||
              `Only ${
                lang === "ar" ? "arabic " : ""
              }alphabets and numbers accepted`,
          });
      } else resolve({ [key]: "" });
    });
  },

  validPhoneNumber: ({ obj, key, errMessage, digits, condition }) => {
    let text = obj[key] || "";
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        let regex = new RegExp(`^[0-9]{${digits.replace("-", ",") || 10}}$`);
        if (text.trim() === "" || regex.test(text.trim()))
          resolve({ [key]: "" });
        else reject({ [key]: errMessage || "Invalid phone number" });
      } else resolve({ [key]: "" });
    });
  },

  checkIfTrue: ({ obj, key, errMessage, condition }) => {
    let value = obj[key] || false;
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        if (value) resolve({ [key]: "" });
        else reject({ [key]: errMessage || "Required to be checked" });
      } else resolve({ [key]: "" });
    });
  },

  validDate: ({ obj, key, errMessage, condition }) => {
    let date = obj[key] || new Date();
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        if (isDate(date)) resolve({ [key]: "" });
        else reject({ [key]: errMessage || "Invalid date" });
      } else resolve({ [key]: "" });
    });
  },

  minDate: ({ obj, key, errMessage, minDate, condition }) => {
    let date = obj[key] || new Date();
    let min = new Date(minDate);
    let startDate = min.toISOString().split("T")[0];
    startDate = new Date(startDate);
    startDate = `${startDate.getDate()}/${
      startDate.getMonth() + 1
    }/${startDate.getFullYear()}`;
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        if (isDate(min)) {
          if (date >= min) resolve({ [key]: "" });
          else
            reject({
              [key]: errMessage || `Date before ${startDate} is not acceptable`,
            });
        } else reject({ [key]: "Invalid min date" });
      } else resolve({ [key]: "" });
    });
  },

  maxDate: ({ obj, key, errMessage, maxDate, condition }) => {
    let date = obj[key] || new Date();
    let min = new Date(maxDate);
    let endDate = min.toISOString().split("T")[0];
    endDate = new Date(endDate);
    endDate = `${endDate.getDate()}/${
      endDate.getMonth() + 1
    }/${endDate.getFullYear()}`;
    if (condition === undefined) condition = true;
    return new Promise((resolve, reject) => {
      if (condition) {
        if (isDate(min)) {
          if (date <= min) resolve({ [key]: "" });
          else
            reject({
              [key]: errMessage || `Date after ${endDate} is not acceptable`,
            });
        } else reject({ [key]: "Invalid max date" });
      } else resolve({ [key]: "" });
    });
  },

  isValueExist: (text) => {
    let value;
    if (Array.isArray(text)) value = text;
    else if (typeof text === "string") value = text.trim();
    else if (typeof text === "number") value = String(text.trim());
    else if (typeof text === "boolean") value = String(text.trim());
    else if (typeof text === "object")
      value = JSON.stringify(text).replace(/^\{/, "").replace(/\}$/, "").trim();
    else value = "";
    return value.length !== 0;
  },

  isDate: (dateToTest) => {
    return !isNaN(Date.parse(dateToTest));
  },
};
