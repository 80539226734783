
import React from "react";
import { IMAGE_BASE_URL } from "../constants/configuration";
import { DateConverter } from "../utils/helpers";
import AttachmentContent from "../commonComponents/AttachmentContent/AttachmentContent";

export default function CommentUpdates({ comments, path }) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row mt-4">
          <h4 className="card-title border-style mb-3">History</h4>
          {comments?.map((ele) => (
            <div key={ele._id} className="card history__card">
              <div className="history-card">
                <div className="icon">
                  <i className="far fa-calendar-check"></i>
                </div>
                <div className="card-element">
                  <div className="history-header ps-4">
                    <h6 style={{ color: "#0279b0" }}>{ele?.created_date ? DateConverter(ele?.created_date) : ""}</h6>
                    {ele?.commented_by &&(
                    <p><span>Updated by</span> {ele?.commented_by?.name}</p>
                       )}
                       </div>
                       <div className="comment_desc">
                    <p>{ele?.comment}</p>
                    </div>
                  
                  <div className="card-body ps-4">
                    <div className="updates__images">
                      <AttachmentContent files={ele.attachments.map(file => ({ url: `${IMAGE_BASE_URL}${path}/${file}`, name: file , type: file?.split(".").pop(), }))} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>
    </div>
  );
}
