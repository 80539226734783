

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from "../../assets/icons/EDIT.svg";
import DeleteIcon from "../../assets/icons/DELETE.svg";
import EntriesCounter from '../../commonComponents/EntriesCounter/EntriesCounter';
import Input from '../../commonComponents/Input/Input';
import LoadingSpinner from '../../commonComponents/LoadingSpinner/LoadingSpinner';
import Table from '../../commonComponents/Table/Table';
import Actions from '../../commonComponents/Actions/Actions';

import useFilter from '../../utils/Hooks/useFilter';
import { useDispatch, useSelector } from 'react-redux';
import { promocode_data, promocode_loading, promocode_page_details, value } from '../../redux/commonReducer';
import { deletePromocode, getPromocodes } from '../../redux/actionCreator';
import DeleteModal from '../../commonComponents/DeleteModal/DeleteModal';
import { DateConverter } from '../../utils/helpers';

const PromoCodes = () => {   
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteID, setDeleteID] = useState(""); 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector(promocode_data);
    const pageData = useSelector(promocode_page_details); 
    const loading = useSelector(promocode_loading);
    const initialFilter = {
      page: 0,
      perPage: 10,
      sortColumn: "created_date",
      sortValue: -1,
      keyword: "",
    };
  
    const [filterData, setFilterData] = useFilter({
      initialFilter,
      dataFetch: (filter) => {
        getSearchDataHandler(filter);
      },
      dependency: [],
    });
  
    const showDeletePopup = (id) => {
      setShowDeleteModal(true);
      setDeleteID(id);
    };
  
    const handleClose = () => {
      setDeleteID("");
      setShowDeleteModal(false);
    };
  
    const deleteHandler = () => {
      dispatch(
        deletePromocode(deleteID, () => {
          setShowDeleteModal(false);
          setDeleteID("");
          if (data?.length == 1 && pageData?.pages > 1 && filterData?.page != 0) {
            setFilterData({
              ...filterData,
              page: filterData?.page - 1,
            });
  
            let promocodeData = {
              page: filterData?.page - 1,
              sort_order: filterData.sortValue,
              perPage: filterData.perPage,
              sort_key: filterData.sortColumn,
              keyword: filterData?.keyword?.trim() ?? "",
              is_pagination: "1",
            };
  
            dispatch(getPromocodes(promocodeData));
          } else {
            getSearchDataHandler(filterData);
          }
        })
      );
    };
  
    function filterHandler(key, value) {
      let filter;
      switch (key) {
        case "searchBox":
          filter = { ...filterData, ...value, page: 0 };
          break;
        case "clearSearch":
          filter = initialFilter;
          break;
        case "keyword":
          filter = { ...filterData, keyword: value, page: 0 };
          break;
        case "sort":
          filter = {
            ...filterData,
            sortColumn: value.key,
            sortValue: value.value,
          };
          break;
        case "page":
          filter = { ...filterData, page: value };
          break;
        case "entries":
          filter = { ...filterData, page: 0, perPage: parseInt(value) };
          break;
        default:
      }
  
      if (!filter) return;
      setFilterData(filter);
      getSearchDataHandler(filter);
    }
  
    const getSearchDataHandler = (filter) => {
      const promocodeData = {
        page: filter.page,
        sort_order: filter.sortValue,
        perPage: filter.perPage,
        sort_key: filter.sortColumn,
        keyword: filter?.keyword?.trim() ?? "",
        is_pagination: "1",
      };
  
      dispatch(getPromocodes(promocodeData));
    };

    return (
        <>
        <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="table__add border-style">
                <h5 className="card-title">Manage Promo Code</h5>
                <Link to="/add-promocode">
                  <button className="btn btn-primary">Add Promo Code</button>
                </Link>
              </div>
              <div className="search__head">
                <div className="count">
                  <EntriesCounter
                    value={filterData.perPage}
                    onChange={(e) => {
                      filterHandler("entries", e);
                    }}
                  />
                </div>
                <div className="search">
                  <Input
                    value={filterData.keyword}
                    type="text"
                    className="css-inp-esc2 search"
                    // icon={GenericSearchIcon}
                    placeholder={"Search Titles"}
                    reverse
                    width="300px"
                    field="keyword"
                    showCloseBtn
                    onChange={(e) => !e && filterHandler("keyword", e)}
                    onSubmit={(e) => {
                      filterHandler("keyword", e);
                    }}
                    onIconClick={(e) => {
                      filterHandler("keyword", e);
                    }}
                  />
                </div>
              </div>
              {loading ? (
                <LoadingSpinner height={"200px"} />
              ) : (
                <Table
                  titles={[
                    {
                      title: "Sl.No",
                      key: "sl_no",
                      width: "10px",
                    },
                    {
                      title: "Created Date",
                      key: "created_date",
                      sort: true,
                      width: "250px",
                    },
                    {
                      title: "Discount Code",
                      key: "promotion_code",
                      sort: true,
                      width: "150px",
                    },
                    {
                      title: "Title",
                      key: "title",
                      sort: true,
                      width: "150px",
                    },
                    {
                      title: "Discount Type",
                      key: "discount_type",
                      sort: true,
                      width: "150px",
                    },
                    {
                      title: "Discount",
                      key: "discount_value",
                      sort: true,
                      width: "150px",
                    },
                    {
                      title: "Usage Limit",
                      key: "max_count",
                      sort: true,
                      width: "150px",
                    },

                    { title: "Action", key: "action", width: "150px" },
                  ]}
                  content={data?.map((item, i) => ({
                    sl_no: filterData.page * filterData.perPage + (i + 1),
                    created_date: DateConverter(item?.created_date),
                    promotion_code: item?.promotion_code,
                    title: item?.title,
                    discount_value: item?.discount_value,
                    discount_type: item?.discount_type,
                    max_count:item?.max_count,

                    action: (
                      <Actions
                        buttons={{
                          edit: EditIcon,
                          delete: DeleteIcon,
                        }}
                        onEdit={() => navigate(`/update-promocode/${item?._id}`)}
                        onDelete={() => showDeletePopup(item?._id)}
                      />
                    ),
                  }))}
                  onSort={(e) => filterHandler("sort", e)}
                  page={filterData.page}
                  pagesCount={pageData?.pages}
                  pageStatus={pageData?.status}
                  onPageChange={(e) => {
                    filterHandler("page", e.selected);
                  }}
                  sortValue={{
                    key: filterData.sortColumn,
                    value: filterData.sortValue,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
       <DeleteModal
       showDeleteModal={showDeleteModal}
       handleClose={handleClose}
       deleteHandler={deleteHandler}
       title={"Delete Promo Code"}
     />
     </>
    )
}

export default PromoCodes