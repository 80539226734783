import React, { useEffect, useState } from "react";
import useForm from "../utils/Hooks/useForm";
import FileUpload from "../commonComponents/FileUpload/FileUpload";
import { AiOutlineClose } from "react-icons/ai";
import {
  getContactDetails,
  insertContact,
  uploadFile,
} from "../redux/actionCreator";
import Seo from "../components/Seo";
import { useDispatch, useSelector } from "react-redux";
import Input from "../commonComponents/Input/Input";
import TextArea from "../commonComponents/TextArea/TextArea";
import { contact_details, contact_path } from "../redux/commonReducer";
import { IMAGE_BASE_URL } from "../constants/configuration";

const Contact = () => {
  const [trackId, setTrackId] = useState("");
  const [err, setErr] = useState({});
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [data, setData] = useState({
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContactDetails());
  }, []);

  const details = useSelector(contact_details);
  const path = useSelector(contact_path);

  useEffect(() => {
    if (details) {
      let result = {
        image: details?.image
          ? [
              {
                type: details?.image?.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${details?.image}`,
                name: `${details?.image}`,
                response: `${details?.image}`,
              },
            ]
          : "",
        image_alt: details?.image_alt,
        title: details?.title,
        short_description1: details?.short_description1,
        email: details?.email,
        short_description2: details?.short_description2,
        phone: details?.phone,
        address_title1: details?.address_title1,
        address_title2: details?.address_title2,
        address1: details?.address1,
        address2: details?.address2,
        getintouch_title: details?.getintouch_title,
        getintouch_tagline: details?.getintouch_tagline,
        footer_info_text: details?.footer_info_text,
      };

      setInputs({ ...result });
      setData({
        meta_title: details?.meta_title,
        meta_keyword: details?.meta_keyword,
        meta_description: details?.meta_description,
      });
    }
  }, [details]);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  const removeImage = (type) => {
    setInputs((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const singleFileUploadHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        formData.append(`path`, "cms");
        dispatch(
          uploadFile(formData, (res) => {
            setErrors({
              ...errors,
              [field]: "",
            });
            setInputs((s) => ({
              ...s,
              [field]: [
                {
                  type: e[0].type,
                  url: e[0].url,
                  name: e[0]?.name,
                  metaFile: e,
                  response: res?.data[0],
                },
              ],
            }));
          })
        );
      }
    }
  };

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const onCancelHandler = () => {
    setInputs({});
    setErrors({});
    setErr({});
    dispatch(getContactDetails());
  };

  const onSubmitHandler = async () => {
    let isValidated = await validate({
      returnErrors: true,
    });

    if (isValidated?.valid && !invalidUpload) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let raw = {
      image: inputs?.image?.[0]?.response ?? "",
      image_alt: inputs?.image_alt,
      title: inputs?.title,
      short_description1: inputs?.short_description1,
      email: inputs?.email,
      short_description2: inputs?.short_description2,
      phone: inputs?.phone,
      address_title1: inputs?.address_title1,
      address_title2: inputs?.address_title2,
      address1: inputs?.address1,
      address2: inputs?.address2,
      getintouch_title: inputs?.getintouch_title,
      getintouch_tagline: inputs?.getintouch_tagline,
      footer_info_text: inputs?.footer_info_text,

      meta_title: data?.meta_title?.trim(),
      meta_keyword: data?.meta_keyword?.trim(),
      meta_description: data?.meta_description?.trim(),
      track_id: trackId,
      path : "cms"
    };
    dispatch(
      insertContact(raw, (res) => {
        if (res) {
          onCancelHandler();
        }
      })
    );
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3"> Contact Us Page</h5>
            <div className="row">
              <div className="col-6">
                <div className="mb-3">
                  <div className="d-flex gap-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 15,
                      }}
                      className="css-atc-esc5 mt-3"
                      label="Banner Image"
                      accept={["jpg", "jpeg", "png"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("image")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          image: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "image"
                        );
                      }}
                      error={errors.image}
                      width={28}
                      field={"image"}
                      id="image"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.image}
                      required={{ message: "Please Upload Image" }}
                      inputSocket={formPlug}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 mb-3">
                <Input
                  className="css-inp-esc1 "
                  label="Image Alt"
                  placeholder="Type here"
                  required={{ message: "Please Enter Alt" }}
                  field={"image_alt"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3"> Contact Us </h5>
            <div className="row">
              <div className="col-12  mb-3">
                <Input
                  className="css-inp-esc1 "
                  label="Title"
                  placeholder="Type here"
                  required={{ message: "Please enter title" }}
                  field={"title"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <Input
                  className="css-inp-esc1 "
                  label="Email Description"
                  placeholder="Type here"
                  required={{ message: "Please Enter Email Description" }}
                  field={"short_description1"}
                  // validation={{ maxChar:}}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <Input
                  type="email"
                  className="css-inp-esc1 "
                  label="Email"
                  placeholder="Type here"
                  required={{ message: "Please Enter Email" }}
                  field={"email"}
                  validation={{ email: true }}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <Input
                  className="css-inp-esc1 "
                  label="Phone Description"
                  placeholder="Type here"
                  required={{ message: "Please Enter Phone Description" }}
                  field={"short_description2"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <Input
                  type="number"
                  className="css-inp-esc1"
                  label="Phone"
                  validation={{ phone: true }}       
                  placeholder="Type here"
                  required={{ message: "Please Enter Phone Number" }}
                  field={"phone"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <Input
                  className="css-inp-esc1"
                  label="USA Address Title"
                  placeholder="Type here"
                  required={{ message: "Please Enter Address Title" }}
                  field={"address_title1"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <Input
                  className="css-inp-esc1 "
                  label="UAE Address Title"
                  placeholder="Type here"
                  required={{ message: "Please Enter Address Title" }}
                  field={"address_title2"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-12  mb-3">
                <TextArea
                  className={"css-txt-esc1"}
                  placeholder={"Type here"}
                  label="USA Address "
                  field={"address1"}
                  value={inputs.address1}
                  error={errors.address1}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  required={{ message: "Please enter Address" }}
                />
              </div>

              <div className="col-12 mb-3">
                <TextArea
                  className={"css-txt-esc1"}
                  placeholder={"Type here"}
                  label="UAE Address Title"
                  field={"address2"}
                  value={inputs.address2}
                  error={errors.address2}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  required={{ message: "Please enter Address" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3"> Get In Touch</h5>
            <div className="row">
              <div className="col-12  mb-3">
                <Input
                  className="css-inp-esc1 "
                  label="Title"
                  placeholder="Type here"
                  required={{ message: "Please Enter Title" }}
                  field={"getintouch_title"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <Input
                  className="css-inp-esc1 "
                  label="Tag Line"
                  placeholder="Type here"
                  required={{ message: "Please Enter Tag line" }}
                  field={"getintouch_tagline"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <Input
                  className="css-inp-esc1 "
                  label="Footer Info Text"
                  placeholder="Type here"
                  required={{ message: "Please Enter Footer Info" }}
                  field={"footer_info_text"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Seo data={data} onChangeHandler={onChangeHandler} />
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary me-3"
                onClick={() => onSubmitHandler()}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
