import { useNavigate } from "react-router-dom";
import { FaSlidersH } from "react-icons/fa";
import {
  getManageBooking,
  getStatusList,
  updateBooking,
  updateStatusBooking,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  status_list,
  booking_data,
  booking_page,
} from "../../redux/commonReducer";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import useFilter from "../../utils/Hooks/useFilter";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";

import Actions from "../../commonComponents/Actions/Actions";
import EditIcon from "../../assets/icons/EDIT.svg";

import { Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";

import Select from "../../commonComponents/Select/Select";
import { DateConverter } from "../../utils/helpers";

const ManageEnquiries = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [matchedStatus, setMatchedStatus] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(booking_data);
  const pageData = useSelector(booking_page);
  const statusData = useSelector(status_list);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updatedAt",
    sortValue: -1,
    keyword: "",
    customer_name: "",
    email: "",
    booking_ref_id: "",
    status: "",
  };

  useEffect(() => {
    let statusKey = {
      key: "booking_management",
    };
    dispatch(getStatusList(statusKey));
  }, []);

  const [filterData, setFilterData] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });
  // console.log(error);

  function filterHandler(key, value) {
    console.log(key, value, "55");
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "customer_name":
        filter = { ...filterData, submission_date: value, page: 0 };
        break;
      case "booking_ref_id":
        filter = { ...filterData, booking_ref_id: value, page: 0 };
        break;
      case "email":
        filter = { ...filterData, email: value, page: 0 };
        break;
      case "status":
        filter = { ...filterData, status: value, page: 0 };
        break;

      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    console.log(filter, "555");
    setFilterData(filter);
    if (
      filter.status === "" &&
      filter.enquiry_type === "" &&
      filter.submission_date === ""
    ) {
      setError("Please Select At Least One Field");
      return;
    } else {
      setError("");
    }

    if (
      key !== "submission_date" &&
      key !== "enquiry_type" &&
      key !== "status"
    ) {
      getSearchDataHandler(filter);
    }
  }
  const getStatusColor = (status) => {
    if (status === "New Lead") return "#379777";
    if (status === "Lost Lead") return "#FF4545";
    if (status === "Discussion In Progress") return "#007bff";
    if (status === "Lead Converted") return "#F4CE14";
    return "#6c757d";
  };

  const handleStatusChange = (index, status_name, statusId, dataId) => {
    setMatchedStatus({ [index]: status_name });
    const row = {
      id: dataId,
      status: statusId,
    };
    dispatch(
      updateStatusBooking(row, (res) => {
        if (res) {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  const getSearchDataHandler = (filter) => {
    const enquiriesData = {
      page: filter.page,
      sort_order: filter.sortValue,
      perPage: filter.perPage,
      sort_key: filter.sortColumn,
      keyword: filter?.keyword?.trim() ?? "",
      is_pagination: "1",
      email: filter?.email,
      booking_ref_id: filter?.booking_ref_id,
      customer_name: filter?.customer_name,
      status: filter?.status._id,
    };

    dispatch(getManageBooking(enquiriesData));
  };

  const columns = [
    { title: "Sl.No", key: "sl_no", width: "10px" },
    {
      title: "Booking Reference ID",
      key: "booking_ref_id",
      sort: true,
      width: "200px",
    },
    {
      title: "Date",
      key: "date",
      sort: true,
      width: "120px",
    },
    {
      title: "Customer Name",
      key: "customer_name",
      sort: true,
      width: "140px",
    },
    { title: "Phone ", key: "phone", sort: true, width: "120px" },
    {
      title: "Choosen Package",
      key: "choosen_package",
      sort: true,
      width: "160px",
    },
    {
      title: "Duration Of Travel",
      key: "duration",
      width: "80px",
      sort: true,
    },
    { title: "Status", key: "status", width: "50px" },
    { title: "Action", key: "action", width: "10px" },
  ];

  const content = data?.map((item, i) => {
    const status =
      matchedStatus?.[i] ||
      statusData?.find((s) => s._id === item.status)?.name;
    return {
      sl_no: filterData.page * filterData.perPage + (i + 1),
      booking_ref_id: item?.booking_ref_id,
      date: item?.created_date ? DateConverter(item?.created_date) : "N/A",
      customer_name:
        item?.traveller_info?.first_name +
        " " +
        item?.traveller_info?.last_name,
      phone: item?.traveller_info?.phone,
      choosen_package: item?.bookingitineraries?.package_name,
      duration: item?.travel_duration,
      status: (
        <Dropdown>
          <Dropdown.Toggle
            style={{ backgroundColor: getStatusColor(status), color: "#fff" }}
          >
            {status}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {statusData
              ?.filter((s) => s._id !== item.status && s.name !== "New Lead")
              .map((s) => (
                <Dropdown.Item
                  key={s._id}
                  onClick={() => handleStatusChange(i, s.name, s._id, item._id)}
                >
                  {s.name}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      ),
      action: (
        <Actions
          buttons={{
            edit: EditIcon,
          }}
          onEdit={() => navigate(`/view-booking/${item._id}`)}
        />
      ),
    };
  });

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <div className="table__add border-style mb-3">
              <h5 className="card-title">Manage Bookings</h5>
              <button className="me-2 filter_btn">
                <FaSlidersH onClick={() => setOpen(!open)} size={30} />
              </button>
            </div>

            {open && (
              <div className="col">
                <div className="row row-cols-5 mb-4">
                  <div className="col pe-0">
                    <Input
                      value={filterData.booking_ref_id}
                      type="text"
                      className="css-inp-esc2 search"
                      label="Booking Reference ID"
                      placeholder={"Type Here"}
                      field="booking_ref_id"
                      showCloseBtn
                      onChange={(e) =>
                        setFilterData({ ...filterData, booking_ref_id: e })
                      }
                      onIconClick={(e) => {
                        filterHandler("booking_ref_id", e);
                      }}
                    />
                  </div>
                  <div className="col pe-0">
                    <Input
                      value={filterData.customer_name}
                      type="text"
                      className="css-inp-esc2 search"
                      label="Customer Name"
                      placeholder={"Type Here"}
                      field="customer_name"
                      showCloseBtn
                      onChange={(e) =>
                        setFilterData({ ...filterData, customer_name: e })
                      }
                      onIconClick={(e) => {
                        filterHandler("customer_name", e);
                      }}
                    />
                  </div>
                  <div className="col pe-0">
                    <Input
                      value={filterData.email}
                      type="text"
                      className="css-inp-esc2 search"
                      label="Email"
                      placeholder={"Type Here"}
                      field="email"
                      showCloseBtn
                      onChange={(e) =>
                        setFilterData({ ...filterData, email: e })
                      }
                      onIconClick={(e) => {
                        filterHandler("email", e);
                      }}
                    />
                  </div>
                  <div className="col pe-0">
                    <label className="form-label">Status</label>
                    <Select
                      className="css-slc-esc1 mb-3"
                      placeholder={"Select"}
                      options={statusData}
                      labelSetter={(o) => o?.name}
                      valueSetter={(option) => option?._id}
                      onChange={(e) =>
                        setFilterData({ ...filterData, status: e })
                      }
                      value={filterData.status}
                    />
                  </div>

                  <div className="col-2 preview-imagemargin pe-0 ">
                    <div className="d-flex gap-2">
                      <button
                        className="btn btn-primary "
                        onClick={(e) => {
                          filterHandler("searchBox", filterData);
                        }}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-primary "
                        onClick={(e) => {
                          filterHandler("clearSearch");
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  {error && <p className="error_feedback">{error}</p>}
                </div>
              </div>
            )}

            <div className="search__head">
              <div className="count">
                <EntriesCounter
                  value={filterData.perPage}
                  onChange={(e) => {
                    filterHandler("entries", e);
                  }}
                />
              </div>
              <div className="search">
                <Input
                  value={filterData.keyword}
                  type="text"
                  className="css-inp-esc2 search"
                  // icon={GenericSearchIcon}
                  placeholder={"Search Here"}
                  reverse
                  width="300px"
                  field="keyword"
                  showCloseBtn
                  onChange={(e) => !e && filterHandler("keyword", e)}
                  onSubmit={(e) => {
                    filterHandler("keyword", e);
                  }}
                  onIconClick={(e) => {
                    filterHandler("keyword", e);
                  }}
                />
              </div>
            </div>

            <Table
              titles={columns}
              content={content}
              onSort={(e) => filterHandler("sort", e)}
              page={filterData.page}
              pagesCount={pageData?.pages}
              pageStatus={pageData?.status}
              onPageChange={(e) => {
                filterHandler("page", e.selected);
              }}
              sortValue={{
                key: filterData.sortColumn,
                value: filterData.sortValue,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEnquiries;
