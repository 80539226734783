const CommonTitleCard = ({
  MainHeading,
  data,
  onChangeHandler,
  err,
  fieldHeading,
  fieldTagline,
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title border-style mb-3">{MainHeading}</h5>
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <label className="form-label">Main Heading<em>*</em></label>
              <input
                type="text"
                className="form-control"
                rows={2}
                placeholder="Type here"
                onChange={(e) =>
                  onChangeHandler(fieldHeading, e?.target?.value)
                }
                value={data?.[fieldHeading]}
              />
              <small id="error" className="error">
                {err?.[fieldHeading]}
              </small>
            </div>
          </div>

          <div className="col-12">
            <div className="mb-3">
              <label className="form-label">Tag Line<em>*</em></label>
              <input
                type="text"
                className="form-control"
                rows={2}
                placeholder="Type here"
                onChange={(e) =>
                  onChangeHandler(fieldTagline, e?.target?.value)
                }
                value={data?.[fieldTagline]}
              />
              <small id="error" className="error">
                {err?.[fieldTagline]}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonTitleCard;
