import Accordion from "react-bootstrap/Accordion";
import * as Tabs from "@radix-ui/react-tabs";
import moment from "moment";
import AttachmentContent from "../../../commonComponents/AttachmentContent/AttachmentContent";
import { IMAGE_BASE_URL } from "../../../constants/configuration";

const Preview = ({
  details,
  gross_total,
  deduction,
  amount_to_be_paid,
  questionnaire,
  itinerary,
  inputs,
}) => {
  return (
    <div className="card">
      <div className="card-body pt-0">
        <div className="row">
          <Tabs.Root className="TabsRoot" defaultValue="tab1">
            <Tabs.List className="TabsList" aria-label="Manage your account">
              <Tabs.Trigger className="TabsTrigger" value="tab1">
                Passenger Information
              </Tabs.Trigger>
              <Tabs.Trigger className="TabsTrigger" value="tab2">
                Customer Engagement
              </Tabs.Trigger>
              <Tabs.Trigger className="TabsTrigger" value="tab3">
                Travel Itinerary
              </Tabs.Trigger>
              <Tabs.Trigger className="TabsTrigger" value="tab4">
                Quotation
              </Tabs.Trigger>
              {/* <Tabs.Trigger className="TabsTrigger" value="tab5">
                                        Payments
                                    </Tabs.Trigger> */}
            </Tabs.List>
            <Tabs.Content className="TabsContent" value="tab1">
              <div className="tab-content">
                <div className="tab__sub_header">
                  <h2>Passenger Info</h2>
                </div>
                {inputs?.traveller_info?.map((ele, i) => {
                  return (
                    <>
                      <div className="tab__sub_header child">
                        <h2>
                          {ele?.is_main == "1"
                            ? "Main Traveller"
                            : ele?.is_adult == "1"
                            ? `Adult ${ele?.adult - 1}`
                            : `Child ${ele?.child}`}
                        </h2>
                      </div>
                      <div className="grid__items">
                        <div className="item">
                          <h4>First Name</h4>
                          <p>{ele?.first_name ?? "--"}</p>
                        </div>
                        <div className="item">
                          <h4>Middle Name</h4>
                          <p>
                            {ele?.middle_name ? ele?.middle_name?.length : "--"}
                          </p>
                        </div>
                        <div className="item">
                          <h4>Last Name</h4>
                          <p>{ele?.last_name ?? "--"}</p>
                        </div>
                        <div className="item">
                          <h4>Date of Birth</h4>
                          <p>
                            {ele?.dob
                              ? moment(ele?.dob).format("D MMM YYYY")
                              : "--"}
                          </p>
                        </div>
                        <div className="item">
                          <h4>Nationality</h4>
                          <p>{ele?.nationality?.name ?? "--"}</p>
                        </div>
                        <div className="item">
                          <h4>Email Address</h4>
                          <p>{ele?.email ?? "--"}</p>
                        </div>
                        <div className="item">
                          <h4>Phone Number</h4>
                          <p>{`${ele?.country_code?.dial_code} ${ele?.phone}`}</p>
                        </div>
                      </div>
                      <div className="tab__sub_header">
                        <h2>Travel Details</h2>
                      </div>
                      <div className="grid__items">
                        <div className="item">
                          <h4>Passport Number</h4>
                          <p>{ele?.passport_no}</p>
                        </div>
                        <div className="item">
                          <h4>Passport Expiry</h4>
                          <p>
                            {ele?.passport_expiry
                              ? moment(ele?.passport_expiry).format(
                                  "D MMM YYYY"
                                )
                              : "--"}
                          </p>
                        </div>
                        <div className="item">
                          <h4>Passport Copy</h4>
                          <div className="files">
                            <AttachmentContent
                              label={""}
                              files={ele?.passport}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </Tabs.Content>
            <Tabs.Content className="TabsContent" value="tab2">
              <div className="tab-content">
                {questionnaire?.map((ele, index) => {
                  return (
                    <>
                      <div className="tab__sub_header">
                        <h2>Question {index + 1}</h2>
                      </div>
                      <div className="grid__items">
                        <div className="item grid-4">
                          <h4>Question</h4>
                          <p>{ele?.title}</p>
                        </div>
                        <div className="item grid-4">
                          <h4>Comment</h4>
                          <p>{ele?.comment ?? "-"}</p>
                        </div>
                        <div className="item">
                          <h4>Status</h4>
                          <span
                            className={`toggle_status ${
                              ele.answer ? "" : "no"
                            }`}
                          >
                            {ele.answer ? "Yes" : "No"}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="grid__items mt-3">
                  <div className="item">
                    <h4>Payment Method</h4>
                    <p>{inputs.payment_method?.label}</p>
                  </div>
                  <div className="item">
                    <h4>Payment Type</h4>
                    <p>{inputs.payment_mode?.label}</p>
                  </div>
                </div>
              </div>
            </Tabs.Content>
            <Tabs.Content className="TabsContent" value="tab3">
              <Accordion>
                {itinerary?.map((ele, index) => {
                  return (
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Day {index + 1}</Accordion.Header>
                      <Accordion.Body>
                        <div className="tab-content">
                          <div className="grid__items">
                            <div className="item grid-4">
                              <h4>Day Title</h4>
                              <p>{ele?.title}</p>
                            </div>
                            <div className="item grid-4">
                              <h4>Day Description</h4>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: ele?.description,
                                }}
                              ></p>
                            </div>
                            <div className="item grid-4">
                              <h4>Activities</h4>
                              <ul className="activities">
                                {ele?.activity?.map((el) => (
                                  <li>{el?.title}</li>
                                ))}
                              </ul>
                            </div>
                            <div className="item grid-4">
                              <h4>Attractions</h4>
                              <ul className="activities">
                                {ele?.attraction?.map((el) => (
                                  <li>{el?.title}</li>
                                ))}
                              </ul>
                            </div>
                            <div className="item">
                              <h4>Map Image</h4>
                              <div className="files">
                                <AttachmentContent
                                  label={""}
                                  files={ele?.map_image}
                                />
                              </div>
                            </div>
                            <div className="item">
                              <h4>Mobile App image</h4>
                              <AttachmentContent
                                label={""}
                                files={ele?.mobile_map_image}
                              />
                            </div>
                          </div>
                          <div className="grid__items mt-5">
                            {ele?.attachments?.length ? (
                              <>
                                {ele?.attachments?.map((el, i) => {
                                  return (
                                    <div>
                                      <div className="item grid-2">
                                        <h4>Document type</h4>
                                        <p>{el?.type?.label}</p>
                                      </div>
                                      <div className="item grid-4">
                                        <h4>title</h4>
                                        <p>{el?.doc_title}</p>
                                      </div>
                                      <div className="item grid-4">
                                        <h4>Description</h4>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: el?.doc_description,
                                          }}
                                        ></p>
                                      </div>
                                      <div className="item">
                                        <h4>Uploaded Document</h4>
                                        <div className="files">
                                          <AttachmentContent
                                            label={""}
                                            files={el?.item}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Tabs.Content>
            <Tabs.Content className="TabsContent" value="tab4">
              <div className="tab-content">
                <div className="quotation__preview">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Final Quote</th>
                        <th scope="col">Unit Cost ( USD )</th>
                        <th scope="col">Units</th>
                        <th scope="col">Total ( USD )</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th colSpan={4}>Package Cost</th>
                      </tr>
                      {details?.quotation?.map((ele, i) => {
                        return (
                          <tr>
                            <td>{ele?.label}</td>
                            <td>{ele?.unit_cost}</td>
                            <td>{ele?.units}</td>
                            <td>{ele?.total_cost}</td>
                          </tr>
                        );
                      })}

                      <tr>
                        <th colSpan={4}>Airfare Cost</th>
                      </tr>
                      {details?.airfare_quotation?.map((ele, i) => {
                        return (
                          <tr>
                            <td>{ele?.label}</td>
                            <td>{ele?.unit_cost}</td>
                            <td>{ele?.units}</td>
                            <td>{ele?.total_cost}</td>
                          </tr>
                        );
                      })}

                      <tr>
                        <th colSpan={3}>Gross Total</th>
                        <th>{gross_total}</th>
                      </tr>
                      <tr>
                        <th colSpan={3}>Payment Completed</th>
                        <th>{Number(details?.amount_paid ?? 0)?.toFixed(2)}</th>
                      </tr>
                      <tr>
                        <th colSpan={3}>Discounts</th>
                        <th>{details?.total_discount ?? "--"}</th>
                      </tr>
                      <tr>
                        <th colSpan={3}>Deductions</th>
                        <th>{deduction ?? "--"}</th>
                      </tr>
                      <tr>
                        <th colSpan={3}>Total To Be Paid</th>
                        <th>{amount_to_be_paid}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Tabs.Content>
          </Tabs.Root>
        </div>
      </div>
    </div>
  );
};
export default Preview;
