import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { AiOutlineClose, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import MediaUploader from "../../components/media-uploader/MediaUploader";
import IconUploader from "../../components/media-uploader/IconUploader";
import CommonTitleCard from "../../components/CommonTitleCard";
import Seo from "../../components/Seo";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttractionsWithoutPagination,
  getHomeDetail,
  getReviewsWithoutPagination,
  insertHome,
  updateHome,
  uploadFile,
} from "../../redux/actionCreator";
import { Modal } from "react-bootstrap";
import {
  attraction_path,
  detail_home_loading,
  full_attraction_list,
  full_review_list,
  home_details,
  home_path,
  review_path,
  update_home_loading,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

const Home = () => {
  const dispatch = useDispatch();

  const allAttractionList = useSelector(full_attraction_list);
  const allReviewList = useSelector(full_review_list);
  const reviewPath = useSelector(review_path);
  const attractionPath = useSelector(attraction_path);
  const submitLoading = useSelector(update_home_loading);
  const loading = useSelector(detail_home_loading);
  const details = useSelector(home_details);
  const path = useSelector(home_path);

  const [data, setData] = useState({
    banner_text: "",
    banner_video: "",
    banner_video_alt: "",
    about_title: "",
    about_tagline: "",
    about_image: "",
    about_image_alt: "",
    tagline_title: "",
    tagline_description: "",
    self_drive_title: "",
    self_drive_description: "",
    self_drive_image: "",
    self_drive_image_alt: "",
    package_title: "",
    package_tagline: "",
    attraction_title: "",
    attraction_tagline: "",
    why_choose_title: "",
    why_choose_tagline: "",
    review_title: "",
    review_tagline: "",
    curated_title: "",
    curated_description: "",
    curated_image: "",
    curated_image_alt: "",
    blog_title: "",
    blog_tagline: "",
    features: [
      { name: "", icon: "", icon_alt: "" },
      { name: "", icon: "", icon_alt: "" },
      { name: "", icon: "", icon_alt: "" },
      { name: "", icon: "", icon_alt: "" },
      { name: "", icon: "", icon_alt: "" },
      { name: "", icon: "", icon_alt: "" },
    ],
    attractions: [{}, {}, {}, {}, {}, {}, {}, {}],
    list: [],
    reviews: [],
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
    list_index: "",
  });
  const [err, setErr] = useState({});
  const [trackId, setTrackId] = useState("");
  const [openListModal, setOpenListModal] = useState(false);
  const [openAttractionModal, setOpenAttractionModal] = useState(false);
  const [attractionIndex, setAttractionIndex] = useState("");
  const [selectedAttractionOption, setSelectedAttractionOption] = useState("");
  const [selectedReviewOption, setSelectedReviewOption] = useState("");
  const [openReviewModal, setOpenReviewModal] = useState(false);

  useEffect(() => {
    dispatch(getAttractionsWithoutPagination());
    dispatch(getReviewsWithoutPagination());
    dispatch(getHomeDetail());
  }, []);

  useEffect(() => {
    if (details?._id) {
      let result = {
        banner_text: details?.banner_text,
        banner_video: details?.banner_video
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.banner_video}`,
            file: null,
            image: details?.banner_video,
            type: "video",
          }
          : "",
        banner_video_alt: details?.banner_video_alt,
        about_title: details?.about_title,
        about_tagline: details?.about_title,
        about_image: details?.about_image
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.about_image}`,
            file: null,
            image: details?.about_image,
          }
          : "",
        about_image_alt: details?.about_image_alt,
        tagline_title: details?.tagline_title,
        tagline_description: details?.tagline_description,
        self_drive_title: details?.self_drive_title,
        self_drive_description: details?.self_drive_description,
        self_drive_image: details?.self_drive_image
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.self_drive_image}`,
            file: null,
            image: details?.self_drive_image,
          }
          : "",
        self_drive_image_alt: details?.self_drive_image_alt,
        package_title: details?.package_title,
        package_tagline: details?.package_tagline,
        attraction_title: details?.attraction_title,
        attraction_tagline: details?.attraction_tagline,
        why_choose_title: details?.why_choose_title,
        why_choose_tagline: details?.why_choose_tagline,
        review_title: details?.review_title,
        review_tagline: details?.review_tagline,
        curated_title: details?.curated_title,
        curated_description: details?.curated_description,
        curated_image: details?.curated_image
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.curated_image}`,
            file: null,
            image: details?.curated_image,
          }
          : "",
        curated_image_alt: details?.curated_image_alt,
        blog_title: details?.blog_title,
        blog_tagline: details?.blog_tagline,
        meta_title: details?.meta_title,
        meta_keyword: details?.meta_keyword,
        meta_description: details?.meta_description,
      };
      if (details?.features?.length) {
        let arr = details?.features?.map((ele, i) => {
          let obj = {
            icon: ele?.icon
              ? {
                preview: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
                file: null,
                image: ele?.icon,
              }
              : "",
            name: `${ele?.name}`,
            icon_alt: `${ele?.icon_alt}`,
          };
          return obj;
        });
        result = {
          ...result,
          features: [...arr],
        };
      }
      if (details?.list?.length) {
        let arr1 = details?.list?.map((ele, i) => {
          let obj = {
            icon: ele?.icon
              ? {
                preview: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
                file: null,
                image: ele?.icon,
              }
              : "",
            name: `${ele?.name}`,
            icon_alt: `${ele?.icon_alt}`,
            description: `${ele?.description}`,
          };
          return obj;
        });
        result = {
          ...result,
          list: [...arr1],
        };
      }
      if (details?.attractions?.length && allAttractionList?.length) {
        let arr2 = allAttractionList?.filter((obj) =>
          details?.attractions?.includes(obj._id)
        );
        arr2 = arr2.concat(Array(8 - arr2.length).fill({})).slice(0, 8);
        result = {
          ...result,
          attractions: [...arr2],
        };
      }
      if (details?.reviews?.length && allReviewList?.length) {
        let arr3 = allReviewList?.filter((obj) =>
          details?.reviews?.includes(obj._id)
        );
        result = {
          ...result,
          reviews: [...arr3],
        };
      }
      setData({ ...result });
    }
  }, [details, allAttractionList, allReviewList]);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const removeImage = (type) => {
    setData((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const singleFileSelectedHandler = (
    event,
    field,
    setFunction,
    type,
    index
  ) => {
    const file = event.target.files?.[0];
    setErr({
      ...err,
      [field]: "",
    });
    let formData = new FormData();
    if (trackId?.length == 0) {
      let track_id = Math.floor(1000 + Math.random() * 9000).toString();
      setTrackId(track_id);
      formData.append("track_id", track_id);
    } else {
      formData.append("track_id", trackId);
    }
    formData.append(`file`, file);
    formData.append(`path`, "home");
    dispatch(
      uploadFile(formData, (res) => {
        setErr({
          ...err,
          [field]: "",
        });
        setFunction(field, file, res, type, index);
      })
    );
  };

  const setSingleImageState = (field, file, res, type) => {
    setData((s) => ({
      ...s,
      [field]: {
        file: file,
        image: res?.data[0],
        preview: URL.createObjectURL(file),
        type: type,
      },
    }));
  };

  const setFeatureImageState = (field, file, res, type, index) => {
    let dataList = { ...data };
    dataList.features[index][field] = {
      file: file,
      image: res?.data[0],
      preview: URL.createObjectURL(file),
      type: type,
    };
    setData(dataList);
    setErr({
      ...err,
      [`features_${field}_${index}`]: "",
    });
  };

  const removeFeatureImage = (field, index) => {
    let dataList = { ...data };
    dataList.features[index][field] = "";
    setData(dataList);
    setErr({
      ...err,
      [`features_${field}_${index}`]: "",
    });
  };

  const handleFeaturesChange = (index, value, field) => {
    let dataList = { ...data };
    dataList.features[index][field] = value;
    setData(dataList);
    setErr({
      ...err,
      [`features_${field}_${index}`]: "",
    });
  };

  const handleCloseListModal = (dataList) => {
    setOpenListModal(false);
    setData({
      ...dataList,
      name: "",
      description: "",
      icon: "",
      icon_alt: "",
      list_edit: false,
      list_index: "",
    });
  };

  const listValidate = () => {
    let temp = {};
    if (!data?.name?.trim()?.length) {
      temp = {
        ...temp,
        name: "Please enter name",
      };
    }
    if (!data?.description?.trim()?.length) {
      temp = {
        ...temp,
        description: "Please enter description",
      };
    }
    if (data?.icon == "") {
      temp = {
        ...temp,
        icon: "Please upload icon",
      };
    }
    // if (!data?.icon_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     icon_alt: "Please enter icon alt text",
    //   };
    // }

    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const handleDataList = () => {
    if (listValidate()) {
      let dataList = { ...data };
      let addObj = {
        name: data?.name,
        description: data?.description,
        icon: data?.icon,
        icon_alt: data?.icon_alt,
      };
      let addArr = [];
      if (data?.list_edit) {
        let list = [...dataList?.list];
        list[data.list_index] = addObj;
        addArr = [...list];
      } else {
        addArr = [...dataList?.list, addObj];
      }
      dataList = {
        ...dataList,
        list: addArr,
        list_edit: false,
        list_index: "",
      };
      setData(dataList);
      handleCloseListModal(dataList);
    }
  };

  const handleListEdit = (ele, index) => {
    setData({
      ...data,
      name: ele?.name,
      description: ele?.description,
      icon: ele?.icon,
      icon_alt: ele?.icon_alt,
      list_edit: true,
      list_index: index,
    });
    setOpenListModal(true);
  };

  const removeListData = (field, index) => {
    let temp = [...data?.[field]];
    if (temp?.length) {
      temp = temp?.filter((ele, i) => i !== index);
      setData({
        ...data,
        [field]: temp,
      });
    }
  };

  const handleCloseAttraction = () => {
    setAttractionIndex("");
    setSelectedAttractionOption("");
    setOpenAttractionModal(false);
  };

  const handleOpenAttraction = (index) => {
    setAttractionIndex(index);
    setOpenAttractionModal(true);
  };

  const handleDeleteAttraction = (index) => {
    const updatedAttractions = [...data.attractions];
    updatedAttractions.splice(index, 1);
    setData({ ...data, attractions: updatedAttractions });
  };



  const handleAddAttraction = () => {
    if (selectedAttractionOption == "") {
      setErr({
        ...err,
        selectedAttractionOption: "Please Select attraction",
      });
    } else {
      if (
        data?.attractions?.some(
          (ele) => ele._id == selectedAttractionOption?._id
        )
      ) {
        setErr({
          ...err,
          selectedAttractionOption: "This attraction is already selected",
        });
      } else {
        let dataList = { ...data };
        dataList.attractions[attractionIndex] = selectedAttractionOption;
        setData(dataList);
        setErr({
          ...err,
          [`attractions`]: "",
        });
        handleCloseAttraction();
      }
    }
  };

  const handleOpenReviews = () => setOpenReviewModal(true);

  const handleCloseReview = () => {
    setOpenReviewModal(false);
    setSelectedReviewOption("");
  };

  const handleAddReview = () => {
    if (selectedReviewOption == "") {
      setErr({
        ...err,
        selectedReviewOption: "Please Select review",
      });
    } else {
      if (data?.reviews?.some((ele) => ele._id == selectedReviewOption?._id)) {
        setErr({
          ...err,
          selectedReviewOption: "This review is already selected",
        });
      } else {
        let dataList = { ...data };
        dataList?.reviews?.push(selectedReviewOption);
        setData(dataList);
        setErr({
          ...err,
          [`selectedReviewOption`]: "",
        });
        handleCloseReview();
      }
    }
  };

  const validate = () => {
    let temp = {};
    if (!data?.banner_text?.trim()?.length) {
      temp = {
        ...temp,
        banner_text: "Please enter banner text",
      };
    }
    if (data?.banner_video == "") {
      temp = {
        ...temp,
        banner_video: "Please upload banner image",
      };
    }
    // if (!data?.banner_video_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     banner_video_alt: "Please enter banner alt",
    //   };
    // }
    if (!data?.about_title?.trim()?.length) {
      temp = {
        ...temp,
        about_title: "Please enter about title",
      };
    }
    if (!data?.about_tagline?.trim()?.length) {
      temp = {
        ...temp,
        about_tagline: "Please enter about description",
      };
    }
    // if (!data?.about_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     about_image_alt: "Please enter about image alt",
    //   };
    // }
    if (data?.about_image == "") {
      temp = {
        ...temp,
        about_image: "Please upload about image",
      };
    }
    if (!data?.tagline_title?.trim()?.length) {
      temp = {
        ...temp,
        tagline_title: "Please enter tagline title",
      };
    }
    if (!data?.tagline_description?.trim()?.length) {
      temp = {
        ...temp,
        tagline_description: "Please enter tagline description",
      };
    }
    if (!data?.self_drive_title?.trim()?.length) {
      temp = {
        ...temp,
        self_drive_title: "Please enter self drive title",
      };
    }
    if (!data?.self_drive_description?.trim()?.length) {
      temp = {
        ...temp,
        self_drive_description: "Please enter self drive description",
      };
    }
    if (data?.self_drive_image == "") {
      temp = {
        ...temp,
        self_drive_image: "Please upload self drive image",
      };
    }
    // if (!data?.self_drive_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     self_drive_image_alt: "Please enter self drive alt text",
    //   };
    // }
    if (!data?.package_title?.trim()?.length) {
      temp = {
        ...temp,
        package_title: "Please enter package title",
      };
    }
    if (!data?.package_tagline?.trim()?.length) {
      temp = {
        ...temp,
        package_tagline: "Please enter package tagline",
      };
    }
    if (!data?.attraction_title?.trim()?.length) {
      temp = {
        ...temp,
        attraction_title: "Please enter attraction title",
      };
    }
    if (!data?.attraction_tagline?.trim()?.length) {
      temp = {
        ...temp,
        attraction_tagline: "Please enter attraction tagline",
      };
    }
    if (!data?.why_choose_title?.trim()?.length) {
      temp = {
        ...temp,
        why_choose_title: "Please enter why choose title",
      };
    }
    if (!data?.why_choose_tagline?.trim()?.length) {
      temp = {
        ...temp,
        why_choose_tagline: "Please enter why choose tagline",
      };
    }
    if (!data?.review_title?.trim()?.length) {
      temp = {
        ...temp,
        review_title: "Please enter review title",
      };
    }
    if (!data?.review_tagline?.trim()?.length) {
      temp = {
        ...temp,
        review_tagline: "Please enter review tagline",
      };
    }
    if (!data?.curated_title?.trim()?.length) {
      temp = {
        ...temp,
        curated_title: "Please enter curated title",
      };
    }
    if (!data?.curated_description?.trim()?.length) {
      temp = {
        ...temp,
        curated_description: "Please enter curated description",
      };
    }
    if (data?.curated_image == "") {
      temp = {
        ...temp,
        curated_image: "Please upload curated image",
      };
    }
    // if (!data?.curated_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     curated_image_alt: "Please enter curated alt text",
    //   };
    // }
    if (!data?.blog_title?.trim()?.length) {
      temp = {
        ...temp,
        blog_title: "Please enter blog title",
      };
    }
    if (!data?.blog_tagline?.trim()?.length) {
      temp = {
        ...temp,
        blog_tagline: "Please enter blog taglin",
      };
    }
    data?.features?.forEach((ele, i) => {
      if (!ele.name?.trim()?.length) {
        temp[`features_name_${i}`] = "Please enter name";
      }
      // if (!ele.icon_alt?.trim()?.length) {
      //   temp[`features_icon_alt_${i}`] = "Please enter alt text";
      // }
      if (ele.icon == "") {
        temp[`features_icon_${i}`] = "Please upload icon";
      }
    });
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        banner_text: data?.banner_text?.trim(),
        banner_video: data?.banner_video?.image,
        banner_video_alt: data?.banner_video_alt?.trim(),
        about_title: data?.about_title?.trim(),
        about_tagline: data?.about_tagline,
        about_image: data?.about_image?.image,
        about_image_alt: data?.about_image_alt?.trim(),
        tagline_title: data?.tagline_title?.trim(),
        tagline_description: data?.tagline_description?.trim(),
        self_drive_title: data?.self_drive_title?.trim(),
        self_drive_description: data?.self_drive_description?.trim(),
        self_drive_image: data?.self_drive_image?.image,
        self_drive_image_alt: data?.self_drive_image_alt?.trim(),
        package_title: data?.package_title?.trim(),
        package_tagline: data?.package_tagline?.trim(),
        attraction_title: data?.attraction_title?.trim(),
        attraction_tagline: data?.attraction_tagline?.trim(),
        why_choose_title: data?.why_choose_title?.trim(),
        why_choose_tagline: data?.why_choose_tagline?.trim(),
        review_title: data?.review_title?.trim(),
        review_tagline: data?.review_tagline?.trim(),
        curated_title: data?.curated_title?.trim(),
        curated_description: data?.curated_description?.trim(),
        curated_image: data?.curated_image?.image,
        curated_image_alt: data?.curated_image_alt?.trim(),
        blog_title: data?.blog_title?.trim(),
        blog_tagline: data?.blog_tagline?.trim(),
        list: data?.list?.map((item, i) => {
          return {
            [`icon`]: item?.icon?.image,
            [`name`]: item?.name?.trim(),
            [`description`]: item?.description?.trim(),
            [`icon_alt`]: item?.icon_alt?.trim(),
          };
        }),
        features: data?.features?.map((item, i) => {
          return {
            [`icon`]: item?.icon?.image,
            [`name`]: item?.name?.trim(),
            [`icon_alt`]: item?.icon_alt?.trim(),
          };
        }),
        reviews: data?.reviews?.map((item, i) => item?._id),
        attractions: data?.attractions?.map((item, i) => item?._id),
        track_id: trackId,
        meta_title: data?.meta_title?.trim(),
        meta_keyword: data?.meta_keyword?.trim(),
        meta_description: data?.meta_description?.trim(),
        path : "home"
      };
      console.log(raw);
      if (details?._id) {
        raw = {
          ...raw,
          id: details?._id,
        };
        dispatch(
          updateHome(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertHome(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      banner_text: "",
      banner_video: "",
      banner_video_alt: "",
      about_title: "",
      about_tagline: "",
      about_image: "",
      about_image_alt: "",
      tagline_title: "",
      tagline_description: "",
      self_drive_title: "",
      self_drive_description: "",
      self_drive_image: "",
      self_drive_image_alt: "",
      package_title: "",
      package_tagline: "",
      attraction_title: "",
      attraction_tagline: "",
      why_choose_title: "",
      why_choose_tagline: "",
      review_title: "",
      review_tagline: "",
      curated_title: "",
      curated_description: "",
      curated_image: "",
      curated_image_alt: "",
      blog_title: "",
      blog_tagline: "",
      features: [
        { name: "", icon: "", icon_alt: "" },
        { name: "", icon: "", icon_alt: "" },
        { name: "", icon: "", icon_alt: "" },
        { name: "", icon: "", icon_alt: "" },
        { name: "", icon: "", icon_alt: "" },
        { name: "", icon: "", icon_alt: "" },
      ],
      attractions: [{}, {}, {}, {}, {}, {}, {}, {}],
      list: [],
      reviews: [],
      meta_title: "",
      meta_keyword: "",
      meta_description: "",
    });
    dispatch(getHomeDetail());
  };

  return (
    /* Banner section*/
    loading ? (<LoadingSpinner height={"200px"} />) : (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Banner</h5>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">Intro Text <em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("banner_text", e?.target?.value)
                      }
                      value={data?.banner_text}
                    />
                    <small id="error" className="error">
                      {err?.banner_text}
                    </small>
                  </div>
                </div>

                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">
                      Banner Video (Recommended Size:650*730)<em>*</em>
                    </label>
                    <MediaUploader
                      media={data.banner_video}
                      onUpload={(e) =>
                        singleFileSelectedHandler(
                          e,
                          "banner_video",
                          setSingleImageState,
                          "video"
                        )
                      }
                      onRemove={() => removeImage("banner_video")}
                      uploadType={["mp4", "mov", "avi"]}
                    />
                    <small id="error" className="error">
                      {err?.banner_video}
                    </small>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("banner_video_alt", e?.target?.value)
                      }
                      value={data?.banner_video_alt}
                    />
                    <small id="error" className="error">
                      {err?.banner_video_alt}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Feature icons sections */}
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Feature List</h5>
              <div className="row">
                {data?.features?.map((ele, index) => (
                  <React.Fragment key={index}>
                    <div className="col-12 col-md-2">
                      <div className="mb-3"><em>*</em>
                        <IconUploader
                          media={ele?.icon}
                          onUpload={(e) =>
                            singleFileSelectedHandler(
                              e,
                              "icon",
                              setFeatureImageState,
                              "image",
                              index
                            )
                          }
                          onRemove={() => removeFeatureImage("icon", index)}
                          index={index}
                        />
                        <small id="error" className="error">
                          {err[`features_icon_${index}`]}
                        </small>
                      </div>
                    </div>
                    <div className="col-12 col-md-10">
                      <div className="">
                        <label className="form-label">
                          {index + 1}.Feature Name <em>*</em>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type here"
                          onChange={(e) =>
                            handleFeaturesChange(index, e?.target?.value, "name")
                          }
                          value={ele?.name}
                        />
                        <small id="error" className="error">
                          {err[`features_name_${index}`]}
                        </small>
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Alt Text</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type here"
                          onChange={(e) =>
                            handleFeaturesChange(
                              index,
                              e?.target?.value,
                              "icon_alt"
                            )
                          }
                          value={ele?.icon_alt}
                        />
                        <small id="error" className="error">
                          {err[`features_icon_alt_${index}`]}
                        </small>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          {/* About section */}

          <div className="card">
            <div className="card-body ">
              <h5 className="card-title border-style mb-3">About</h5>
              <div className="row">
                <div className="col-4">
                  <label className="form-label">
                    About Image (Recommended Size:650*730)<em>*</em>
                  </label>
                  <MediaUploader
                    media={data.about_image}
                    onUpload={(e) =>
                      singleFileSelectedHandler(
                        e,
                        "about_image",
                        setSingleImageState,
                        "image"
                      )
                    }
                    onRemove={() => removeImage("about_image")}
                  />
                  <small id="error" className="error">
                    {err?.about_image}
                  </small>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("about_image_alt", e?.target?.value)
                      }
                      value={data?.about_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.about_image_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="mb-3">
                    <label className="form-label">Main Heading <em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("about_title", e?.target?.value)
                      }
                      value={data?.about_title}
                    />
                    <small id="error" className="error">
                      {err?.about_title}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Short description <em>*</em></label>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("about_tagline", e?.target?.value)
                      }
                      value={data?.about_tagline}
                    ></textarea>
                    <small id="error" className="error">
                      {err?.about_tagline}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* tagline section */}

          <CommonTitleCard
            MainHeading="Tagline"
            data={data}
            onChangeHandler={onChangeHandler}
            err={err}
            fieldHeading={"tagline_title"}
            fieldTagline={"tagline_description"}
          />

          {/* self drive tours */}

          <div className="card">
            <div className="card-body ">
              <h5 className="card-title border-style mb-3">Self Drive Tours </h5>
              <div className="row">
                <div className="col-12 ">
                  <div className="mb-3">
                    <label className="form-label">Main Heading<em>*</em> </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("self_drive_title", e?.target?.value)
                      }
                      value={data?.self_drive_title}
                    />
                    <small id="error" className="error">
                      {err?.self_drive_title}
                    </small>
                  </div>
                </div>

                <div className="col-12 ">
                  <div className="mb-3">
                    <label className="form-label">Short Description<em>*</em></label>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler(
                          "self_drive_description",
                          e?.target?.value
                        )
                      }
                      value={data?.self_drive_description}
                    ></textarea>
                    <small id="error" className="error">
                      {err?.self_drive_description}
                    </small>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Background Image (Recommended Size:650*730)<em>*</em>
                    </label>
                    <MediaUploader
                      media={data.self_drive_image}
                      onUpload={(e) =>
                        singleFileSelectedHandler(
                          e,
                          "self_drive_image",
                          setSingleImageState,
                          "image"
                        )
                      }
                      onRemove={() => removeImage("self_drive_image")}
                    />
                    <small id="error" className="error">
                      {err?.self_drive_image}
                    </small>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("self_drive_image_alt", e?.target?.value)
                      }
                      value={data?.self_drive_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.self_drive_image_alt}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* popular packages  */}

          <CommonTitleCard
            MainHeading="Popular Packages"
            data={data}
            onChangeHandler={onChangeHandler}
            err={err}
            fieldHeading={"package_title"}
            fieldTagline={"package_tagline"}
          />

          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">{"Attractions"}</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Main Heading<em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("attraction_title", e?.target?.value)
                      }
                      value={data?.attraction_title}
                    />
                    <small id="error" className="error">
                      {err?.attraction_title}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Tag Line<em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("attraction_tagline", e?.target?.value)
                      }
                      value={data?.attraction_tagline}
                    />
                    <small id="error" className="error">
                      {err?.attraction_tagline}
                    </small>
                  </div>
                </div>
              </div>
              <label className="form-label mb-3">Adding Attractions<em>*</em></label>
              <div className="row row-cols-6">
              {
  Array.from({ length: 8 }).map((_, index) => (
    <div className="col mb-3" key={index}>
      <div className="uploader-attractions mb-3" style={{ position: "relative" }}>
        {data?.attractions?.[index]?._id ? (
          <>
            <img
              src={`${IMAGE_BASE_URL}${attractionPath}/${data.attractions[index]?.image}`}
              alt=""
              height={"90px"}
              width={"152px"}
              style={{ display: "block" }}
            />
            <p className="text-center">{data.attractions[index]?.title}</p>
            <AiOutlineDelete
              size={20}
              className="delete-icon"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                cursor: "pointer",
                color: "white",
                padding: "3px",
                background: "red",
                borderRadius: "10px",
              }}
              onClick={() => handleDeleteAttraction(index)}
            />
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "120px" }}
          >
            <AiOutlinePlus
              size={30}
              className="plus-icon"
              onClick={() => handleOpenAttraction(index)}
            />
          </div>
        )}

        <div className="index_number">
          <p>{index + 1}</p>
        </div>
      </div>
    </div>
  ))}


              </div>
            </div>
          </div>

          {/* why choose us     */}

          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Why Choose Us</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Main Heading<em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("why_choose_title", e?.target?.value)
                      }
                      value={data?.why_choose_title}
                    />
                    <small id="error" className="error">
                      {err?.why_choose_title}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Tag Line<em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("why_choose_tagline", e?.target?.value)
                      }
                      value={data?.why_choose_tagline}
                    />
                    <small id="error" className="error">
                      {err?.why_choose_tagline}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">List<em>*</em></label>
                    <div className="row">
                      {data?.list?.length > 0 && (
                        <>
                          {data?.list?.map((item, index) => (
                            <div key={index} className="col-12 col-md-3 mb-3">
                              <div className="card card_layout">
                                <div className="card-body">
                                  <h6
                                    onClick={() => {
                                      handleListEdit(item, index);
                                    }}
                                  >
                                    {item.name}
                                  </h6>
                                  <AiOutlineClose
                                    size={20}
                                    className="remove-icon"
                                    onClick={() => removeListData("list", index)}
                                  />
                                  {/* <MdModeEditOutline 
                                size={20}
                                className="edit-icon" 
                                onClick={editListData(item._id)}
                                /> */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      <div className="col-12 col-md-3">
                        <div className="add_list mb-3">
                          <label className="upload-label">
                            <AiOutlinePlus
                              size={40}
                              className="plus-icon"
                              onClick={() => setOpenListModal(true)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Review section */}
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Reviews</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Main Heading<em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("review_title", e?.target?.value)
                      }
                      value={data?.review_title}
                    />
                    <small id="error" className="error">
                      {err?.review_title}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Tag Line<em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("review_tagline", e?.target?.value)
                      }
                      value={data?.review_tagline}
                    />
                    <small id="error" className="error">
                      {err?.review_tagline}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Add Reviews<em>*</em></label>
                    <div className="row row-cols-4 mt-3">
                      {data?.reviews?.length > 0 && (
                        <>
                          {data?.reviews?.map((item, index) => (
                            <div key={index} className="col mb-3 pe-0">
                              <div className="card card_layout">
                                <div className="card-body">
                                  <h6>{item.review}</h6>
                                  <AiOutlineClose
                                    size={30}
                                    className="remove-icon"
                                    onClick={() =>
                                      removeListData("reviews", index)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>

                    <div className="col-12 col-md-3">
                      <div className="add_list mb-3">
                        <label className="upload-label">
                          <AiOutlinePlus
                            size={40}
                            className="plus-icon"
                            onClick={handleOpenReviews}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* curated section */}
          <div className="card">
            <div className="card-body ">
              <h5 className="card-title border-style mb-3">Curated</h5>
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="form-label">Curated Image<em>*</em></label>
                    <MediaUploader
                      media={data.curated_image}
                      onUpload={(e) =>
                        singleFileSelectedHandler(
                          e,
                          "curated_image",
                          setSingleImageState,
                          "image"
                        )
                      }
                      onRemove={() => removeImage("curated_image")}
                    />
                    <small id="error" className="error">
                      {err?.curated_image}
                    </small>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("curated_image_alt", e?.target?.value)
                      }
                      value={data?.curated_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.curated_image_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="mb-3">
                    <label className="form-label">Main Heading<em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("curated_title", e?.target?.value)
                      }
                      value={data?.curated_title}
                    />
                    <small id="error" className="error">
                      {err?.curated_title}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Short description<em>*</em></label>
                    <textarea
                      rows={2}
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("curated_description", e?.target?.value)
                      }
                      value={data?.curated_description}
                    ></textarea>
                    <small id="error" className="error">
                      {err?.curated_description}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* blog section */}
          <CommonTitleCard
            MainHeading="Blog"
            data={data}
            onChangeHandler={onChangeHandler}
            err={err}
            fieldHeading={"blog_title"}
            fieldTagline={"blog_tagline"}
          />
          <div className="card">
            <div className="card-body">
              <Seo data={data} onChangeHandler={onChangeHandler} />
            </div>
          </div>

          <div className="card">
            <div className="card-body ">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary me-3"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* attraction modal */}
        <Modal
          show={openAttractionModal}
          onHide={handleCloseAttraction}
          centered
          backdrop="static"
          keyboard={false}
          className="modal-open"
        >
          <Modal.Header closeButton className="modal_design">
            <Modal.Title>Choose Attractions</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mb-5 mt-4">
            <Select
              defaultValue={selectedAttractionOption}
              options={allAttractionList}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option._id}
              onChange={(e) => {
                setSelectedAttractionOption(e);
                setErr({
                  ...err,
                  selectedAttractionOption: "",
                });
              }}
            />
            <small id="error" className="error">
              {err?.selectedAttractionOption}
            </small>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleAddAttraction}>Add</Button>
            <Button onClick={handleCloseAttraction}>Cancel</Button>
          </Modal.Footer>
        </Modal>
        {/* Why choose us modal */}
        <Modal
          show={openListModal}
          onHide={() => handleCloseListModal(data)}
          centered
          backdrop="static"
          keyboard={false}
          className="modal-open"
        >
          <Modal.Header closeButton className="">
            <Modal.Title>{data?.list_edit ? "Update":"Add"} why choose us</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mt-4">
            <div className="col-12 ">
              <div className="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  onChange={(e) => onChangeHandler("name", e?.target?.value)}
                  value={data?.name}
                />
                <small id="error" className="error">
                  {err?.name}
                </small>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label">Short Description</label>
                <textarea
                  rows={4}
                  className="form-control"
                  placeholder="Type here"
                  onChange={(e) =>
                    onChangeHandler("description", e?.target?.value)
                  }
                  value={data?.description}
                ></textarea>
                <small id="error" className="error">
                  {err?.description}
                </small>
              </div>
            </div>
            <div className="col-12 ">
              <div className="mb-5">
                <label className="form-label">Icon</label>
                <IconUploader
                  media={data.icon}
                  onUpload={(e) =>
                    singleFileSelectedHandler(
                      e,
                      "icon",
                      setSingleImageState,
                      "image"
                    )
                  }
                  onRemove={() => removeImage("icon")}
                />
                <small id="error" className="error">
                  {err?.icon}
                </small>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder="Type alt text "
                  onChange={(e) => onChangeHandler("icon_alt", e?.target?.value)}
                  value={data?.icon_alt}
                />
                <small id="error" className="error">
                  {err?.icon_alt}
                </small>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleDataList}>
              {data?.list_edit ? "Update" : "Add"}
            </Button>
            <Button onClick={() => handleCloseListModal(data)}>Cancel</Button>
          </Modal.Footer>
        </Modal>
        {/* review modal */}
        <Modal
          show={openReviewModal}
          onHide={handleCloseReview}
          centered
          backdrop="static"
          keyboard={false}
          className="modal-open"
        >
          <Modal.Header closeButton className="modal_design">
            <Modal.Title>Choose Reviews</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mb-5 mt-4">
            <Select
              defaultValue={selectedReviewOption}
              options={allReviewList}
              getOptionLabel={(option) => option.review}
              getOptionValue={(option) => option._id}
              onChange={(e) => {
                setSelectedReviewOption(e);
                setErr({
                  ...err,
                  selectedReviewOption: "",
                });
              }}
            />
            <small id="error" className="error">
              {err?.selectedReviewOption}
            </small>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleAddReview}>Add</Button>
            <Button onClick={handleCloseReview}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      </div>
    ))
};

export default Home;
