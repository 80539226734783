import React, { useEffect, useState } from "react";
import useForm from "../../utils/Hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  detail_faq_loading,
  faq_details,
  update_faq_loading,
} from "../../redux/commonReducer";
import {
  getEssentialInfoDetail,
  insertEssentialInfo,
  updateEssentialInfo,
} from "../../redux/actionCreator";
import Input from "../../commonComponents/Input/Input";
import TextEditor from "../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../utils/editorConfig";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

export default function AddEssential() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector(detail_faq_loading);
  const submitLoading = useSelector(update_faq_loading);
  const details = useSelector(faq_details);
  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm()

  useEffect(() => {
    if (id) {
      dispatch(getEssentialInfoDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        question: details?.question,
        description: details?.description,
      };
    setInputs({ ...result });
    }
  }, [details]);

  const onSubmitHandler = async () => {
    let isValidated = await validate({
      returnErrors: true,
    });
    if (isValidated?.valid ) {
      finalSubmitHandler();
    }
  };
  const finalSubmitHandler = () => {
      let raw = {
        question: inputs?.question?.trim(),
        description: inputs?.description,
      };
      if (id) {
        raw = {
          ...raw,
          id: id,
        };
        dispatch(
          updateEssentialInfo(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertEssentialInfo(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
  };

  const onCancelHandler = () => {
      setInputs({});
      setErrors({});
    navigate("/manage-essential");
  };
  return (
    loading ?(<LoadingSpinner height={"200px"}/>):(
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">{id ? "Edit Essential Information" :"Add Essential Information"} </h5>
            <div className="row mt-3">
            <div className="col-12">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="Question"
                  placeholder="Type here"
                  required={{ message: "Please enter question" }}
                  field={"question"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-12 mb-3">
                <TextEditor
                  config={editorConfigEn}
                  label={"Answer"}
                  className={"mb-3"}
                  field={"description"}
                  // onBlur={(newContent) =>
                  //   onDetailsEditorChange(newContent, "description1")
                  // }
                  validation = {{maxChar:255}}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  required={{
                    message: "Please enter description",
                  }}
                  placeholder="Type Here"
                />
              </div>
             
              <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary m-2"
                onClick={() => onSubmitHandler()}
              >
                Submit
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
}
