import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import cryptoJs from "crypto-js";
import { v4 } from "uuid";
import "../pages/Login/Login.scss";
import { createPassword } from "../redux/actionCreator";
import { setpassword_loading } from "../redux/commonReducer";

export default function ResetPassword() {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const pwdInput = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const uuid = v4();

  const loading = useSelector(setpassword_loading);

  const handleSubmit = (e) => {
    const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/

  

    e.preventDefault();

    if (!password?.trim() || !confirmPassword?.trim()) {
      toast.error("Please enter both Password and Confirm Password", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (password !== confirmPassword) {
      toast.error("Your password and confirmation password do not match", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (!passwordRegex.test(password)) {
      toast.error(
        "Password must be at least 8 characters long, with uppercase, lowercase, number, and special character",
        {
          position: "bottom-center",
          autoClose: 3000,
        }
      );
    } else {
      const data = {
        password:  cryptoJs.AES.encrypt(password, uuid).toString() +
        "_" +
        window.btoa(uuid + "_" + token),
        token,
      };
      dispatch(
        createPassword(data, (msg) => {
          if (msg === true) {
            navigate("/login");
          }
        })
      );
    }
  };

  return (
    <div id="login-page">
      <div className="container">
        <div className="c-r row justify-content-md-center">
          <div className="col-md-12 col-lg-4">
            <div className="card login-box-container">
              <div className="card-body">
                <div className="authent-logo">
                  <img
                    width={100}
                    className="logo_img"
                    src="/assets/img/logo.png"
                    alt=""
                  />
                </div>
                <div className="authent-text">
                  <p>Welcome to SKYWARD</p>
                  <p>Please Create Password</p>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="password"
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                      />
                    <label for="floatingInput">Password</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="password"
                        className="form-control"
                        id="floatingPassword"
                        ref={pwdInput}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="confirm password"
                        autoComplete="off"
                      />
                    <label for="floatingPassword">Confirm Password</label>
                    </div>
                  </div>
                  <div className="d-grid">
                    <button
                      type="submit"
                      className="btn btn-info m-b-xs"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                        ></Spinner>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
