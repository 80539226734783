import React, { useEffect, useState } from "react";
import "./TextArea.scss";
import { v4 } from "uuid";

let validationObj = {
  minChar: { message: "", condition: "", length: "" },
  maxChar: { message: "", condition: "", length: "" },
  alphabetsOnly: { message: "", condition: "", more: "" },
  alphaNumeric: { message: "", condition: "", more: "" },
};

export default function TextArea({
  label,
  value,
  onChange,
  field,
  placeholder,
  inputSocket,
  className,
  error,
  required,
  validation = validationObj,
  condition,
  clearOnHide,
  disabled,
  dontTranslate,
}) {
  let uuid = v4();

  const [inValue, setInValue] = useState("");
  const [inLabel, setInLabel] = useState("");
  const [inPlaceholder, setInPlaceholder] = useState("");
  const [inError, setInError] = useState("");

  useEffect(() => {
    setInValue(() => {
      let res = "";
      if (typeof value === "object" && value?.[field] !== undefined)
        res = value?.[field];
      else if (typeof value === "object" && value?.[field] === undefined)
        res = "";
      else if (value !== undefined) res = value;
      return res;
    });
    placeholder && setInPlaceholder(placeholder);
    label && setInLabel(label);
    return () => {
      setInValue("");
      setInPlaceholder("");
      setInLabel("");
    };
  }, [value, placeholder, label]);

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field };
      if (required) obj.required = required;
      if (ifObjectExist(validation.minChar)) obj.minChar = validation.minChar;
      if (ifObjectExist(validation.maxChar)) obj.maxChar = validation.maxChar;
      if (ifObjectExist(validation.alphabetsOnly))
        obj.alphabetsOnly = validation.alphabetsOnly;
      if (ifObjectExist(validation.alphaNumeric))
        obj.alphaNumeric = validation.alphaNumeric;
      inputSocket && inputSocket(obj);
      if (Object.keys(obj).length > 1 && !inputSocket)
        setInError("Please connect 'formPlug' to 'inputSocket'");
    }
    return () => {
      inputSocket && inputSocket({ key: field, clearValidation: true });
    };
  }, [
    ...(required && typeof required === "object"
      ? Object.values(required)
      : [required]),
    condition,
  ]);

  useEffect(() => {
    return () => {
      condition &&
        clearOnHide &&
        inputSocket &&
        inputSocket({ key: field, clearValidation: true, clearValue: true });
    };
  }, [condition]);

  function ifObjectExist(obj = {}) {
    return Object.values(obj).join("").length > 0 || obj === true;
  }

  function onChangeHandler(e) {
    if (validation.maxChar > 0 && e.target.value.length > validation.maxChar) return
    setInValue(e.target.value);
    onChange && onChange(e.target.value, field);
  }

  if (!condition && condition !== undefined) return null;

  return (
    <div id="TextArea" className={className}>
      {inLabel && (
        <label htmlFor={uuid}>
          {inLabel}
          {(required?.condition !== undefined
            ? required?.condition
            : required) && <em>*</em>}
        </label>
      )}
      <textarea
        id={uuid}
        value={inValue}
        placeholder={inPlaceholder}
        onChange={onChangeHandler}
        disabled={disabled}
      />
      {(inError || (typeof error === "object" ? error?.[field] : error)) && (
        <small id="error" className="error">
          {inError ||
            (typeof error === "object" ? error?.[field] || "" : error)}
        </small>
      )}
    </div>
  );
}

{
  /* 

<TextArea
  className={'css-txt-esc1'}
  label={'Comment'}
  error={'error message'}
  placeholder={'Comment here'}
  value={''}
  onChange={(e) => { }}
/> 

*/
}
