import React, { useEffect, useState } from "react";
import EditIcon from "../../assets/icons/EDIT.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  detail_vehicle_costing_loading,
  update_vehicle_costing_loading,
  vehicle_costing_details,
} from "../../redux/commonReducer";
import {
  getVehicleCostDetail,
  insertVehicleCosting,
} from "../../redux/actionCreator";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

export default function Vehicle() {
  const dispatch = useDispatch();

  const submitLoading = useSelector(update_vehicle_costing_loading);
  const detailLoading = useSelector(detail_vehicle_costing_loading);
  const details = useSelector(vehicle_costing_details);

  const [data, setData] = useState([
    { month: "January", key: "january", value: "", edit: false },
    { month: "February", key: "february", value: "", edit: false },
    { month: "March", key: "march", value: "", edit: false },
    { month: "April", key: "april", value: "", edit: false },
    { month: "May", key: "may", value: "", edit: false },
    { month: "June", key: "june", value: "", edit: false },
    { month: "July", key: "july", value: "", edit: false },
    { month: "August", key: "august", value: "", edit: false },
    { month: "September", key: "september", value: "", edit: false },
    { month: "October", key: "october", value: "", edit: false },
    { month: "November", key: "november", value: "", edit: false },
    { month: "December", key: "december", value: "", edit: false },
  ]);
  const [err, setErr] = useState({});

  useEffect(() => {
    dispatch(getVehicleCostDetail());
  }, []);

  useEffect(() => {
    if (details) {
      if (details?.vehicle_costing?.length) {
        let arr = details?.vehicle_costing?.map((ele) => {
          return {
            month: Object.keys(ele)[0],
            key: Object.keys(ele)[0],
            value: Object.values(ele)[0],
            edit: false,
          };
        });
        console.log(arr);
        setData(arr);
      }
    }
  }, [details]);

  const handleValueChange = (index, value, field) => {
    console.log(value);
    console.log(`${field}_${index}`);
    const temp = [...data];
    temp[index][field] = value;
    setData(temp);
    setErr({
      ...err,
      [`${field}_${index}`]: "",
    });
  };

  const validate = () => {
    let temp = {};
    data?.forEach((ele, i) => {
      console.log(ele.value?.trim()?.length, ele.value);
      if (!ele.value?.trim()?.length) {
        temp[`value_${i}`] = "Please enter value";
      }
    });
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };
  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        vehicle_costing: data?.map((item, i) => {
          return {
            [`${item?.key}`]: item?.value,
          };
        }),
      };
      console.log(raw);
      dispatch(
        insertVehicleCosting(raw, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    dispatch(getVehicleCostDetail());
  };
  return (
    detailLoading ? (<LoadingSpinner height={"200px"}/>):(
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Vehicle costing </h5>
            <div className="row cost__table">
              <table className="table ">
                <thead className="table_head">
                  <tr>
                    <th scope="col">Sl.No</th>
                    <th scope="col">Month</th>
                    <th scope="col">Per Head Vehicle Cost (USD) <em>*</em></th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {data?.map((ele, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td
                        className="name"
                        style={{ textTransform: "capitalize" }}
                      >
                        {ele?.month}
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control typeNumber"
                          // onChange={(e) =>
                          //   handleValueChange(i, e?.target?.value, "value")
                          // }
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Allow only digits and restrict to 5 characters
                            if (/^\d{0,10}$/.test(inputValue)) {
                              handleValueChange(i, inputValue, "value");
                            }
                          }}
                          value={ele?.value}
                          placeholder="Type here"
                          disabled={!ele?.edit}
                          maxLength="10"
                        />{err && (
                          <small id="error" className="error_feedback">
                          {err[`value_${i}`]}
                        </small>
                        )}
                      </td>
                      <td className="actions">
                        <button
                         className="btn-sm p-0"
                          onClick={() => handleValueChange(i, true, "edit")}
                        >
                          <img
                            src={EditIcon}
                            alt=""
                            style={{
                              height: "38px",
                              width: "38px",
                              marginRight: "0px",
                            }}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary m-2"
                onClick={() => onSubmitHandler()}
              >
                Submit
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
}
