// dev
// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
//   ? process.env.REACT_APP_API_BASE_URL
//   : "https://dev-api.skywardxperia.com/admin/";

export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL
  ? process.env.REACT_APP_IMAGE_BASE_URL
  : "https://skywardxperia.b-cdn.net/uploads/";

// pre-production

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "https://preprod-api.skywardxperia.com/admin/";

// export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL
//   ? process.env.REACT_APP_IMAGE_BASE_URL
//   : "https://preprod-api.skywardxperia.com/";

// pre-production -  https://preprod-api.skywardxperia.com/
