import React, { useEffect } from "react";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import SupportedFormats from "../../../commonComponents/SupportedFormats/SupportedFormats";
import SortableList from "../../../commonComponents/sortFiles/SortableList";
import { scrollToTop } from "../../../utils/helpers";

export default function Gallery({
  inputs,
  setErrors,
  fileSelectedHandler,
  imageLoader,
  errors,
  getId,
  videoUrl,
  handleAddVideoUrl,
  setInputs,
  handleImageInputDelete,
  trackId,
  altChangeHandler,
}) {

  useEffect(() => {
    if (errors) {
      setErrors({
        ...errors,
      });
    }
    scrollToTop()
  }, []);

  return (
    <>
      <h5 className="package__header">Gallery</h5>
      <div className="row">
        <div className="row mb-5">
          <div className="col-12 col-md-6">
            <div className="d-flex gap-3">
              <FileUpload
                style={{
                  marginRight: 5,
                  marginTop: 15,
                }}
                className="css-atc-esc2 mt-0 mb-0"
                label="Add Gallery Images"
                noLabel
                buttonLabel="Choose File"
                accept={["jpg", "jpeg", "png", "svg"]}
                onChange={(e, isInvalid, message, isClosing) => {
                  setErrors((s) => ({
                    ...s,
                    gallery: "",
                  }));
                  fileSelectedHandler(
                    e,
                    isInvalid,
                    message,
                    isClosing,
                    "gallery"
                  );
                }}
                validFilesOnly
                field={"gallery"}
                loader={imageLoader}
                error={errors.gallery}
                id="gallery"
                maxFileSizeMB={100}
                multiple={true}
                files={inputs?.gallery}
                inputSocket={() => {}}
              />
            </div>
            <SupportedFormats
              formats={["jpg", "jpeg", "png", "svg"]}
              maxSize={100}
            />
          </div>
          <div className="col-12 col-md-4 mb-3">
            <label className="form-label">Add Youtube Videos</label>
            <input
              type="text"
              className="form-control"
              placeholder="Video URL"
              onChange={(e) => getId(e.target.value)}
              value={videoUrl ? videoUrl : ""}
            />
            <small id="error" className="error">
              {errors?.videoUrl}
            </small>
          </div>
          <div className="col-12 col-md-2 mb-3 margin-container">
            <button
              type="submit"
              className="btn btn-primary m-2"
              disabled={videoUrl ? false : true}
              onClick={handleAddVideoUrl}
            >
              Add
            </button>
          </div>
        </div>
        {inputs?.gallery?.length ? (
          <SortableList
            setInputs={setInputs}
            inputs={inputs}
            field="gallery"
            handleDelete={handleImageInputDelete}
            randomTrackID={trackId ?? ""}
            err={errors}
            altChangeHandler={altChangeHandler}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
