import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Seo from "../../components/Seo";
import Input from "../../commonComponents/Input/Input";
import useForm from "../../utils/Hooks/useForm";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import { AiOutlineClose } from "react-icons/ai";
import DatePick from "../../commonComponents/DatePicker/DatePick";
import TextEditor from "../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../utils/editorConfig";
import {
  getBlogDetail,
  getPackageList,
  insertBlog,
  updateBlog,
  uploadFile,
} from "../../redux/actionCreator";

import SupportedFormats from "../../commonComponents/SupportedFormats/SupportedFormats";
import SortableList from "../../commonComponents/sortFiles/SortableList";
import { useNavigate, useParams } from "react-router-dom";
import {
  blog_details,
  blog_path,
  detail_blog_loading,
  package_list,
} from "../../redux/commonReducer";
import RadioButtons from "../../commonComponents/RadioButtons/RadioButtons";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import Select from "../../commonComponents/Select/Select";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

export default function Blog() {
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [delete_package, setDelete_package] = useState([]);
  const [err, setErr] = useState({});
  const [data, setData] = useState({
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
  });
  const [imageLoader, setImageLoader] = useState(false);
  const [trackId, setTrackId] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const packageList = useSelector(package_list);
  const details = useSelector(blog_details);
  const path = useSelector(blog_path);
  const loading = useSelector(detail_blog_loading)

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({ gallery: [], packages: [] });

 

  useEffect(() => {
    dispatch(getPackageList());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getBlogDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        publish_date: details?.publish_date,
        description1: details?.description1,
        packages: details?.packages ?? [],
        is_active: details?.is_active.toString(),
        thumbnail: details?.thumbnail
          ? [
              {
                type: details?.thumbnail?.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${details?.thumbnail}`,
                name: `${details?.thumbnail}`,
                response: `${details?.thumbnail}`,
              },
            ]
          : "",
        thumbnail_alt: details?.thumbnail_alt,
      };
      if (details?.gallery?.length) {
        let arr = details?.gallery?.map((ele, i) => {
          let obj = {
            type: ele?.type,
            url:
              ele?.type == "video"
                ? ele?.image
                : `${IMAGE_BASE_URL}${path}/${ele?.image}`,
            name: `${ele?.image}`,
            response: `${ele?.image}`,
            id: ele?._id,
            alt: ele?.alt,
          };
          return obj;
        });
        result = {
          ...result,
          gallery: [...arr],
        };
      }
      setInputs({ ...result });
      setData({
        meta_title: details?.meta_title,
        meta_keyword: details?.meta_keyword,
        meta_description: details?.meta_description,
      });
    }
  }, [details]);

  const removeImage = (type) => {
    setInputs((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const handleDelete = (file, field) => {
    let temp = [...inputs?.[field]];
    if (temp?.length) {
      temp = temp?.filter((ele) => ele?.id !== file?.id);
      setInputs({
        ...inputs,
        [field]: temp,
      });
    }
  };

  const altChangeHandler = (key, value, index, field, id) => {
    let dataList = { ...inputs };
    dataList[field][index][key] = value;
    setInputs(dataList);
    setErrors({
      ...errors,
      [`${key}_${id}`]: "",
    });
  };

  const singleFileUploadHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        formData.append(`path`, "blog");
        dispatch(
          uploadFile(formData, (res) => {
            if(field==='thumbnail'){
              setInputs((s) => ({
              
                ...s,
                thumbnail_alt: s?.title || "",
              }))
             
            }
            setErrors({
              ...errors,
              [field]: "",
            });
            setInputs((s) => ({
              
              ...s,
              [field]: [
                {
                  type: e[0].type,
                  url: e[0].url,
                  name: e[0]?.name,
                  metaFile: e,
                  response: res?.data[0],
                },
              ],
            }));
          })
        );
      }
    }
  };

  // const onDetailsEditorChange = (content, field) => {

  //    console.log(content + "something went wrong")

  //   setInputs({
  //     ...inputs,
  //     [field]: content,
  //   });
  // };

  const onCancelHandler = () => {
    setInputs({});
    setErrors({});
    setErr({});
    navigate("/manage-blogs");
  };

  const fileSelectedHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setImageLoader(true);
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        formData.append(`path`, "blog");
        dispatch(
          uploadFile(formData, (res) => {
            let oldFiles = e?.filter((el) => el?.metaFile == undefined);
            let arr = e
              ?.filter((el) => el?.metaFile != undefined)
              ?.map((ele, i) => {
                return {
                  // ...ele,
                  url: ele?.url,
                  name: ele?.name,
                  response: res?.data[i],
                  id: res?.data[i] + i + 1,
                  alt: "",
                };
              });
            setErrors({
              ...errors,
              [field]: "",
            });
            setInputs((s) => ({
              ...s,
              [field]: [...oldFiles, ...arr],
            }));
            setImageLoader(false);
          })
        );
      }
    }
  };

  function getId(url) {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
      setVideoUrl("https://www.youtube.com/embed/" + match[2]);
    } else {
      return "error";
    }
  }

  const handleAddVideoUrl = () => {
    if (videoUrl) {
      setErrors({
        ...errors,
        gallery: "",
      });
      let dataList = { ...inputs };
      dataList?.gallery?.push({
        // ...ele,
        url: videoUrl,
        response: videoUrl,
        type: "video",
        id: videoUrl + new Date().getTime() + 1,
        alt: "",
      });
      setInputs(dataList);
      setVideoUrl("");
    }
  };

  const galleryValidate = (error) => {
    let temp = {};
    if (inputs?.gallery?.length) {
      inputs?.gallery?.forEach((ele, i) => {
        if (!ele.alt?.trim()?.length) {
          temp[`alt_${ele?.id}`] = "Please enter alt text";
        }
      });
    }
    if (Object.values(temp).length != 0) {
      
      setErrors({
        ...error,
        ...temp,
      });
      return false;
    } else {
      setErrors({ ...error });
      return true;
    }
  };

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const handleInputDelete = (id, field) => {
    setDelete_package((prev) => {
      const updated = [...prev, id];
      return updated;
    });

    let temp = [...inputs?.[field]];
    if (temp?.length) {
      temp = temp?.filter((ele) => ele?._id !== id);
      setInputs({
        ...inputs,
        [field]: temp,
      });
    }
  };

  const onSubmitHandler = async () => {
    let isValidated = await validate({
      returnErrors: true,
    });
    let isValidated2 = await galleryValidate(isValidated?.err);
    if (isValidated?.valid && !invalidUpload && isValidated2) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = {
      title: inputs?.title,
      description1: inputs?.description1,
      thumbnail: inputs?.thumbnail?.[0]?.response ?? "",
      gallery: inputs?.gallery?.map((item, i) => {
        return {
          ["image"]: item?.response,
          ["alt"]: item?.alt?.trim(),
          ["type"]: item?.type ?? "image",
        };
      }),
      delete_package: delete_package || "",
      thumbnail_alt: inputs?.thumbnail_alt,
      publish_date: inputs?.publish_date,
      is_active: inputs?.is_active.toString(),
      packages: inputs?.packages
        ? inputs?.packages?.map((item, i) => item?._id)
        : "",
      meta_title: data?.meta_title?.trim(),
      meta_keyword: data?.meta_keyword?.trim(),
      meta_description: data?.meta_description?.trim(),
      track_id: trackId,
      path : "blog"
    };

    if (id) {
      formData = {
        ...formData,
        id: id,
      };
      dispatch(
        updateBlog(formData, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    } else {
      dispatch(
        insertBlog(formData, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  return (
    loading ? ( <LoadingSpinner height="200px" /> ):(
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">{id ?"Update Blog":"Add Blog"}</h5>
            <div className="row">
              <div className="col-12">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="Title"
                  placeholder="Type here"
                  required={{ message: "Please enter title" }}
                  field={"title"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-6 mb-3">
                <FileUpload
                  style={{
                    marginRight: 5,
                    marginTop: 0,
                  }}
                  className="css-atc-esc5 mt-3"
                  label="Banner Image"
                  accept={["jpg", "jpeg", "png"]}
                  icon={"/assets/img/icons/plus_black.svg"}
                  closeIcon={
                    <AiOutlineClose
                      size={30}
                      className="remove-icon"
                      onClick={() => removeImage("thumbnail")}
                    />
                  }
                  onChange={(e, isInvalid, message, isClosing) => {
                   
                    setErrors((s) => ({
                      ...s,
                      thumbnail: "",
                    }));
                    singleFileUploadHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "thumbnail"
                    );
                    
                  }}
                  error={errors.thumbnail}
                  width={28}
                  field={"thumbnail"}
                  id="thumbnail"
                  maxFileSizeMB={50}
                  multiple={false}
                  showPreview
                  files={inputs?.thumbnail}
                  required={{ message: "Please upload image" }}
                  inputSocket={formPlug}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <Input
                    className="css-inp-esc1"
                    label="Add Alt Text"
                    placeholder="Type here"
                    // required={{ message: "Please enter image alt" }}
                    field={"thumbnail_alt"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                  />
                </div>
                <div className="col-6">
                  <DatePick
                    className="css-dtp-esc2 mb-3"
                    title={"Date"}
                    placeholder="DD/MM/YYYY"
                    selected={inputs?.publish_date}
                    onChange={(e, key) => {
                      inputHandler(e, key);
                    }}
                    dateFormat="dd/MM/yyyy"
                    // minDate={new Date()}
                    error={errors?.publish_date}
                    inputSocket={formPlug}
                    required={{ message: "Please select date" }}
                    field={"publish_date"}
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>
              </div>

              <div className="col-12 mb-3">
                <TextEditor
                  config={editorConfigEn}
                  label={"Content Section One"}
                  className={"mb-3"}
                  field={"description1"}
                  // onBlur={(newContent) =>
                  //   onDetailsEditorChange(newContent, "description1")
                  // }
                  // validation = {{maxChar:250}}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  required={{
                    message: "Please enter description",
                  }}
                  placeholder="Type Here"
                />
              </div>

              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <label className="form-label">Add Gallery Images</label>
                  <div className="d-flex gap-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 15,
                      }}
                      className="css-atc-esc2 mt-0 mb-3"
                      noLabel
                      buttonLabel="Choose File"
                      accept={["jpg", "jpeg", "png", "svg"]}
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          gallery: "",
                        }));
                        fileSelectedHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "gallery"
                        );
                      }}
                      validFilesOnly
                      field={"gallery"}
                      loader={imageLoader}
                      error={errors.gallery}
                      id="gallery"
                      maxFileSizeMB={100}
                      multiple={true}
                      files={inputs?.gallery}
                      inputSocket={() => {}}
                    />
                  </div>
                  <SupportedFormats
                    formats={["jpg", "jpeg", "png", "svg"]}
                    maxSize={100}
                  />
                </div>
                <div className="col-12 col-md-4 mb-3">
                  <label className="form-label">Add Youtube Videos</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Video URL"
                    onChange={(e) => getId(e.target.value)}
                    value={videoUrl ? videoUrl : ""}
                  />
                  <small id="error" className="error">
                    {errors?.videoUrl}
                  </small>
                </div>
                <div className="col-12 col-md-2 mb-3 margin-container">
                  <button
                    type="submit"
                    className="btn btn-primary m-2"
                    disabled={videoUrl ? false : true}
                    onClick={handleAddVideoUrl}
                  >
                    Add
                  </button>
                </div>
              </div>
              {inputs?.gallery?.length ? (
                <SortableList
                  setInputs={setInputs}
                  inputs={inputs}
                  field="gallery"
                  handleDelete={handleDelete}
                  randomTrackID={trackId ?? ""}
                  err={errors}
                  altChangeHandler={altChangeHandler}
                />
              ) : (
                <></>
              )}

              <div className="col-12 col-md-6 mb-3">
                <label className="form-label mb-3">Associated Packages</label>
                <Select
                  title="Choose Package"
                  className="css-slc-esc1 mb-3"
                  placeholder={"Select"}
                  options={packageList}
                  labelSetter={(o) => o?.package_name}
                  valueSetter={(option) => option._id}
                  field={"packages"}
                  error={errors.packages}
                  value={inputs.packages ? inputs.packages : []}
                  onChange={inputHandler}
                  inputSocket={formPlug}
                  Multi2
                  // required={{ message: "Please select packages" }}
                />
              </div>
              <div className="raw">
                <div className="col-12 selectedListData">
                  {inputs?.packages?.map((option) => (
                    <div key={option?._id} className="mb-3 ">
                      <div className="d-inline-block mb-1">
                        <button className="selected__item" type="button">
                          {option?.package_name}
                         <img src="/assets/img/icons/close-red.svg"  onClick={() =>
                              handleInputDelete(option?._id, "packages")
                            } alt="" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Seo data={data} onChangeHandler={onChangeHandler} />
          </div>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <RadioButtons
              className={'mb-0'}
                label={"Status"}
                buttons={[
                  {
                    label: "Active",
                    value: true,
                  },
                  {
                    label: "Inactive",
                    value: false,
                  },
                ]}
                name="is_active"
                placeholder={"Select any option"}
                required={{ message: "Please select  an option" }}
                field={"is_active"}
                error={errors.is_active}
                value={{
                  is_active: inputs?.is_active,
                }}
                onChange={(value, key) => inputHandler(value, key)}
                inputSocket={formPlug}
              />
               <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary m-2"
                onClick={() => onSubmitHandler()}
              >
                Submit
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  );
}
