import * as Tabs from "@radix-ui/react-tabs";
import Select from "../../../commonComponents/Select/Select";
import Input from "../../../commonComponents/Input/Input";
import DatePick from "../../../commonComponents/DatePicker/DatePick";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import moment from "moment";
import AttachmentContent from "../../../commonComponents/AttachmentContent/AttachmentContent";
import { IMAGE_BASE_URL } from "../../../constants/configuration";

const Payments = ({
  paymentTypeList,
  formPlug,
  inputs,
  inputHandler,
  errors,
  fileUploadHandler,
  mileStoneData,
  paymentStatusList,
  milestoneDataChangeHandler,
  details,
}) => {
  return (
    <div className="card">
      <div className="card-body pt-0">
        <div className="tab-content">
          <div className="row">
            <Tabs.Root
              className="TabsRoot"
              defaultValue={details?.is_quotation ? "tab2" : "tab1"}
            >
              <Tabs.List className="TabsList" aria-label="Manage your account">
                {details?.is_quotation && (
                  <Tabs.Trigger className="TabsTrigger" value="tab2">
                    Customized Payment Details
                  </Tabs.Trigger>
                )}
                <Tabs.Trigger className="TabsTrigger" value="tab1">
                  Payment Details
                </Tabs.Trigger>
              </Tabs.List>
              {details?.is_quotation ? (
                <Tabs.Content className="TabsContent" value="tab2">
                  {/* <div className="grid__items mb-4 pad-0 mt-3">
                    <div className="item">
                      <Select
                        title="Payment type"
                        className="css-slc-esc1 mb-3"
                        placeholder={"Select"}
                        options={paymentTypeList}
                        labelSetter={(o) => o?.label}
                        valueSetter={(o) => o?.value}
                        field={"payment_mode"}
                        inputSocket={formPlug}
                        value={inputs.payment_mode}
                        onChange={(e, key) => {
                          inputHandler(e, key);
                        }}
                        error={errors[`payment_mode`]}
                        disabled={details?.is_payment.length}
                      />
                    </div>
                  </div> */}
                  {details?.is_quotation ? (
                    <>
                      {!inputs.payment_mode ? (
                        <></>
                      ) : (
                        <>
                          {mileStoneData?.map((ele, index) => {
                            return (
                              <div className="grid__items pad-0">
                                <div className="item">
                                  <Input
                                    className="css-inp-esc1 "
                                    label="Milestone Name"
                                    placeholder="Type here"
                                    field={"milestone"}
                                    inputSocket={formPlug}
                                    value={ele?.milestone_name}
                                    onChange={(e, key) => {
                                      milestoneDataChangeHandler(e, key, index);
                                    }}
                                    error={
                                      errors[
                                        `${"milestone"}_${"milestone"}_${index}`
                                      ]
                                    }
                                    disabled
                                  />
                                </div>
                                <div className="item">
                                  <Input
                                    className="css-inp-esc1 "
                                    label="Percentage"
                                    placeholder="Type here"
                                    field={"percentage"}
                                    inputSocket={formPlug}
                                    value={ele?.percentage}
                                    onChange={(e, key) => {
                                      milestoneDataChangeHandler(e, key, index);
                                    }}
                                    error={
                                      errors[
                                        `${"milestone"}_${"percentage"}_${index}`
                                      ]
                                    }
                                    disabled
                                  />
                                </div>
                                <div className="item">
                                  <Input
                                    className="css-inp-esc1 "
                                    label="Value"
                                    placeholder="Type here"
                                    field={"value"}
                                    inputSocket={formPlug}
                                    value={ele?.value}
                                    onChange={(e, key) => {
                                      milestoneDataChangeHandler(e, key, index);
                                    }}
                                    error={
                                      errors[
                                        `${"milestone"}_${"value"}_${index}`
                                      ]
                                    }
                                    disabled
                                  />
                                </div>
                                <div className="item">
                                  <Select
                                    title="Payment status"
                                    placeholder="payment status"
                                    className="css-slc-esc1 mb-3"
                                    options={paymentStatusList}
                                    labelSetter={(option) => option?.label}
                                    valueSetter={(option) => option.value}
                                    field={"payment_status"}
                                    inputSocket={formPlug}
                                    value={ele.payment_status}
                                    onChange={(e, key) => {
                                      milestoneDataChangeHandler(e, key, index);
                                      if (e?.value == "captured") {
                                        milestoneDataChangeHandler(
                                          new Date(),
                                          "date",
                                          index
                                        );
                                      }
                                    }}
                                    error={
                                      errors[
                                        `${"milestone"}_${"payment_status"}_${index}`
                                      ]
                                    }
                                  />
                                </div>
                                <div className="item">
                                  {console.log(ele?.date, "55")}
                                  <DatePick
                                    className="css-dtp-esc2 mb-3"
                                    title={"Date"}
                                    placeholder="DD/MM/YYYY"
                                    dateFormat="dd/MM/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    field={"date"}
                                    inputSocket={formPlug}
                                    selected={ele?.date}
                                    onChange={(e, key) => {
                                      milestoneDataChangeHandler(e, key, index);
                                    }}
                                    minDate={new Date()}
                                    error={
                                      errors[
                                        `${"milestone"}_${"date"}_${index}`
                                      ]
                                    }
                                    disabled={
                                      ele.payment_status?.value == "captured"
                                    }
                                  />
                                </div>
                                <div className="item">
                                  <FileUpload
                                    className="css-atc-esc1 preview-imagemargin"
                                    buttonLabel="Upload Receipt"
                                    accept={[
                                      "jpg",
                                      "jpeg",
                                      "png",
                                      "pdf",
                                      "application/pdf",
                                    ]}
                                    onChange={(
                                      e,
                                      isInvalid,
                                      message,
                                      isClosing
                                    ) => {
                                      fileUploadHandler(
                                        e,
                                        isInvalid,
                                        message,
                                        isClosing,
                                        "upload_receipt",
                                        milestoneDataChangeHandler,
                                        "multiple",
                                        index
                                      );
                                    }}
                                    error={
                                      errors[
                                        `${"milestone"}_${"upload_receipt"}_${index}`
                                      ]
                                    }
                                    id="upload_receipt"
                                    maxFileSizeMB={100}
                                    showPreview
                                    files={ele?.upload_receipt}
                                    multiple={true}
                                  />
                                </div>
                              </div>
                            );
                          })}
                          {errors?.mileStoneData ? (
                            <span className="error">
                              {errors?.mileStoneData}
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {/* <div className="grid__items pad-0">
                    <div className="remaining__amount">
                      <p>{`Remaining Amount : $${details?.amount_to_be_paid}`}</p>
                    </div>
                  </div> */}
                </Tabs.Content>
              ) : (
                <></>
              )}

              <Tabs.Content className="TabsContent" value="tab1">
                <div className="tab__header mt-0">
                  <h2>Payments</h2>
                </div>
                <div className="grid__items">
                  {/* payment_advance */}
                  <div className="item">
                    <h4>Transaction ID</h4>
                    <p>{details?.payment_advance?.[0]?.txt_id}</p>
                  </div>
                  <div className="item">
                    <h4>Amount</h4>
                    <p>{details?.payment_advance?.[0]?.amount}</p>
                  </div>
                  <div className="item">
                    <h4>Currency</h4>
                    <p>{details?.payment_advance?.[0]?.currency}</p>
                  </div>
                  <div className="item">
                    <h4>Payment Status</h4>
                    <p className="badge bg-success">
                      <p>{details?.payment_advance?.[0]?.payment_status}</p>
                    </p>
                  </div>
                  <div className="item">
                    <h4>Payment Method</h4>
                    <p>{details?.payment_advance?.[0]?.payment_method}</p>
                  </div>
                  {/* <div className="item">
                      <h4>Customer Stripe id</h4>
                      <p>SKYWARD001221</p>
                    </div>
                    <div className="item">
                      <h4>Customer id</h4>
                      <p>SKY1322</p>
                    </div> */}
                </div>
                {/* <div class="tab__sub_header mt-4">
                    <h2>Billing Details</h2>
                  </div>
                  <div className="grid__items">
                    <div className="item">
                      <h4>Name</h4>
                      <p>{details?.billing?.[0]?.name ?? "--"}</p>
                    </div>
                    <div className="item">
                      <h4>Email</h4>
                      <p>{details?.billing?.[0]?.email ?? "--"}</p>
                    </div>
                    <div className="item grid-2">
                      <h4>Address</h4>
                      <p>{details?.billing?.[0]?.address ?? "--"}</p>
                    </div>
                  </div> */}
                {details?.is_quotation ? (
                  <>
                    {" "}
                    <div class="tab__sub_header mt-4">
                      <h2>
                        {inputs.payment_mode?.value == "onetime"
                          ? "Payment Details"
                          : "Milestone Details"}
                      </h2>
                    </div>
                    {mileStoneData?.map((item, i) => (
                      <>
                        <span className="badge bg-success mb-3">
                          {inputs.payment_mode?.value == "onetime"
                            ? "One time payment"
                            : ` Milestone ${i + 1}`}
                        </span>
                        <div className="grid__items">
                          <div className="item">
                            <h4>
                              {inputs.payment_mode?.value == "onetime"
                                ? "Payment Type"
                                : "Milestone type"}
                            </h4>
                            <p>{inputs.payment_mode?.label}</p>
                          </div>
                          {item?.txt_id ? (
                            <div className="item">
                              <h4>Transaction ID</h4>
                              <p>{item?.txt_id ?? "--"}</p>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div className="item">
                            <h4>Amount</h4>
                            <p>{item?.value}</p>
                          </div>

                          <div className="item">
                            <h4>Payment Status</h4>
                            <span className="badge bg-success">
                              {item?.payment_status?.label}
                            </span>
                          </div>
                          <div className="item">
                            <h4>Currency</h4>
                            <p>{"USD"}</p>
                          </div>
                          <div className="item">
                            <h4>Payment Method</h4>
                            <p>{inputs.payment_method?.label}</p>
                          </div>
                          {/* <div className="item">
                            <h4>Customer Stripe id</h4>
                            <p>SKYWARD001221</p>
                          </div> */}

                          <div className="item">
                            <h4>Milestone Name</h4>
                            <p>{item?.milestone_name}</p>
                          </div>
                          <div className="item">
                            <h4>Due Date</h4>
                            <p>
                              {item?.date
                                ? moment(item?.date).format("D MMM yyyy")
                                : "--"}
                            </p>
                          </div>
                          <div className="item">
                            <h4>Percentage</h4>
                            <p>{item.percentage}%</p>
                          </div>
                          <div className="item">
                            <h4>value</h4>
                            <p>{item.value}</p>
                          </div>
                          <div className="item">
                            <h4>Uploaded File</h4>
                            <div className="files">
                              {item?.upload_receipt ? (
                                <AttachmentContent
                                  label={""}
                                  files={item?.upload_receipt}
                                />
                              ) : (
                                <>{"--"}</>
                              )}
                              {/* <img src="/assets/img/pdf.jpg" alt="" /> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Tabs.Content>
            </Tabs.Root>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Payments;
