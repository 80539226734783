import React, { useEffect, useState } from "react";
import Input from "../../commonComponents/Input/Input";
import DatePick from "../../commonComponents/DatePicker/DatePick";
import useForm from "../../utils/Hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPromoCodeDetail,
  insertPromoCode,
  updatePromoCodes,
} from "../../redux/actionCreator";
import { promocode_details } from "../../redux/commonReducer";
import Select from "../../commonComponents/Select/Select";
import moment from "moment";

const AddPromoCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const details = useSelector(promocode_details);

  useEffect(() => {
    if (id) {
      dispatch(getPromoCodeDetail(id));
    }
  }, [id]);

  const discountType = [{ name: "AMOUNT" }, { name: "PERCENTAGE" }];

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        discount_type: details?.discount_type,
        discount_value: details?.discount_value,
        max_count: details?.max_count,
        expiry_date: details?.expiry_date,
        promotion_code: details?.promotion_code,
      };

      setInputs({ ...result });
    }
  }, [details]);
  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  const onCancelHandler = () => {
    setInputs({});
    setErrors({});
    navigate("/manage-promocodes");
  };

  const onSubmitHandler = async () => {
    let isValidated = await validate({
      returnErrors: true,
    });
    if (isValidated?.valid) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = {
      title: inputs?.title,
      discount_type: inputs?.discount_type.name,
      discount_value: inputs?.discount_value,
      max_count: Number(inputs?.max_count),
      expiry_date: inputs?.expiry_date
  ? moment(inputs.expiry_date).endOf('day').toISOString()
  : "",

      promotion_code: inputs?.promotion_code,
    };
    console.log(formData);

    if (id) {
      formData = {
        ...formData,
        id: id,
      };
      dispatch(
        updatePromoCodes(formData, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    } else {
      dispatch(
        insertPromoCode(formData, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">
              {" "}
              {id ? "Edit Promo Code" : "Add Promo Code"}
            </h5>
            <div className="row">
              <div className="col-6">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="Title"
                  placeholder="Type here"
                  required={{ message: "Please enter title" }}
                  field={"title"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-6">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="Discount Code"
                  placeholder="Type here"
                  required={{ message: "Please enter Promotion Code" }}
                  field={"promotion_code"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Select
                  title="Discount Type"
                  className="css-slc-esc1 mb-3"
                  placeholder={"Select"}
                  options={discountType}
                  labelSetter={(o) => o?.name}
                  valueSetter={(option) => option.name}
                  onChange={inputHandler}
                  inputSocket={formPlug}
                  error={errors?.discount_type}
                  field={"discount_type"}
                  value={inputs.discount_type}
                  required={{ message: "Select a discount type" }}
                />
              </div>
              <div className="col-6">
                <Input
                  type={"number"}
                  className="css-inp-esc1"
                  label="Discount Value"
                  placeholder="Type here"
                  required={{ message: "Please enter discount value" }}
                  field={"discount_value"}
                  validation={
                    inputs?.discount_type?.name === "PERCENTAGE"
                      ? { maxChar: 2 }
                      : ""
                  }
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>

              <div className="col-6">
                <Input
                  type={"number"}
                  className="css-inp-esc1"
                  label="Usage Limit"
                  placeholder="Type here"
                  required={{ message: "Please enter usage limit" }}
                  field={"max_count"}
                  validation={{ maxChar: 2 }}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-6">
                <DatePick
                  className="css-dtp-esc2 mb-3"
                  title={"Expiry Date"}
                  placeholder="DD/MM/YYYY"
                  selected={inputs?.expiry_date}
                  onChange={(e, key) => {
                    inputHandler(e, key);
                  }}
                  dateFormat="dd/MM/yyyy"
                  // minDate={new Date()}
                  error={errors?.expiry_date}
                  inputSocket={formPlug}
                  required={{ message: "Please select date" }}
                  field={"expiry_date"}
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary m-2"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPromoCode;
