import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFlightCostDetail,
  insertFlightCost,
} from "../../redux/actionCreator";
import {
  detail_flight_cost_loading,
  flight_cost_details,
  update_flight_cost_loading,
} from "../../redux/commonReducer";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

export default function FlightMarkup() {
  const dispatch = useDispatch();

  const submitLoading = useSelector(update_flight_cost_loading);
  const detailLoading = useSelector(detail_flight_cost_loading);
  const details = useSelector(flight_cost_details);

  const [data, setData] = useState({
    flightmarkup_cost: "",
    emirates_markup_cost:"",
  });
  const [err, setErr] = useState({});

  useEffect(() => {
    dispatch(getFlightCostDetail());
  }, []);

  useEffect(() => {
    if (details) {
      setData({
        flightmarkup_cost: details?.flightmarkup_cost,
        emirates_markup_cost: details?.emirates_markup_cost
      });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (!data?.flightmarkup_cost?.trim()?.length) {
      temp = {
        ...temp,
        flightmarkup_cost: "Please enter value",
      };
    }
    if (!data?.emirates_markup_cost?.trim()?.length) {
      temp = {
        ...temp,
        emirates_markup_cost: "Please enter value",
      };
    }

    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };
  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        flightmarkup_cost: data?.flightmarkup_cost?.trim(),
        emirates_markup_cost: data?.emirates_markup_cost?.trim()
      };
      console.log(raw);
      dispatch(
        insertFlightCost(raw, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    setData({
      flightmarkup_cost: "",
      emirates_markup_cost:"",
    });
    dispatch(getFlightCostDetail());
  };

  return (
      detailLoading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title border-style mb-3">Flight Markup Cost</h5>
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="mb-3">
                      <label htmlFor="ageGroupInput1" className="form-label">
                        Flight Markup Cost (%) <em>*</em>
                      </label>
                      <input
                        type="text"
                        className="form-control typeNumber"
                        id="ageGroupInput1"
                        placeholder="Type here"
                        value={data?.flightmarkup_cost}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            /^(\d{1,3}(\.\d{0,2})?)?$/.test(inputValue) &&
                            (inputValue === "" || Number(inputValue) <= 100)
                          ) {
                            onChangeHandler("flightmarkup_cost", inputValue);
                          }
                        }}
                      />
                      <small id="error" className="error">
                        {err?.flightmarkup_cost}
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="mb-3">
                      <label htmlFor="ageGroupInput2" className="form-label">
                        Emirates Markup Cost <em>*</em>
                      </label>
                      <input
                        type="text"
                        className="form-control typeNumber"
                        id="ageGroupInput2"
                        placeholder="Type here"
                        value={data?.emirates_markup_cost}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            /^(\d{1,3}(\.\d{0,2})?)?$/.test(inputValue) &&
                            (inputValue === "" || Number(inputValue) <= 100)
                          ) {
                            onChangeHandler("emirates_markup_cost", inputValue);
                          }
                        }}
                      />
                      <small id="error" className="error">
                        {err?.emirates_markup_cost}
                      </small>
                    </div>
                  </div>
    
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary mt-2 me-2"
                      onClick={() => onSubmitHandler()}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => onCancelHandler()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
    
}


