import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./AttachmentContent.scss";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

import closeIconPath from "./ac_close.svg";
import docIcon from "./ac_doc.svg";
import docxIcon from "./ac_docx.svg";
import mp4Icon from "./ac_mp4.svg";
import movIcon from "./ac_mov.svg";
import pdfIcon from "./ac_pdf.svg";
import xlsxIcon from "./ac_xlsx.svg";
import xlsIcon from "./ac_xls.svg";
import csvIcon from "./ac_csv.svg";
import pptxIcon from "./ac_pptx.svg";
import pptIcon from "./ac_ppt.svg";
import avifIcon from "./ac_avif.svg";
import svgIcon from "./ac_svg.svg";
import zipIcon from "./ac_zip.svg";
import errorFile from "./ac_errorFile.svg";

export default function AttachmentContent({
  files,
  closeButton,
  onClose,
  label,
  className,
  closeIcon
}) {
  const [inFiles, setFiles] = useState([]);

  useEffect(() => {
    files && setFiles(files);
    return () => setFiles([]);
  }, [files]);

  function fileCloseHandler(fileToDelete) {
    let result = inFiles.filter((file) => file !== fileToDelete);
    onClose && onClose(result.map((item) => item));
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} style={{ fontSize: "12px" }}>
      {props.label}
    </Tooltip>
  );

  let icons = {
    png: "file",
    jpg: "file",
    jpeg: "file",
    png: "file",
    pdf: pdfIcon,
    doc: docIcon,
    docx: docxIcon,
    mov: movIcon,
    mp4: mp4Icon,
    xlsx: xlsxIcon,
    csv: csvIcon,
    ppt: pptIcon,
    pptx: pptxIcon,
    xls: xlsIcon,
    avif: avifIcon,
    svg: svgIcon,
    zip: zipIcon,
  };

  function fileParser(file) {
    let type = file.type || file.url.split('.').pop()
    let src = icons[type.toLowerCase()];
    if (src === "file") src = file.url;
    if (file.isInvalid) src = errorFile;
    let isImage = icons[type?.toLowerCase()] === "file" && !file.isInvalid;
    return [src, isImage];
  }

  if (inFiles.length === 0) return null;

  return (
    <div id="AttachmentContent" className={className}>
      {label && <label htmlFor="">{label}</label>}
      <span className="content">
        {inFiles.map((file, i) => {
          let [src, isImage] = fileParser(file);
          return (
            <span key={i} className={isImage ? "image" : ""}>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip({ label: file.name })}
              >
                <a href={file.url} target="_blank" rel="noreferrer">
                  <img className="file" src={src} alt="" />
                </a>
              </OverlayTrigger>
              {closeButton && (

                closeIcon
                  ? <span className="close_wrap"
                    onClick={() => fileCloseHandler(file)}
                  >{closeIcon}</span>
                  : <img
                    onClick={() => fileCloseHandler(file)}
                    className="close"
                    src={closeIconPath}
                    alt=""
                  />

              )}
            </span>
          );
        })}
      </span>
    </div>
  );
}
